import { createSlice } from '@reduxjs/toolkit';
import { IntegrationCredentialsInterface } from '../../types';

export interface State {
    monitors: any[],
    flows: any[],
    teamMembers: any[],
    linkedIntegrations: Partial<IntegrationCredentialsInterface>[],
    contentLoading: boolean,
    fullScreen: boolean,
    flowNodeDrawer: boolean,
    flowNodeDrawerId: string | null,
    isFlowLocked: boolean,
    isUpgradeModalShown: boolean,
    isUpgradeModalError: string | null,
}

const initialState: State = {
    monitors: [],
    flows: [],
    teamMembers: [],
    linkedIntegrations: [],
    fullScreen: false,
    contentLoading: false,
    flowNodeDrawer: false,
    flowNodeDrawerId: null,
    isFlowLocked: false,
    isUpgradeModalShown: false,
    isUpgradeModalError: null,
};

export const slice = createSlice({
    name: 'cache',
    initialState,
    reducers: {
        setCacheMonitors: (state: any, action: any) => {
            state.monitors = action.payload;
        },
        setCacheFlows: (state: any, action: any) => {
            state.flows = action.payload;
        },
        setTeamMembers: (state: any, action: any) => {
            state.teamMembers = action.payload;
        },
        setCacheLinkedIntegrations: (state: any, action: any) => {
            state.linkedIntegrations = action.payload;
        },
        setFullscreenMode: (state: any, action: any) => {
            state.fullScreen = action.payload;
        },
        setFlowLocked: (state: any, action: any) => {
            state.isFlowLocked = action.payload;
        },
        setFlowNodeDrawer: (state: any, action: any) => {
            state.flowNodeDrawer = action.payload.status;
            state.flowNodeDrawerId = action.payload.id;
        },
        setUpgradeModalShow: (state: any, action: any) => {
            state.isUpgradeModalShown = action.payload.show;
            state.isUpgradeModalError = action.payload.error;
        },
        setContentLoading: (state: any, action: { payload: boolean }) => {
            state.contentLoading = action.payload;
        },
        resetCache: () => {
            return initialState;
        },
    }
});

export const { setCacheMonitors, setTeamMembers, setContentLoading, setCacheFlows, setCacheLinkedIntegrations, setFullscreenMode, setFlowLocked, setFlowNodeDrawer, setUpgradeModalShow, resetCache } = slice.actions;
export default slice.reducer;