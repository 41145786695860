import PulseDot from "react-pulse-dot";
import { Link } from 'react-router-dom';
import { DATA_STATUSES } from '../utils/types';

const statusColors = {
    [DATA_STATUSES.active]: 'success',
    [DATA_STATUSES.inactive]: 'warning',
    [DATA_STATUSES.expired]: 'danger',
    [DATA_STATUSES.suspended]: 'info',
    [DATA_STATUSES.terminated]: 'danger',
    [DATA_STATUSES.banned]: 'danger',
    [DATA_STATUSES.pending]: 'warning',
    default: 'danger',
};

type ItemProps = {
    id: string,
    name: string,
    status: DATA_STATUSES,
}

const FlowItem = ({ id, name, status }: ItemProps) => {
    const color = statusColors?.[status] || statusColors.default;
    const textColor = status === DATA_STATUSES.inactive ? '#878787' : status === DATA_STATUSES.active ? '#1aba34' : '#ed1d25';

    return (
        <Link className="monitor-item" to={`/flows/view/${id}`}>
            <div className="monitor-item-header-container">
                <div className='monitor-item-header-wrapper'>
                    <div className="monitor-item-header">
                        <div className="monitor-item-favicon"></div>
                        <div className="monitor-item-name">{name}</div>
                    </div>
                </div>
                <div className="status-indicator">
                    <span style={{
                        color: textColor,
                        textShadow: `0 0 6px ${textColor}`,
                        fontSize: 11,
                        marginRight: 5
                    }}>{status === DATA_STATUSES.inactive ? 'Paused' : status === DATA_STATUSES.active ? 'Working' : 'Stopped'}</span>
                    {status !== DATA_STATUSES.inactive && <PulseDot style={{ fontSize: '0.7em' }} color={color} />}
                </div>
            </div>
        </Link>
    )
}

export default FlowItem;