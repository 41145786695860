import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { uptimeLogsData } from "../../utils/types";
import UptimeTooltip from "./Tooltips/UptimeTooltip";

export function UptimeChart({ data }: { data: uptimeLogsData[] }) {
    return (
        <ResponsiveContainer width="100%" height={150}>
            <AreaChart
                data={data}
                layout="horizontal"
                reverseStackOrder={false}
                stackOffset={"none"}
            >
                <XAxis dataKey="date" axisLine={false} tickLine={false} fontSize={8} tickFormatter={(value) => new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} />
                <YAxis dataKey="uptime" axisLine={false} tickLine={false} domain={[0, 50, 100]} fontSize={8} tickFormatter={(value) => `${value}%`} />
                <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                    stroke="#6d67e4"
                    opacity={0.1}
                />
                <Tooltip content={<UptimeTooltip />} offset={50} />
                <Area
                    dataKey="uptime"
                    stroke="#74ff83"
                    fill="#00860e12"
                />
            </AreaChart>
        </ResponsiveContainer>
    )
}