// import { LineChart, Line, ResponsiveContainer } from 'recharts';
import PulseDot from "react-pulse-dot";
import { Link } from 'react-router-dom';
import { DATA_STATUSES, UPTIME_LOG_STATUSES } from '../utils/types';

const statusColors = {
    [DATA_STATUSES.active]: 'success',
    [DATA_STATUSES.inactive]: 'warning',
    [DATA_STATUSES.expired]: 'danger',
    [DATA_STATUSES.suspended]: 'info',
    [DATA_STATUSES.terminated]: 'danger',
    [DATA_STATUSES.banned]: 'danger',
    [DATA_STATUSES.pending]: 'warning',
    default: 'danger',
};

const endpointStatusColors: any = {
    [UPTIME_LOG_STATUSES.up]: 'success',
    [UPTIME_LOG_STATUSES.down]: 'danger',
    [UPTIME_LOG_STATUSES.internalError]: 'warning',
    default: 'success',
};

type MonitorItemProps = {
    id: string,
    companyName: string,
    website: string,
    status: DATA_STATUSES,
    endpointStatus: UPTIME_LOG_STATUSES,
    // uptime: number | string,
    // responseTime: number,
    // chartData: any[],
}

const MonitorItem = ({ id, companyName, website, status, endpointStatus }: MonitorItemProps) => {
    const color = status === DATA_STATUSES.active ? (endpointStatusColors?.[endpointStatus] || endpointStatusColors.default) : (statusColors?.[status] || statusColors.default);
    const textColor = status === DATA_STATUSES.inactive ? '#878787' : status === DATA_STATUSES.active ? endpointStatus === UPTIME_LOG_STATUSES.up ? '#1aba34' : '#ed1d25' : '#878787';

    return (
        <Link className="monitor-item" to={`/monitors/view/${id}`}>
            <div className="monitor-item-header-container">
                <div className='monitor-item-header-wrapper'>
                    <div className="monitor-item-header">
                        <div className="monitor-item-favicon"></div>
                        <div className="monitor-item-name">{companyName}</div>
                    </div>
                    <div className="monitor-item-website">{website}</div>
                </div>
                <div className="status-indicator">
                    <span style={{
                        color: textColor,
                        textShadow: `0 0 6px ${textColor}`,
                        fontSize: 11,
                        marginRight: 5,
                    }}>{status === DATA_STATUSES.inactive ? 'Paused' : endpointStatus === UPTIME_LOG_STATUSES.up ? 'Up' : 'Down'}</span>
                    {status !== DATA_STATUSES.inactive && <PulseDot style={{ fontSize: '0.7em' }} color={color} />}
                </div>
            </div>
        </Link>
    )
}

export default MonitorItem;


// {/* <div className="monitor-item-info-tab">
//     <div className="monitor-item-info-item">
//         UPTIME
//         <span>{uptime}%</span>
//     </div>
//     <div className="monitor-item-info-item">
//         RESPONSE TIME
//         <span>{formatNumber(responseTime)} ms</span>
//     </div>
// </div> */}
// {/* <div className="monitor-item-chart">
//     <ResponsiveContainer width="100%" height="100%">
//         <LineChart style={{ cursor: "pointer" }} width={300} height={100} data={chartData}>
//             <Line dot={false} type="monotone" dataKey="y" stroke="#8884d8" strokeWidth={2} />
//         </LineChart>
//     </ResponsiveContainer>
// </div> */}