import { SaveFilled, DeleteOutlined, RadarChartOutlined } from "@ant-design/icons"; // ThunderboltOutlined
import { Button, Divider, Form, Input, notification, Popconfirm, Radio, Select, Tag } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import FormItemTitle from "../../../components/FormItemTitle";
import FormSettingsItem from "../../../components/FormSettingsItem";
import RequestContainer from "../../../components/HTTPRequestEditor/RequestContainer";
import PageContent from "../../../components/PageContent";
import RegionSelectionTab from "../../../components/RegionSelectionTab";
import { checkIntervalOptions, httpStatusCodeOptions, HTTP_METHODS_ARR, timeoutIntervalOptions } from "../../../utils/constants";
import endpoints from "../../../utils/endpoints";
import Request from "../../../utils/request";
import { IHTTPRequestData, IHTTPRequestDataTypes, PLANS_BY_ID, checkIntervalsEnum, monitorTypes } from "../../../utils/types";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/useRedux";
import { setContentLoading } from "../../../utils/redux/features/cache";
import { removeEmptyObjects } from "../../../utils/utils";

const { Option } = Select;

type IFooterContentTypes = {
  isEdit: boolean,
  onCreate: () => void,
  onEdit: () => void,
  onDelete: () => void,
  onRun: () => void,
  onToggleAdvanced: () => void,
  isCreatingLoading: boolean,
  isCreateDisabled: boolean,
  isRunning?: boolean,
  isTesting: boolean,
  advancedMode: boolean
}

const FooterContent = ({ isEdit, onCreate, onEdit, onDelete, onToggleAdvanced, isCreatingLoading, isCreateDisabled, isRunning, advancedMode }: IFooterContentTypes) => { // onRun, isTesting,
  return (
    <div className="sticky-footer-content">
      {/* <Button className="run-btn" type="primary" disabled={isRunning} loading={isTesting} onClick={onRun}><ThunderboltOutlined /> Test</Button> */}
      {isEdit && (
        <Popconfirm
          title="Are you sure to delete this monitor?"
          onConfirm={onDelete}
          okText="Delete"
          cancelText="No"
        >
          <Button type="primary" danger disabled={isRunning}><DeleteOutlined /></Button>
        </Popconfirm>
      )}
      <Button type={"primary"} className={advancedMode ? "toggle-button" : ""} disabled={isRunning} onClick={onToggleAdvanced}>Advanced mode <RadarChartOutlined /></Button>
      {isEdit && <Button className="run-btn" type="primary" disabled={isRunning || isCreateDisabled} onClick={onEdit}>Save changes <SaveFilled /> </Button>}
      {!isEdit && <Button className="run-btn" type="primary" disabled={isRunning || isCreateDisabled} loading={isCreatingLoading} onClick={onCreate}>Create <SaveFilled /></Button>}
    </div>
  )
}

function MonitorsManage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const dataFetchedRef = useRef(false);
  const serverRegionsData = useAppSelector(state => state.common.locations);
  const userData = useAppSelector(state => state.common.user);
  const userLimits = userData?.limits;
  const userPlan = userData?.plan;
  const [isRunning, setRunning] = useState<boolean>(false);
  const [isTesting, setTesting] = useState<boolean>(false);
  const [isCreatingLoading, setCreatingLoading] = useState<boolean>(false);
  const [advancedMode, setAdvancedMode] = useState(false);

  const [name, setName] = useState<string>("");
  const [method, setMethod] = useState<string>("GET");
  const [endpoint, setEndpoint] = useState<string>("");
  const [port, setPort] = useState<string>("");
  const [monitorType, setMonitorType] = useState<monitorTypes>(monitorTypes.https);

  const [requestPayload, setRequestPayload] = useState<IHTTPRequestData>();

  const [monitoringInterval, setMonitoringInterval] = useState<number>(
    userPlan === PLANS_BY_ID.free ?
      checkIntervalsEnum.minute5
      : userPlan === PLANS_BY_ID.business ?
        checkIntervalsEnum.second30 : checkIntervalsEnum.minute30
  );

  const [timeoutInterval, setTimeoutInterval] = useState<number>(checkIntervalsEnum.second30);
  const [expectedStatusCode, setExpectedStatusCode] = useState<number | null>(1);
  const [bodyTextAssert, setBodyTextAssert] = useState<string>("");

  const [datacenterRegions, setDatacenterRegions] = useState<number[]>([]);

  const isHTTP = useMemo(() => monitorType === monitorTypes.https, [monitorType]);
  const selectedStatusCode = useMemo(() => httpStatusCodeOptions.find(h => h.value === expectedStatusCode), [expectedStatusCode])
  const getURLPlaceholder = useMemo(() => {
    if (monitorType === monitorTypes.ping || monitorType === monitorTypes.port) return "0.0.0.0";
    return "https://------.com/get-weather";
  }, [monitorType]);

  const isURLEmpty = useMemo(() => endpoint?.trim().length === 0, [endpoint]);
  const isURLMaxLength = useMemo(() => endpoint?.trim().length >= 500, [endpoint]);
  const isNameEmpty = useMemo(() => name?.trim().length === 0, [name]);
  const isDatacenterRegionsEmpty = useMemo(() => datacenterRegions.length === 0 && advancedMode === true, [datacenterRegions, advancedMode]);

  const checkIntervalOptionsRefined = useMemo(() => {
    return checkIntervalOptions.map(c => {
      if (userLimits?.monitor.minMonitorCheckIntervalLimit && c.value < userLimits.monitor.minMonitorCheckIntervalLimit) {
        return {
          ...c,
          label: userPlan !== PLANS_BY_ID.business ? (c.value <= 30 ? `${c.label} (Business plan)` : c.label) : c.label,
          disabled: true
        }
      }
      return c;
    })
  }, [userLimits, userPlan]);

  useEffect(() => {
    const fetchMonitorData = async (id: string) => {
      dispatch(setContentLoading(true));
      Request.get(`${endpoints.monitor}/${id}/details/edit`)
        .then(m_Data => {
          dispatch(setContentLoading(false));
          const mData = m_Data?.data?.payload;

          if (m_Data.data.success === false || _.isNaN(mData) || _.isNull(mData)) {
            notification.error({ message: "Monitor not found" });
            navigate("/monitors");
            return;
          }

          if (_.isString(mData?.monitorId)) {
            setName(mData?.name);
            setEndpoint(mData?.endpoint);
            if (mData?.method) setMethod(mData?.method);
            if (mData?.port) setPort(mData?.port);
            setMonitorType(mData.monitorType);
            if (mData?.regionIDList) setDatacenterRegions(mData?.regionIDList);
            if (mData?.requestPayload) setRequestPayload(mData?.requestPayload);
            if (mData?.checkInterval) setMonitoringInterval(mData?.checkInterval);
            if (mData?.timeoutInterval) setTimeoutInterval(mData?.timeoutInterval);
            if (mData?.responseAssert) setBodyTextAssert(mData?.responseAssert);
            if (mData?.expectedHTTPCode) setExpectedStatusCode(mData?.expectedHTTPCode);
            setAdvancedMode(mData?.advanced);
          }
        })
        .catch(_err => {
          console.log(_err, 'er');
          dispatch(setContentLoading(false));
        });
    }

    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (id) {
      fetchMonitorData(id);
    } else {
      setDatacenterRegions(serverRegionsData.filter(dR => dR.unavailable === false).map(dR => dR.regionId))
    }
  }, [id, dispatch, navigate, serverRegionsData])

  const toggleAdvancedMode = () => setAdvancedMode(prev => !prev);

  const onRunAction = () => {
    setRunning(true);
    setTesting(true);
    setTimeout(() => {
      setRunning(false);
      setTesting(false);
    }, 1000);
  };
  const onCreateAction = (isEdit = false) => {
    setRunning(true);
    setCreatingLoading(true);
    Request({
      url: isEdit ? `${endpoints.monitor}/${id}` : endpoints.monitor,
      method: isEdit ? "PUT" : "POST",
      data: {
        monitorType,
        name,
        endpoint,
        ...((monitorType === monitorTypes.https && isHTTP) ? { method } : null),
        ...(monitorType === monitorTypes.port ? { port } : null),
        ...(advancedMode ? {
          regionIDList: datacenterRegions,
          ...(isHTTP ? { requestPayload: clearRequestPayloadEmptyValues(requestPayload) } : null),
          checkInterval: monitoringInterval,
          ...(isHTTP ? {
            timeoutInterval,
            responseAssert: bodyTextAssert?.length === 0 ? null : bodyTextAssert,
            expectedHTTPCode: expectedStatusCode
          } : null),
        } : null),
        advanced: advancedMode,
      }
    })
      .then((mData) => {
        if (Boolean(mData?.data?.success)) {
          if (isEdit) {
            notification.success({
              message: "Monitor is updated."
            })
          } else if (_.isString(mData?.data?.payload?.monitorId)) {
            notification.success({
              message: "Monitor is started..."
            })
            // navigate(`/monitors/view/${mData.data.payload.monitorId}`, { replace: true })
          }
          navigate("/monitors")
        }
        setRunning(false);
        setCreatingLoading(false);
      })
      .catch(_err => {
        console.log(_err, 'mData create');
        setRunning(false);
        setCreatingLoading(false);
      })
  };
  const onEditAction = () => onCreateAction(true);
  const onDeleteAction = () => {
    setRunning(true);
    Request.delete(`${endpoints.monitor}/${id}`)
      .then(mData => {
        if (Boolean(mData?.data?.success)) {
          notification.success({
            message: "Monitor is deleted."
          })
          navigate(`/monitors`)
        }
        setRunning(false);
      })
      .catch(_err => {
        setRunning(false);
      });
  };

  const toggleRegion = (id: number) => {
    if (datacenterRegions.indexOf(id) === -1) {
      setDatacenterRegions(prev => [...prev, id]);
    } else {
      if (datacenterRegions.length === 1) return;
      setDatacenterRegions(prev => prev.filter(p => p !== id));
    }
  }

  const clearRequestPayloadEmptyValues = (data: IHTTPRequestData | undefined) => {
    if (!data) return null;

    let refinedBody = {}

    if (data.body?.dataType === IHTTPRequestDataTypes.FORM) {
      refinedBody = Object.assign(refinedBody, {
        form: removeEmptyObjects(data.body?.form)
      });
    } else if (data.body?.dataType === IHTTPRequestDataTypes.JSON) {
      refinedBody = Object.assign(refinedBody, {
        JSON: data.body?.JSON
      });
    } else if (data.body?.dataType === IHTTPRequestDataTypes.XML) {
      refinedBody = Object.assign(refinedBody, {
        XML: data.body?.XML
      });
    }

    return {
      ...data,
      headers: removeEmptyObjects(data?.headers),
      params: removeEmptyObjects(data?.params),
      body: {
        dataType: data.body?.dataType,
        ...refinedBody
      }
    }
  }

  return (
    <PageContent title={`${id ? "Edit" : "Create"} monitor`} backButton footer={
      <FooterContent
        isEdit={Boolean(id)}
        onRun={onRunAction}
        onEdit={onEditAction}
        onDelete={onDeleteAction}
        onCreate={() => onCreateAction(false)}
        onToggleAdvanced={toggleAdvancedMode}
        isRunning={isRunning}
        isTesting={isTesting}
        isCreateDisabled={isURLEmpty || isURLMaxLength || isNameEmpty || isDatacenterRegionsEmpty}
        isCreatingLoading={isCreatingLoading}
        advancedMode={advancedMode}
      />
    }>
      <Form
        className="monitor-view-container"
        layout="vertical"
      >
        <Form.Item label={<FormItemTitle title="Name" tooltip="Enter the name of this monitor." />}>
          <Input value={name} style={{ maxWidth: 400 }} maxLength={100} onChange={e => setName(e.target.value)} placeholder="Weather forecast API" />
        </Form.Item>
        <Form.Item label={<FormItemTitle title="URL for monitoring" tooltip="The monitors will use this to do health checks." />}>
          <div className="monitor-url-container">
            {(advancedMode && isHTTP) && (
              <Select defaultValue={method} value={method} onChange={setMethod} style={{ width: 120 }}>
                {HTTP_METHODS_ARR.map(m => <Option key={m} value={m}>{m}</Option>)}
              </Select>
            )}
            <Input value={endpoint} onChange={e => setEndpoint(e.target.value)} maxLength={500} style={{ maxWidth: 600 }} placeholder={getURLPlaceholder} />
            {monitorType === monitorTypes.port && <Input value={port} style={{ maxWidth: 80 }} onChange={e => setPort(e.target.value)} placeholder={"0000"} />}
          </div>
          {isURLMaxLength && <Tag style={{ marginTop: 10 }} color="yellow">URL can't be more than 500 characters. If you have long queries, please enable <b>Advanced mode</b> and use <b>Request payload</b> editor.</Tag>}
        </Form.Item>
        <Form.Item label={<FormItemTitle title="Type" tooltip="Choose the type of monitoring. You can monitor an URL or directly an IP address (with port)." />}>
          <Radio.Group defaultValue={monitorType} value={monitorType} onChange={e => setMonitorType(e.target.value)}>
            <Radio.Button value={monitorTypes.https}>HTTP(s)</Radio.Button>
            <Radio.Button value={monitorTypes.port}>Port</Radio.Button>
            <Radio.Button value={monitorTypes.ping}>Ping (ICMP)</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {advancedMode && (
          <>
            <Divider />
            <RegionSelectionTab
              label={<FormItemTitle title={`Data center regions (${datacenterRegions?.length}/${serverRegionsData.length})`} tooltip="You can select any region to do monitoring from." />}
              selectedArr={datacenterRegions}
              onToggle={toggleRegion}
            />
            <Divider />
            {isHTTP && (
              <>
                <Form.Item label={<FormItemTitle title="Request payload" tooltip="Customizing request can be helpful for some edge cases. You can add custom headers, params or body elements." />}>
                  <RequestContainer data={requestPayload} onDataChange={d => setRequestPayload(d.itemData)} hideURL />
                </Form.Item>
                <Divider />
              </>
            )}
            <FormSettingsItem name="Check interval" tooltip="Select an interval for the monitoring.">
              <Select
                defaultValue={monitoringInterval}
                style={{ width: 300 }}
                onChange={setMonitoringInterval}
                options={checkIntervalOptionsRefined}
              />
            </FormSettingsItem>
            {isHTTP && (
              <>
                <FormSettingsItem name="Timeout interval" tooltip="When should we consider the request timeouts?">
                  <Select
                    defaultValue={timeoutInterval}
                    style={{ width: 300 }}
                    onChange={setTimeoutInterval}
                    options={timeoutIntervalOptions}
                  />
                </FormSettingsItem>
                <FormSettingsItem
                  name="Response should contain"
                  tooltip="Enter any text that the response should contain. If not contained, it will be detected as downtime."
                  info={
                    (bodyTextAssert.trim().length === 0 && bodyTextAssert.length > 0) ? "You can't enter just empty space." :
                      bodyTextAssert.length > 0 ? `You have entered ${bodyTextAssert.length} character${bodyTextAssert.length > 1 ? "s" : ""} to response assertion.` : ""
                  }
                  infoColor={(bodyTextAssert.trim().length === 0 && bodyTextAssert.length > 0) ? "red" : "yellow"}
                >
                  <Input
                    value={bodyTextAssert}
                    placeholder="Response text"
                    style={{ width: 300 }}
                    onChange={e => setBodyTextAssert(e.target.value)}
                  />
                </FormSettingsItem>
                <FormSettingsItem name="Expected HTTP status code" info={selectedStatusCode?.title}>
                  <Select
                    defaultValue={expectedStatusCode}
                    style={{ width: 300 }}
                    onChange={setExpectedStatusCode}
                    options={httpStatusCodeOptions}
                  />
                </FormSettingsItem>
              </>
            )}
          </>
        )}
      </Form>
    </PageContent>
  );
}

export default MonitorsManage;
