import { CheckOutlined, QuestionCircleFilled } from "@ant-design/icons"
import { Tooltip } from "antd"
import Flags, { FlagCode } from "../assets/images/flags"

type IRegionItemTypes = {
    id: number,
    countryCode: string,
    city: string,
    country: string
    onClick: (id: number) => void
    isActive: boolean
    unavailable: boolean
}

const RegionItem = (props: IRegionItemTypes) => {
    return (
        <div style={{ position: "relative" }}>
            <div
                className={`region-item ${props.unavailable ? "region-item-unavailable" : (props.isActive ? "region-item-active" : "")}`}
                onClick={!props.unavailable ? () => props.onClick(props.id) : undefined}
            >
                <div className="region-content">
                    <div className="region-flag">
                        <Flags code={props.countryCode as FlagCode} />
                    </div>
                    <div className="region-info">
                        <div className="region-city">{props.city}</div>
                        <div className="region-country">{props.country}</div>
                    </div>
                </div>
                {props.isActive && <CheckOutlined />}
            </div>
            {props.unavailable && <div style={{ position: "absolute", right: -5, top: -10 }} >
                <Tooltip title="Available soon"><QuestionCircleFilled /></Tooltip>
            </div>}
        </div>
    )
}

export default RegionItem;