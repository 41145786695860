import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../utils/hooks/useRedux';
import { useMemo } from 'react';
import * as Sentry from "@sentry/react";
import { ACCOUNT_SESSION } from '../utils/types';
import differenceInDays from 'date-fns/differenceInDays'

function CalculatePercentageAndRemainingDays(user: ACCOUNT_SESSION): { percentage: number, remainingDays: number } | null {
    try {
        const trialEndDate = user?.subscription?.meta?.trialEnd;
        if (!trialEndDate) return null;
        const trialEnd = new Date(trialEndDate);
        const today = new Date();
        const diff = differenceInDays(trialEnd, today);
        const total = differenceInDays(trialEnd, new Date(trialEnd.getFullYear(), trialEnd.getMonth(), 1));
        const percentage = (diff / total) * 100;
        return { percentage, remainingDays: diff };
    } catch (e) {
        Sentry.captureException(e);
        return null;
    }
}

function FreeTrialBar(props: { isActive?: (path: string) => string, isDisabled?: string, hideLabel?: boolean }) {
    const userData = useAppSelector(state => state.common.user);

    const daysInfo = useMemo(() => {
        return userData ? CalculatePercentageAndRemainingDays(userData) : null;
    }, [userData])

    if (!daysInfo) return null;
    return (
        <Link to={"/billing"} className={props?.isDisabled}>
            <div className='title'>
                <div style={{ display: "flex", alignItems: "center", width: 20, height: 20 }}>
                    <CircularProgressbar
                        value={daysInfo?.percentage || 0}
                        styles={buildStyles({
                            pathColor: "#f5cb5c",
                            trailColor: '#273041',
                        })}
                    />
                </div>
                {!props.hideLabel && (`${daysInfo?.remainingDays} day${daysInfo.remainingDays > 1 ? 's' : ''} left in trial`)}
            </div>
        </Link>
    );
}

export default FreeTrialBar;  