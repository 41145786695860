import { useMemo, useRef, useState } from "react";
import { SwapOutlined, SyncOutlined, CheckOutlined } from "@ant-design/icons";
import { useAppSelector, useAppDispatch } from "../utils/hooks/useRedux";
import { setProject, setProjects } from "../utils/redux/features/common";
import { useOnClickOutside } from "../utils/hooks/useOnClickOutside";
import CreateProjectModal from "./CreateProjectModal";

function ProjectSelectbox({ hasSidebarExpanded }: { hasSidebarExpanded: boolean }) {
  const ref = useRef(null)
  const dispatch = useAppDispatch();
  const commonData = useAppSelector(state => state.common);
  const userProjects = commonData?.projects;
  const [isLoading, setIsLoading] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [createProjectModalDisplay, setCreateProjectModalDisplay] = useState(false);

  function toggleProjectSelector() {
    setIsShown(!isShown);
  }

  function setProjectId(projectId: string) {
    if (projectId === commonData.projectId) return;
    setIsLoading(true);
    dispatch(setProject(projectId as never));
    setTimeout(() => {
      window.location.href = '/';
    }, 500);
  }

  const onModalClose = (projectId?: string | undefined, projects?: any[] | undefined) => {
    setCreateProjectModalDisplay(false)
    if (projectId && projects) {
      dispatch(setProjects(projects));
      dispatch(setProject(projectId as never));
      setTimeout(() => {
        window.location.href = '/';
      }, 500);
    }
  }

  const currentProject = useMemo(() => {
    return userProjects?.find(project => project.projectId === commonData.projectId);
  }, [userProjects, commonData.projectId]);

  const handleClickOutside = () => {
    if (isShown) {
      setIsShown(false);
    }
  }

  const openCreateProjectModal = () => {
    setCreateProjectModalDisplay(true);
    handleClickOutside();
  }

  useOnClickOutside(ref, handleClickOutside)

  return (
    <>
      <div className="project-select-box" ref={ref}>
        <div className="current-project" style={hasSidebarExpanded ? { justifyContent: 'center' } : undefined} onClick={toggleProjectSelector}>
          {!hasSidebarExpanded && (
            <div className="project-info">
              <div className="title">Project</div>
              <div className="project-name">{currentProject?.name}</div>
            </div>
          )}
          <SwapOutlined />
        </div>

        <div className={`projects-selector${isShown ? ' projects-selector-active' : ''}${hasSidebarExpanded ? ' projects-selector-floating' : ''}`}>
          {userProjects?.length === 0 ? (
            <div className="no-project">No project</div>
          ) : <div className="project-list">
            {userProjects?.map((project, index) => (
              <div
                key={index}
                className="project-item"
                onClick={() => setProjectId(project.projectId)}
              >
                <div className="project-name">{project.name}</div>
                {project.projectId === commonData.projectId && (isLoading ? <SyncOutlined spin /> : <CheckOutlined />)}
              </div>
            ))}
            <button onClick={openCreateProjectModal} className="create-project">Create a new project</button>
          </div>}
        </div>
      </div>
      <CreateProjectModal
        isOpen={createProjectModalDisplay}
        onClose={onModalClose}
      />
    </>
  );
}

export default ProjectSelectbox;