import { Link } from "react-router-dom";

export default function RootPanelWrapper({ children }: any) {
    return (
        <div style={{ padding: 10, }}>
            <ul style={{ display: "flex", gap: 10, listStyleType: "none" }}>
                <li><Link to="/root/all-customers">Customers</Link></li>
                <li><Link to="/root/all-monitors">Monitors</Link></li>
            </ul>
            <div>
                {children}
            </div>
        </div>
    )
}