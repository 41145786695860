import { MessageOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { useState } from "react";
import FeedbackWidget from "./FeedbackWidget";

const PageHeaderRightSection = () => {
    const [feedbackVisible, setFeedbackVisible] = useState(false);

    const handleVisibleChange = (visible: boolean) => {
        setFeedbackVisible(visible);
    }

    return (
        <div className="page-content-header-right">
            <Popover
                placement="bottomLeft"
                content={<FeedbackWidget feedbackVisible={feedbackVisible} />}
                onOpenChange={handleVisibleChange}
                trigger="click"
            >
                <Button>
                    <MessageOutlined /> Feedback
                </Button>
            </Popover>
            <a href="https://docs.onradar.io" target="_blank" rel="noreferrer">Docs</a>
        </div>
    )
}

export default PageHeaderRightSection;