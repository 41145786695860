import { Node } from "reactflow";
import { IKeyValueData } from "./types";
import ShortUniqueId from 'short-unique-id';

export const domainValidation = (value: string) => value.indexOf(".") !== -1;
export const emailValidation = (value: string) => value.indexOf("@") !== -1 && value.indexOf(".") !== -1;

export function calculatePercentages(data: any) {
  const total: any = Object.values(data).reduce((sum: any, value) => sum + value, 0);

  let percentages: any = {};
  let remainingPercentage = 100;

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      let percentage = Math.floor((data[key] / total) * 100);
      percentages[key] = percentage;
      remainingPercentage -= percentage;
    }
  }

  const lastKey: any = Object.keys(percentages).pop();
  percentages[lastKey] += remainingPercentage;

  return percentages;
}

export function formatNumber(number: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3
  });

  return formatter.format(number);
}

export function removeEmptyObjects(data: IKeyValueData[] | null | undefined): IKeyValueData[] | null {
  if (!data) return null;
  const purified = data.filter(item => {
    return item.data_key !== '' && item.data_value !== '';
  });
  if (purified.length === 0) return null;
  return purified;
}

export const formattedDate = (date: Date | string) => new Date(date).toLocaleString(undefined, {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

export const isValidJSON = (jsonString: string) => {
  try {
    JSON.parse(JSON.stringify(jsonString));
  } catch (e) {
    return false;
  }
  return true;
}

export const extractUrlFromCurl = (curlCommand: string): string | null => {
  const sanitizedCommand = curlCommand.replace(/['"]/g, '');
  const parts = sanitizedCommand.split(' ');

  for (let i = 0; i < parts.length; i++) {
    if (parts[i].startsWith('http://') || parts[i].startsWith('https://')) {
      return parts[i];
    }
  }

  return null;
}

export const sortEdges = (nodes: any, edges: any) => {
  const sortedEdges: any = [];
  const visited = new Set();

  function traverseEdge(sourceId: any) {
    const connectedEdges = edges.filter((edge: any) => edge.source === sourceId);
    connectedEdges.forEach((edge: any) => {
      const { id, target } = edge;
      if (!visited.has(id)) {
        visited.add(id);
        sortedEdges.push(edge);
        traverseEdge(target);
      }
    });
  }

  traverseEdge(nodes[0].id);

  return sortedEdges;
}

export const getShortId = (nodes: Node[], retry?: number | undefined): string => {
  const uid = new ShortUniqueId({ length: 10 });
  const id = uid.rnd();

  const isIdUnique = nodes.every((node) => node.id !== id);

  if (isIdUnique) {
    return id;
  } else {
    if (retry && retry > 3) {
      throw new Error('Could not generate a unique id');
    }
    return getShortId(nodes, retry ? retry + 1 : 1);
  }
}

export const getEnumValues = (enumObj: any, type: 'string' | 'number' = 'number') => Object.values(enumObj).filter(value => typeof value === type);

export const getMainDomainName = (url: string) => {
  try {
    const { hostname } = new URL(url);
    const parts = hostname.split('.').slice(-2);
    return parts.join('.');
  } catch (error) {
    return null;
  }
}

export const getCompanyNameFromEmail = (email: string) => {
  try {
    return email.split('@')[1].split('.')[0];
  } catch (error) {
    return null;
  }
}

export const calculateAnnualPrice = (price: number) => price * 12;
export const calculateAnnualDiscountedPrice = (price: number) => price * 12 * 0.8;

export const openPopupCenter = (url: string, title?: string, w?: number, h?: number) => {
  try {
    const getSpecs = (w: any, h: any, top: any, left: any) => {
      return `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`;
    };

    const getFirstNumber = (potentialNumbers: any) => {
      for (let i = 0; i < potentialNumbers.length; i++) {
        const value = potentialNumbers[i];

        if (typeof value === 'number') {
          return value;
        }
      }
    };

    const dualScreenLeft: any = getFirstNumber([window.screenLeft, (window.screen as any).left]);
    const dualScreenTop: any = getFirstNumber([window.screenTop, (window.screen as any).top]);
    const width: any = getFirstNumber([window.innerWidth, document.documentElement.clientWidth, window.screen.width]);
    const height: any = getFirstNumber([window.innerHeight, document.documentElement.clientHeight, window.screen.height]);
    const left = ((width / 2) - (w ? (w / 2) : 0)) + dualScreenLeft;
    const top = ((height / 2) - (h ? (h / 2) : 0)) + dualScreenTop;
    const newWindow = window.open(url, title, getSpecs(w, h, top, left));

    window.focus();

    return newWindow;
  } catch (error) {
    console.error('Error opening popup', error);
    return window.open(url, title, `width=${w},height=${h}`) as any;
  }
}