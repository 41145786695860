import { Alert, Button } from "antd";
import _ from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../utils/hooks/useRedux";

export function NoPage() {
  const navigate = useNavigate();
  const hasAuth = useAppSelector(state => state.common.user?.token);

  useEffect(() => {
    if (_.isNil(hasAuth)) navigate("/login");
  }, [hasAuth, navigate])

  return (
    <div>
      <Alert
        message="404"
        description="Sorry, the page you are looking for could not be found."
        type="error"
      />
      <Button type="primary" onClick={() => window.location.href = '/'} style={{marginTop: 10}} >Go to homepage</Button>
    </div>
  );
}

export function AlreadyAuth() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate])

  return <></>
}