import USA from "./USA";
import UK from "./UK";
import BR from "./BR";
import CA from "./CA";
import FR from "./FR";
import AU from "./AU";
import KR from "./KR";
import IN from "./IN";
import SG from "./SG";
import JP from "./JP";
import BH from "./BH";
import DE from "./DE";

export enum FlagCode {
    USA = "USA",
    UK = "UK",
    BR = "BR",
    CA = "CA",
    FR = "FR",
    AU = "AU",
    KR = "KR",
    IN = "IN",
    SG = "SG",
    JP = "JP",
    BH = "BH",
    DE = "DE",
}

const Flags = (props: { code: FlagCode, height?: number }) => {
    const iprops = {height: props?.height || 35}
    if (props.code === "USA") return <USA {...iprops} />
    if (props.code === "UK") return <UK {...iprops} />
    if (props.code === "BR") return <BR {...iprops} />
    if (props.code === "CA") return <CA {...iprops} />
    if (props.code === "FR") return <FR {...iprops} />
    if (props.code === "AU") return <AU {...iprops} />
    if (props.code === "KR") return <KR {...iprops} />
    if (props.code === "IN") return <IN {...iprops} />
    if (props.code === "SG") return <SG {...iprops} />
    if (props.code === "JP") return <JP {...iprops} />
    if (props.code === "BH") return <BH {...iprops} />
    if (props.code === "DE") return <DE {...iprops} />
    return <>No flag.</>
}

export default Flags;