import { notification } from "antd";
import axios from "axios";
import { HOST } from "./config";
import { resetCommon } from "./redux/features/common";
import { store } from "./redux/store";
import { IGNORE_HTTP_ERROR_NOTIFY_BY_ERR_CODE } from "./constants";
import { resetCache, setUpgradeModalShow } from "./redux/features/cache";
import { resetConfig } from "./redux/features/ui-config";

axios.interceptors.request.use(function (config) {
    config.baseURL = HOST;
    if (config.headers) {
        const commonStore = store.getState().common;
        const userToken = commonStore?.user?.token;
        const projectId = commonStore?.projectId;
        if (userToken) (config.headers["x-auth-key"] as any) = commonStore?.user?.token;
        if (projectId) (config.headers["x-project-id"] as any) = commonStore?.projectId;
    }

    return config;
}, function (error) {
    return Promise.reject(error.toJSON());
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error?.response?.status === 401) {
        window.location.href = "/login";
        store.dispatch(resetCommon());
        store.dispatch(resetCache());
        store.dispatch(resetConfig());
    } else {
        const internalError = error?.response?.data;
        const shouldBeIgnored = IGNORE_HTTP_ERROR_NOTIFY_BY_ERR_CODE.includes(internalError?.code);
        if (internalError && !shouldBeIgnored) {
            if (internalError?.upgradePlan === true) {
                store.dispatch(setUpgradeModalShow({ show: true, error: internalError?.message } as never));
            } else {
                notification.error({
                    message: "Error",
                    description: `${internalError?.message} (${internalError?.code})`,
                    maxCount: 1,
                })
            }
        }
    }

    return Promise.reject(error);
});

const Request = axios;

export default Request;