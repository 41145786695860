import { Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useCallback, useEffect, useRef, useState } from 'react';
import Request from '../../../utils/request';
import endpoints from '../../../utils/endpoints';
import { MonitorInterface } from '../../../utils/types';


const columns: ColumnsType<MonitorInterface> = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
    },
    {
        title: 'Method',
        dataIndex: 'method',
        key: 'method',
    },
    {
        title: 'Endpoint',
        dataIndex: 'endpoint',
        key: 'endpoint',
    },
    {
        title: 'Region',
        key: 'regionId',
        dataIndex: 'regionId',
        render: (_, record) => (
            <>
                {
                    record.regionIDList?.map((regionId: number) => (
                        <Tag color="blue" key={regionId}>
                            {regionId}
                        </Tag>
                    ))
                }
            </>
        ),
    },
];

export default function AllMonitors() {
    const dataFetchedRef = useRef(false);
    const [allMonitors, setAllMonitors] = useState([]);
    const [pagination, setPagination] = useState({
        limit: 500,
        page: 1,
        totalDocs: 1,
        totalPages: 1,
    });

    const fetchAllMonitors = useCallback(async () => {
        const res = await Request.get(endpoints.rootPanel + "monitors");
        if (
            res.data.success === true &&
            res.data.payload.data.length > 0
        ) {
            setAllMonitors(res.data.payload.data);
            setPagination(res?.data?.payload?.meta);
        } else {
            setAllMonitors([]);
        }
    }, [])
    console.log(allMonitors, 'allMonitors')
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchAllMonitors();
    }, [fetchAllMonitors])

    return (
        <div>
            <Table columns={columns} dataSource={allMonitors} />
        </div>
    )
}