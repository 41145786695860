import { Button, Tag } from "antd";
import PageContent from "../../components/PageContent";
import TelegramDrawer from "../../components/Integrations/TelegramDrawer";
import { useCallback, useEffect, useState } from "react";
import { AlertChannels, IntegrationCredentialsInterface, PLANS_BY_ID } from "../../utils/types";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/useRedux";
import { setCacheLinkedIntegrations } from "../../utils/redux/features/cache";
import endpoints from "../../utils/endpoints";
import Request from "../../utils/request";
import useIsMounted from "../../utils/hooks/useIsMounted";
import { upperFirst } from "lodash";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import DangerActionConfirmModal from "../../components/DangerActionConfirmModal";
import IntegrationIcons from "../../assets/images/integrations";
import SlackDrawer from "../../components/Integrations/SlackDrawer";
import WebhookDrawer from "../../components/Integrations/WebhookDrawer";

function Integrations() {
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();
  const linkedIntegrations = useAppSelector(state => state.cache.linkedIntegrations);
  const userData = useAppSelector(state => state.common.user);
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [channelId, setChannelId] = useState<number | string | null>(null);
  const [integrationEditData, setIntegrationEditData] = useState<IntegrationCredentialsInterface | null>(null);
  const [deleteIntegrationId, setDeleteIntegrationId] = useState<string | null>(null);
  const [deleteIntegrationLoadingId, setDeleteIntegrationLoadingId] = useState<string | null>(null);

  const fetchLinkedIntegrations = useCallback(() => {
    Request.get(endpoints.integration).then(res => {
      if (res.data?.payload) dispatch(setCacheLinkedIntegrations(res.data?.payload));
    })
      .catch(() => {
      });
  }, [dispatch])

  useEffect(() => {
    if (isMounted()) {
      fetchLinkedIntegrations();
    }
  }, [isMounted, fetchLinkedIntegrations])

  const connectIntegration = (cId: number) => {
    setChannelId(cId);
    setIntegrationEditData(null);
  }

  const openEditIntegrationModal = (data: IntegrationCredentialsInterface) => {
    setChannelId(data.channel);
    setIntegrationEditData(data);
  }

  const closeNotificationSettings = () => {
    setChannelId(null);
    setIntegrationEditData(null);
  }

  const applyNotificationSettings = () => {
    closeNotificationSettings();
  }

  const openDangerModal = (iId: string | undefined) => {
    if (!iId) return;
    setDeleteIntegrationId(iId)
    setDangerModalOpen(true);
  }

  const closeDangerModal = () => {
    setDeleteIntegrationId(null)
    setDangerModalOpen(false);
  }

  const deleteIntegration = () => {
    if (!deleteIntegrationId) return;
    setDeleteIntegrationLoadingId(deleteIntegrationId);
    Request.delete(`${endpoints.integration}/${deleteIntegrationId}`)
      .then(() => {
        fetchLinkedIntegrations();
        setDeleteIntegrationLoadingId(null);
      })
      .catch(() => {
        setDeleteIntegrationLoadingId(null);
      });
  }

  return (
    <>
      <PageContent title="Integrations" contentClassName="settings-container">
        {linkedIntegrations?.length !== 0 && (
          <div className="settings-item">
            <h2 className="settings-label">Active channels</h2>
            <div className="settings-content">
              <div className="channels-container">
                {
                  linkedIntegrations.map(integration => {
                    const channelKey = Object.keys(AlertChannels).find(key => AlertChannels[key as keyof typeof AlertChannels] === integration.channel);
                    const channelValue = AlertChannels[channelKey as keyof typeof AlertChannels];
                    const channelName = upperFirst(channelKey);

                    return (
                      <div key={integration.integrationId} className="channel-item" style={{ flexDirection: "row", alignItems: "center" }}>
                        <div className="channel-info">
                          <div className="channel-top" style={{ gap: 10, alignItems: "center" }}>
                            <div className="channel-icon"><IntegrationIcons code={channelValue} /></div>
                            <h3>{integration.name}</h3>
                            {integration.channel !== AlertChannels.email && <p style={{ margin: 0 }}>{channelName}</p>}
                          </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                          {
                            integration.channel !== AlertChannels.email && (
                              <>
                                <Button className="integration-delete-btn" type="primary" loading={deleteIntegrationLoadingId === integration?.integrationId} danger onClick={() => openDangerModal(integration?.integrationId)}><CloseOutlined /></Button>
                                <Button className="integration-delete-btn" type="primary" onClick={() => openEditIntegrationModal(integration as IntegrationCredentialsInterface)}><EditOutlined /></Button>
                              </>
                            )
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        )}

        <div className="settings-item">
          <h2 className="settings-label">Alert channels</h2>
          <div className="settings-content">
            <div className="channels-container">
              <div className="channel-item">
                <div className="channel-info">
                  <div className="channel-top">
                    <div className="channel-icon"><IntegrationIcons code={AlertChannels.telegram} /></div>
                    <h3>Telegram</h3>
                  </div>
                  <p>Receive alerts on Telegram group or private chat.</p>
                </div>
                <Button type="primary" onClick={() => connectIntegration(AlertChannels.telegram)}>Connect</Button>
              </div>

              <div className="channel-item">
                <div className="channel-info">
                  <div className="channel-top">
                    <div className="channel-icon"><IntegrationIcons code={AlertChannels.webhook} /></div>
                    <h3>Webhook</h3>
                    {(userData && (userData?.plan === PLANS_BY_ID.free)) && <Tag color="warning">Paid plans only</Tag>}
                  </div>
                  <p>Receive alerts on your webhook.</p>
                </div>
                <Button type="primary" disabled={userData?.plan === PLANS_BY_ID.free} onClick={() => connectIntegration(AlertChannels.webhook)}>Connect</Button>
              </div>

              <div className="channel-item">
                <div className="channel-info">
                  <div className="channel-top">
                    <div className="channel-icon"><IntegrationIcons code={AlertChannels.slack} /></div>
                    <h3>Slack</h3>
                    {(userData && (userData?.plan === PLANS_BY_ID.free)) && <Tag color="warning">Startup and above plans only</Tag>}
                  </div>
                  <p>Receive alerts on Slack channel.</p>
                </div>
                <Button type="primary" disabled={userData?.plan !== PLANS_BY_ID.startup && userData?.plan !== PLANS_BY_ID.business} onClick={() => connectIntegration(AlertChannels.slack)}>Connect</Button>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
      <SlackDrawer
        channelId={channelId}
        data={integrationEditData}
        isCreate={integrationEditData === null}
        onClose={closeNotificationSettings}
        onApply={applyNotificationSettings}
        refreshIntegrations={fetchLinkedIntegrations}
      />
      <TelegramDrawer
        channelId={channelId}
        data={integrationEditData}
        onClose={closeNotificationSettings}
        onApply={applyNotificationSettings}
        refreshIntegrations={fetchLinkedIntegrations}
      />
      <WebhookDrawer
        channelId={channelId}
        data={integrationEditData}
        onClose={closeNotificationSettings}
        onApply={applyNotificationSettings}
        refreshIntegrations={fetchLinkedIntegrations}
      />
      <DangerActionConfirmModal
        isOpen={dangerModalOpen}
        onClose={closeDangerModal}
        onSubmit={deleteIntegration}
        messageText="Are you sure you want to delete this integration?"
      />
    </>
  );
}

export default Integrations;
