import * as Sentry from "@sentry/react";
import { CartesianGrid, Tooltip, ResponsiveContainer, XAxis, YAxis, LineChart, Line } from "recharts";
import ChartTooltip from "./Tooltips/ChartTooltip";
import { uptimeLogsData } from "../../utils/types";
import { useMemo } from "react";
import { colorPalette } from "../../utils/constants";
import _ from "lodash";

export function ResponseTimeChart({ data }: { data: uptimeLogsData[] }) {
    const byRegion = useMemo(() => data.reduce((acc, curr) => {
        if (!acc[curr.regionId]) {
            acc[curr.regionId] = [];
        }
        acc[curr.regionId].push(curr);
        return acc;
    }, {} as any), [data])

    const groupedData = useMemo(() => {
        try {
            const unifiedData: any = [];

            if (Object.keys(byRegion).length === 0) {
                return [];
            }

            const mainRegion = Object.keys(byRegion).reduce((acc, curr) => {
                if (byRegion[acc].length < byRegion[curr].length) {
                    return curr;
                }
                return acc;
            });

            for (const region in byRegion[mainRegion]) {
                const d: any = {
                    date: byRegion[mainRegion][region].date,
                    status: byRegion[mainRegion][region].status,
                    uptime: byRegion[mainRegion][region].uptime,
                    regions: { ['region' + mainRegion + 'duration']: byRegion[mainRegion][region] },
                    ['region' + mainRegion + 'duration']: byRegion[mainRegion][region].duration,
                };
                for (const subRegion in byRegion) {
                    if (subRegion !== mainRegion && byRegion?.[subRegion]?.[region]?.duration) {
                        d.regions = {
                            ...d.regions,
                            ['region' + subRegion + 'duration']: byRegion[subRegion][region]
                        }
                        d['region' + subRegion + 'duration'] = byRegion[subRegion][region].duration;
                    }
                }
                unifiedData.push(d);
            }
            return unifiedData;
        } catch (error) {
            Sentry.captureException(error);
            return [];
        }
    }, [byRegion])

    return (
        <ResponsiveContainer width="100%" height={250}>
            <LineChart
                data={groupedData}
                layout="horizontal"
                reverseStackOrder={false}
                stackOffset={"none"}
            >
                <CartesianGrid
                    strokeDasharray="3 3"
                    vertical={false}
                    stroke="#6d67e4"
                    opacity={0.1}
                />
                <XAxis dataKey="date" axisLine={false} tickLine={false} fontSize={8} tickFormatter={(value) => new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} />
                <YAxis axisLine={false} tickLine={false} fontSize={8} tickFormatter={(value) => `${value} ms`} />
                <Tooltip content={<ChartTooltip />} offset={50} />
                {Object.keys(byRegion).map((regionId, index) => (
                    <Line
                        key={'region' + regionId + 'duration'}
                        type="linear"
                        dataKey={'region' + regionId + 'duration'}
                        dot={false}
                        stroke={colorPalette[index % colorPalette.length]}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}