import { Button, Input, Modal, Tag, notification } from "antd";
import { useMemo, useRef, useState } from "react";
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import Request from "../utils/request";
import endpoints from "../utils/endpoints";
import isNaN from "lodash/isNaN";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import { useAppSelector } from "../utils/hooks/useRedux";

type ModalTypes = {
    isOpen: boolean;
    onClose: (projectId?: string | undefined, projects?: any[] | undefined) => void;
}

const CreateProjectModal = ({ isOpen, onClose }: ModalTypes) => {
    const userLimits = useAppSelector(state => state.common.user?.limits);
    const projectsArr = useAppSelector(state => state.common.projects);
    const [value, setValue] = useState('');
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null);
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [showError, setError] = useState(false);

    const projectCreateDisabled = useMemo(() => {
        const ownedProjects = projectsArr?.filter(project => project?.isOwner === true);
        return ownedProjects?.length >= (userLimits?.project?.maxProjectsLimit || 0)
    }, [projectsArr, userLimits]);

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    const actionCloseModal = (projectId?: string, projects?: any[] | undefined) => {
        setValue('');
        setError(false);
        setLoading(false);
        setDisabled(true);
        setBounds({ left: 0, top: 0, bottom: 0, right: 0 });
        onClose(projectId, projects);
    }

    const actionSubmit = () => {
        try {
            if (projectCreateDisabled) return;
            if (!value) return setError(true);
            setLoading(true);
            setError(false);

            Request.post(endpoints.project, { name: value })
                .then(m_Data => {
                    setLoading(false);
                    const mData = m_Data?.data?.payload;

                    if (m_Data.data.success === false || isNaN(mData) || isNull(mData) || !isString(mData?.projectId)) {
                        return notification.error({ message: "Project not created." });
                    }

                    actionCloseModal(mData?.projectId, mData?.projects);
                })
                .catch(_err => {
                    console.log(_err, 'er');
                    setLoading(false);
                });
        } catch (err) {
            console.error(err);
            setError(true);
            setLoading(false);
        }
    }

    return (
        <Modal
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                >
                    Create a project
                </div>
            }
            open={isOpen}
            footer={[
                <Button key="back" type="primary" onClick={() => actionCloseModal()}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" className="run-btn" loading={isLoading} disabled={isLoading || projectCreateDisabled} onClick={actionSubmit}>
                    Create
                </Button>,
            ]}
            onCancel={() => actionCloseModal()}
            modalRender={modal => (
                <Draggable
                    bounds={bounds}
                    disabled={disabled}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
        >
            <Input placeholder="Project name" disabled={projectCreateDisabled} value={value} onChange={e => setValue(e.target.value)} />
            {showError && <Tag color="red" style={{ marginTop: 15 }}>Please enter a project name.</Tag>}
            {projectCreateDisabled && <Tag color="volcano" style={{ marginTop: 15 }}>You have reached the maximum limit of projects.</Tag>}
        </Modal>
    )
}

export default CreateProjectModal;