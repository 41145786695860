type PropsType = {
    height?: string,
    width?: string,
    color?: string,
    style?: any,
    svgStyle?: any
    onClick?: () => void
}

const CompanyLogoIcon = (props: PropsType) => {
    return (
        <div className="company-logo-comp" style={{ display: "flex", alignItems: "center", justifyContent: "center", ...props?.style }}>
            <svg style={props?.svgStyle} onClick={props?.onClick} viewBox="0 0 347.419 432.79" xmlns="http://www.w3.org/2000/svg" width={props?.width ?? "100px"} height={props?.height ?? "100px"}>
                <g transform="matrix(0.1, 0, 0, -0.1, 0, 512)" fill={props?.color ?? "#f5cb5c"} stroke="none">
                    <path d="M 1939.981 5080.07 C 1213.981 5004.07 567.981 4549.07 243.981 3886.07 C -155.019 3071.07 11.981 2096.07 659.981 1449.07 C 1040.981 1069.07 1507.981 862.07 2066.981 826.07 L 2158.981 820.07 L 2158.981 1034.07 L 2158.981 1248.07 L 2066.981 1255.07 C 1624.981 1288.07 1262.981 1450.07 961.981 1749.07 C 635.981 2073.07 458.981 2497.07 458.981 2956.07 C 458.981 3608.07 820.981 4193.07 1407.981 4486.07 C 1612.981 4588.07 1896.981 4661.07 2093.981 4661.07 L 2158.981 4661.07 L 2158.981 4876.07 L 2158.981 5091.07 L 2091.981 5090.07 C 2053.981 5089.07 1985.981 5084.07 1939.981 5080.07 Z" />
                    <path d="M 2046.981 4230.07 C 1542.981 4186.07 1111.981 3845.07 949.981 3363.07 C 865.981 3112.07 863.981 2825.07 944.981 2568.07 C 1022.981 2321.07 1189.981 2088.07 1399.981 1931.07 C 1588.981 1790.07 1839.981 1698.07 2070.981 1684.07 L 2158.981 1678.07 L 2160.981 2317.07 L 2163.981 2956.07 L 2802.981 2959.07 L 3441.981 2961.07 L 3435.981 3049.07 C 3404.981 3552.07 3058.981 4002.07 2572.981 4169.07 C 2423.981 4220.07 2212.981 4245.07 2046.981 4230.07 Z" />
                </g>
            </svg>
        </div>
    )
}

export default CompanyLogoIcon;