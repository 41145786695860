import { memo, useMemo } from 'react';
import { Handle, Position, useReactFlow, useStoreApi } from 'reactflow';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks/useRedux';
import { setFlowNodeDrawer } from '../../../utils/redux/features/cache';
import { Button, Tooltip } from 'antd';
import { CopyFilled, SettingFilled } from '@ant-design/icons';
import classNames from 'classnames';
import { HTTP_METHOD_COLORS } from '../../../utils/constants';
import { getShortId } from '../../../utils/utils';
import { UPTIME_LOG_STATUSES } from '../../../utils/types';

// const selector = (s: any) => ({
//   nodeInternals: s.nodeInternals,
//   edges: s.edges,
// });

// const sourceLimit = 3;
// const targetLimit = 1;

function RequestNode({ id, data }: any) {
  const dispatch = useAppDispatch();
  const cacheState = useAppSelector(state => state.cache);
  const flowNodeDrawer = cacheState.flowNodeDrawer;
  const isFlowLocked = cacheState.isFlowLocked;
  const store = useStoreApi();
  const { setNodes } = useReactFlow();

  // const nodeId = useNodeId();

  const nodeId = useMemo(() => {
    const { nodeInternals } = store.getState();
    return Array.from(nodeInternals.keys())?.[0]
  }, [store]);

  const openDrawer = () => {
    if (flowNodeDrawer) {
      dispatch(setFlowNodeDrawer({ status: false, id: null } as never));
      setTimeout(() => {
        dispatch(setFlowNodeDrawer({ status: true, id } as never));
      }, 500)
    } else {
      dispatch(setFlowNodeDrawer({ status: true, id } as never));
    }
  }

  const duplicateNode = () => {
    if (!id) return;
    const { nodeInternals } = store.getState();
    const node = nodeInternals.get(id);
    if (node) {
      const nodes = Array.from(nodeInternals.values());
      const newNode = {
        ...node,
        id: getShortId(nodes),
        position: {
          x: node.position.x + 500,
          y: node.position.y,
        }
      };
      setNodes([...Array.from(nodeInternals.values()), newNode]);
    }
  }

  // const isConnectableTarget = useMemo(() => {
  //   const node = nodeInternals.get(nodeId);
  //   const connectedEdges = getConnectedEdges([node], edges);
  //   return connectedEdges.length < targetLimit;
  // }, [edges, nodeId, nodeInternals])

  // const isConnectableSource = useMemo(() => {
  //   const node = nodeInternals.get(nodeId);
  //   const connectedEdges = getConnectedEdges([node], edges);
  //   return connectedEdges.length < sourceLimit;
  // }, [edges, nodeId, nodeInternals])

  return (
    <div className="flow-node-request" onDoubleClick={openDrawer}>
      <div className='actions-container'>
        <div className='node-id'>{id === "Initial" ? '' : 'ID: ' + id}</div>
        <div style={{ display: 'flex', gap: 10, height: 40 }}>
          {!isFlowLocked && (
            <>
              <Tooltip title="Duplicate">
                <Button type="primary" onClick={duplicateNode} shape='circle' size="large" style={{ backgroundColor: "#504d7e" }}><CopyFilled /></Button>
              </Tooltip>
              <Tooltip title="Configure">
                <Button type="primary" onClick={openDrawer} shape='circle' size="large" style={{ backgroundColor: "#504d7e" }}><SettingFilled /></Button>
              </Tooltip>
            </>
          )}
        </div>
      </div>

      <div className='info-container'>
        <h1>{data?.name || "Untitled " + id}</h1>
        {(data?.httpReq?.itemData?.URL || data?.httpReq?.itemData?.method) && <div className='node-url-container'>
          {data?.httpReq?.itemData?.method && (
            <div className='method-type' style={{ backgroundColor: HTTP_METHOD_COLORS?.[data?.httpReq?.itemData?.method as keyof typeof HTTP_METHOD_COLORS] }}>
              {data?.httpReq?.itemData?.method}
            </div>
          )}
          {data?.httpReq?.itemData?.URL && <div className='node-url'>{data?.httpReq?.itemData?.URL}</div>}
        </div>}
      </div>

      {data?.status === UPTIME_LOG_STATUSES.down ? <div className="node-in-progress node-in-progress-animation-red" /> : (
        (data?.isInProgress !== 0 && typeof data?.isInProgress !== 'undefined') ? <div className={classNames(
          'node-in-progress',
          data?.isInProgress !== 2 && 'node-in-progress-animation',
          data?.isInProgress === 1 && 'node-in-progress-animation-yellow',
          data?.isInProgress === 2 && 'node-in-progress-animation-green',
        )} /> : <></>
      )}

      <Handle
        type="source"
        position={Position.Right}
        //  isConnectable={isConnectableSource}
        id={"right_" + id}
      />
      {nodeId !== id && <Handle
        type="target"
        // isConnectable={isConnectableTarget}
        position={Position.Left}
        id={"left_" + id}
      />}
    </div>
  );
}

export default memo(RequestNode);