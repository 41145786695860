import { Collapse, Input, Select, Tabs } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { HTTP_HEADERS_ARR, HTTP_METHODS_ARR } from "../../utils/constants";
import { IHTTPRequestData } from "../../utils/types";
import KeyValueTable from "../KeyValueTable";
import BodyTab from "./BodyTab";
import { CodeOutlined, RadiusSettingOutlined, SettingOutlined } from "@ant-design/icons";
import { isDEV } from "../../utils/config";
import ReactJson from 'react-json-view'
import VariableKeyValueTable from "../VariableKeyValueTable";
import KeyValueTableVariableMode from "../KeyValueTableVariableMode";

const { Panel } = Collapse;
const { Option } = Select;

const RequestContainer = ({ data, variablesEnabled, nodeId, onDataChange, hideURL }: { data?: IHTTPRequestData, variablesEnabled?: boolean, nodeId?: string, onDataChange?: (data: any) => void, hideURL?: boolean }) => {
    const [itemData, setItemData] = useState<IHTTPRequestData | undefined>(data);

    useEffect(() => {
        if (onDataChange) onDataChange({ data, itemData });
        // eslint-disable-next-line
    }, [itemData]);

    return (
        <Collapse
            defaultActiveKey={['0', '1']}
            expandIconPosition={'end'}
        >
            <Panel header="Configs" key="0" extra={<SettingOutlined onClick={event => event.stopPropagation()} />}>
                <div className="w-100">
                    {(_.isNil(hideURL) || hideURL === false) && (
                        <div className="row align-center gap-10">
                            <Select defaultValue={data?.method || "GET"} style={{ width: 120 }} onChange={value => setItemData((prev: any) => ({ ...prev, method: value }))}>
                                {HTTP_METHODS_ARR.map(m => <Option key={m} value={m}>{m}</Option>)}
                            </Select>
                            <Input placeholder="Request URL" defaultValue={data?.URL} onChange={e => setItemData((prev: any) => ({ ...prev, URL: e.target.value }))} />
                        </div>
                    )}

                    <div className="req-tab-content">
                        <Tabs
                            defaultActiveKey="1"
                            type="card"
                            size="large"
                            items={[
                                {
                                    label: "Headers",
                                    key: '1',
                                    children:
                                        variablesEnabled ?
                                            <KeyValueTableVariableMode defaultData={data?.headers} autoCompleteOptions={HTTP_HEADERS_ARR.map(hA => ({ value: hA }))} onDataChange={value => setItemData((prev: any) => ({ ...prev, headers: value }))} /> :
                                            <KeyValueTable defaultData={data?.headers} autoCompleteOptions={HTTP_HEADERS_ARR.map(hA => ({ value: hA }))} onDataChange={value => setItemData((prev: any) => ({ ...prev, headers: value }))} />,
                                },
                                {
                                    label: "Params",
                                    key: '2',
                                    children:
                                        variablesEnabled ?
                                            <KeyValueTableVariableMode defaultData={data?.params} onDataChange={value => setItemData((prev: any) => ({ ...prev, params: value }))} /> :
                                            <KeyValueTable defaultData={data?.params} onDataChange={value => setItemData((prev: any) => ({ ...prev, params: value }))} />,
                                },
                                {
                                    label: "Body",
                                    key: '3',
                                    children: <BodyTab defaultData={data?.body} onDataChange={value => setItemData((prev: any) => ({ ...prev, body: value }))} />,
                                },
                            ]}
                        />
                    </div>
                </div>
            </Panel>
            {
                (variablesEnabled && nodeId) && (
                    <Panel header="Variables" key="1" extra={<RadiusSettingOutlined onClick={event => event.stopPropagation()} />}>
                        <VariableKeyValueTable nodeId={nodeId} keyColumnTitle="Key" valueColumnTitle="Source" defaultData={data?.variables} onDataChange={value => setItemData((prev: any) => ({ ...prev, variables: value }))} />
                    </Panel>
                )
            }
            {(isDEV && data) && (
                <Panel header="JSON" key="2" extra={<CodeOutlined onClick={event => event.stopPropagation()} />}>
                    <ReactJson src={data} style={{ backgroundColor: 'white' }} />
                </Panel>
            )}
        </Collapse>
    )
}

export default RequestContainer;