export const IGNORE_HTTP_ERROR_NOTIFY_BY_ERR_CODE = ['Internal_Login#4', 'Internal_ResendEmailVerification#4', 'Internal_ResendEmailVerification#1']

export const HTTP_METHODS_ARR = [
    "GET",
    "POST",
    "PUT",
    "PATCH",
    "DELETE",
    "COPY",
    "HEAD",
    "OPTIONS",
    "LINK",
    "UNLINK",
    "PURGE",
    "LOCK",
    "UNLOCK",
    "PROPFIND",
    "VIEW",
];

export const HTTP_METHOD_COLORS = {
    "GET": "#4b7f4d",
    "POST": "#4d7f7a",
    "PUT": "#7f7b4d",
    "PATCH": "#7f5b4d",
    "DELETE": "#7f4d4d",
    "COPY": "#5d4d7f",
    "HEAD": "#7f7f7f",
    "OPTIONS": "#4d7f7f",
    "LINK": "#7f4d7f",
    "UNLINK": "#5d4d7f",
    "PURGE": "#7f7f7f",
    "LOCK": "#7f6f4d",
    "UNLOCK": "#7f6f4d",
    "PROPFIND": "#4d7f7f",
    "VIEW": "#4d7f5d"
}

export const HTTP_HEADERS_ARR = [
    "Accept",
    "Accept-Charset",
    "Accept-Encoding",
    "Accept-Language",
    "Access-Control-Request-Headers",
    "Access-Control-Request-Method",
    "Authorization",
    "Cache-Control",
    "Content-MD5",
    "Content-Length",
    "Content-Transfer-Encoding",
    "Content-Type",
    "Cookie",
    "Cookie 2",
    "Date",
    "Expect",
    "From",
    "Host",
    "If-Match",
    "If-Modified-Since",
    "If-None-Match",
    "If-Range",
    "If-Unmodified-Since",
    "Keep-Alive",
    "Max-Forwards",
    "Origin",
    "Pragma",
    "Proxy-Authorization",
    "Range",
    "Referer",
    "TE",
    "Trailer",
    "Transfer-Encoding",
    "Upgrade",
    "User-Agent",
    "Via",
    "Warning",
    "X-Requested-With",
    "X-Do-Not-Track",
    "DNT",
    "x-api-key",
    "Connection"
]

export const dummyFlowItemData = {
    id: 0,
    data: {
        method: "GET",
        URL: "",
    }
}

export const dummyFlow = [
    {
        "id": 0,
        "data": {
            "method": "POST",
            "URL": "https://api.onradar.io/demo-shop/auth",
            "headers": [
                {
                    "id": 1,
                    "data_key": "",
                    "data_value": ""
                }
            ],
            "params": [
                {
                    "id": 1,
                    "data_key": "",
                    "data_value": ""
                }
            ],
            "body": {
                "dataType": 3,
                "JSON": "{\n    \"email\": \"demo@test.com\",\n    \"password\": \"123\"\n}"
            }
        }
    },
    {
        "id": 1,
        "data": {
            "method": "POST",
            "URL": "https://api.onradar.io/demo-shop/createOrder",
            "headers": [
                {
                    "id": 0,
                    "data_key": "",
                    "data_value": ""
                }
            ],
            "body": {
                "dataType": 3,
                "JSON": "{\n    \"productId\": 742,\n    \"delivery\": \"PICK-UP\",\n    \"color\": \"PURPLE\",\n    \"material\": \"COTTON\",\n    \"count\": 3,\n    \"promo\": \"DISCOUNT001\"\n}"
            }
        }
    }
]


export const contientnsData = [
    // {
    //     id: 0,
    //     name: "Africa",
    // },
    {
        id: 5,
        name: "America",
    },
    {
        id: 4,
        name: "Europe",
    },
    {
        id: 1,
        name: "Asia",
    },
    {
        id: 2,
        name: "Middle East",
    },
    {
        id: 3,
        name: "Australia",
    },
]

export const checkIntervalOptions = [
    {
        value: 10,
        label: '10 seconds',
    },
    {
        value: 20,
        label: '20 seconds',
    },
    {
        value: 30,
        label: '30 seconds',
    },
    {
        value: 60,
        label: '1 minute',
    },
    {
        value: 300,
        label: '5 minutes',
    },
    {
        value: 600,
        label: '10 minutes',
    },
    {
        value: 1800,
        label: '30 minutes',
    },
    {
        value: 3600,
        label: '1 hour',
    },
    {
        value: 43200,
        label: '12 hours',
    },
    {
        value: 86400,
        label: '24 hours',
    },
]

export const timeoutIntervalOptions = [
    {
        value: 5,
        label: '5 seconds',
    },
    {
        value: 10,
        label: '10 seconds',
    },
    {
        value: 20,
        label: '20 seconds',
    },
    {
        value: 30,
        label: '30 seconds',
    },
    {
        value: 40,
        label: '40 seconds',
    },
    {
        value: 50,
        label: '50 seconds',
    },
    {
        value: 60,
        label: '1 minute',
    },
]

export const httpStatusCodeOptions = [
    {
        "value": 0,
        "title": "None indicates each status code will be treated as an success.",
        "label": "None"
    },
    {
        "value": 1,
        "title": "Default indicates If the status code is below 200 or 300 and above, it will be treated as an error.",
        "label": "Default"
    },
    {
        "value": 100,
        "title": "100 indicates that the initial part of a request has been received and has not yet been rejected by the server.",
        "label": "100 - Continue"
    },
    {
        "value": 101,
        "title": "101 indicates that the server understands and is willing to comply with the client's request, via the Upgrade header field, for a change in the application protocol being used on this connection.",
        "label": "101 - Switching Protocols"
    },
    {
        "value": 102,
        "title": "102 is an interim response used to inform the client that the server has accepted the complete request, but has not yet completed it.",
        "label": "102 - Processing"
    },
    {
        "value": 200,
        "title": "200 indicates that the request has succeeded.",
        "label": "200 - OK"
    },
    {
        "value": 201,
        "title": "201 indicates that the request has been fulfilled and has resulted in one or more new resources being created.",
        "label": "201 - Created"
    },
    {
        "value": 202,
        "title": "202 indicates that the request has been accepted for processing, but the processing has not been completed.",
        "label": "202 - Accepted"
    },
    {
        "value": 203,
        "title": "203 indicates that the request was successful but the enclosed payload has been modified from that of the origin server's 200 (OK) response by a transforming proxy.",
        "label": "203 - Non-Authoritative Information"
    },
    {
        "value": 204,
        "title": "204 indicates that the server has successfully fulfilled the request and that there is no additional content to send in the response payload body.",
        "label": "204 - No Content"
    },
    {
        "value": 205,
        "title": "205 indicates that the server has fulfilled the request and desires that the user agent reset the document view, which caused the request to be sent, to its original state as received from the origin server.",
        "label": "205 - Reset Content"
    },
    {
        "value": 206,
        "title": "206 indicates that the server is successfully fulfilling a range request for the target resource by transferring one or more parts of the selected representation that correspond to the satisfiable ranges found in the requests's Range header field.",
        "label": "206 - Partial Content"
    },
    {
        "value": 207,
        "title": "207 provides status for multiple independent operations.",
        "label": "207 - Multi-Status"
    },
    {
        "value": 226,
        "title": "226 indicates that the server has fulfilled a GET request for the resource, and the response is a representation of the result of one or more instance-manipulations applied to the current instance.",
        "label": "226 - IM Used"
    },
    {
        "value": 300,
        "title": "300 indicates that the target resource has more than one representation, each with its own more specific identifier, and information about the alternatives is being provided so that the user (or user agent) can select a preferred representation by redirecting its request to one or more of those identifiers.",
        "label": "300 - Multiple Choices"
    },
    {
        "value": 301,
        "title": "301 indicates that the target resource has been assigned a new permanent URI and any future references to this resource ought to use one of the enclosed URIs.",
        "label": "301 - Moved Permanently"
    },
    {
        "value": 302,
        "title": "302 indicates that the target resource resides temporarily under a different URI.",
        "label": "302 - Found"
    },
    {
        "value": 303,
        "title": "303 indicates that the server is redirecting the user agent to a different resource, as indicated by a URI in the Location header field, that is intended to provide an indirect response to the original request.",
        "label": "303 - See Other"
    },
    {
        "value": 304,
        "title": "304 indicates that a conditional GET request has been received and would have resulted in a 200 (OK) response if it were not for the fact that the condition has evaluated to false.",
        "label": "304 - Not Modified"
    },
    {
        "value": 305,
        "title": "305 *deprecated*",
        "label": "305 - Use Proxy"
    },
    {
        "value": 307,
        "title": "307 indicates that the target resource resides temporarily under a different URI and the user agent MUST NOT change the request method if it performs an automatic redirection to that URI.",
        "label": "307 - Temporary Redirect"
    },
    {
        "value": 308,
        "title": "308 indicates that target resource has been assigned a new permanent URI and any future references to this resource outght to use one of the enclosed URIs. [...] This status code is similar to 301 Moved Permanently (Section 7.3.2 of rfc7231), except that it does not allow rewriting the request method from POST to GET.",
        "label": "308 - Permanent Redirect"
    },
    {
        "value": 400,
        "title": "400 indicates that the server cannot or will not process the request because the received syntax is invalid, nonsensical, or exceeds some limitation on what the server is willing to process.",
        "label": "400 - Bad Request"
    },
    {
        "value": 401,
        "title": "401 indicates that the request has not been applied because it lacks valid authentication credentials for the target resource.",
        "label": "401 - Unauthorized"
    },
    {
        "value": 402,
        "title": "402 *reserved*",
        "label": "402 - Payment Required"
    },
    {
        "value": 403,
        "title": "403 indicates that the server understood the request but refuses to authorize it.",
        "label": "403 - Forbidden"
    },
    {
        "value": 404,
        "title": "404 indicates that the origin server did not find a current representation for the target resource or is not willing to disclose that one exists.",
        "label": "404 - Not Found"
    },
    {
        "value": 405,
        "title": "405 indicates that the method specified in the request-line is known by the origin server but not supported by the target resource.",
        "label": "405 - Method Not Allowed"
    },
    {
        "value": 406,
        "title": "406 indicates that the target resource does not have a current representation that would be acceptable to the user agent, according to the proactive negotiation header fields received in the request, and the server is unwilling to supply a default representation.",
        "label": "406 - Not Acceptable"
    },
    {
        "value": 407,
        "title": "407 is similar to 401 (Unauthorized), but indicates that the client needs to authenticate itself in order to use a proxy.",
        "label": "407 - Proxy Authentication Required"
    },
    {
        "value": 408,
        "title": "408 indicates that the server did not receive a complete request message within the time that it was prepared to wait.",
        "label": "408 - Request Timeout"
    },
    {
        "value": 409,
        "title": "409 indicates that the request could not be completed due to a conflict with the current state of the resource.",
        "label": "409 - Conflict"
    },
    {
        "value": 410,
        "title": "410 indicates that access to the target resource is no longer available at the origin server and that this condition is likely to be permanent.",
        "label": "410 - Gone"
    },
    {
        "value": 411,
        "title": "411 indicates that the server refuses to accept the request without a defined Content-Length.",
        "label": "411 - Length Required"
    },
    {
        "value": 412,
        "title": "412 indicates that one or more preconditions given in the request header fields evaluated to false when tested on the server.",
        "label": "412 - Precondition Failed"
    },
    {
        "value": 413,
        "title": "413 indicates that the server is refusing to process a request because the request payload is larger than the server is willing or able to process.",
        "label": "413 - Payload Too Large"
    },
    {
        "value": 414,
        "title": "414 indicates that the server is refusing to service the request because the request-target is longer than the server is willing to interpret.",
        "label": "414 - URI Too Long"
    },
    {
        "value": 415,
        "title": "415 indicates that the origin server is refusing to service the request because the payload is in a format not supported by the target resource for this method.",
        "label": "415 - Unsupported Media Type"
    },
    {
        "value": 416,
        "title": "416 indicates that none of the ranges in the request's Range header field overlap the current extent of the selected resource or that the set of ranges requested has been rejected due to invalid ranges or an excessive request of small or overlapping ranges.",
        "label": "416 - Range Not Satisfiable"
    },
    {
        "value": 417,
        "title": "417 indicates that the expectation given in the request's Expect header field could not be met by at least one of the inbound servers.",
        "label": "417 - Expectation Failed"
    },
    {
        "value": 418,
        "title": "418 attempt to brew coffee with a teapot should result in the error code 418 I'm a teapot.",
        "label": "418 - I'm a teapot"
    },
    {
        "value": 422,
        "title": "422 means the server understands the content type of the request entity (hence a 415(Unsupported Media Type) status code is inappropriate), and the syntax of the request entity is correct (thus a 400 (Bad Request) status code is inappropriate) but was unable to process the contained instructions.",
        "label": "422 - Unprocessable Entity"
    },
    {
        "value": 423,
        "title": "423 means the source or destination resource of a method is locked.",
        "label": "423 - Locked"
    },
    {
        "value": 424,
        "title": "424 means that the method could not be performed on the resource because the requested action depended on another action and that action failed.",
        "label": "424 - Failed Dependency"
    },
    {
        "value": 426,
        "title": "426 indicates that the server refuses to perform the request using the current protocol but might be willing to do so after the client upgrades to a different protocol.",
        "label": "426 - Upgrade Required"
    },
    {
        "value": 428,
        "title": "428 indicates that the origin server requires the request to be conditional.",
        "label": "428 - Precondition Required"
    },
    {
        "value": 429,
        "title": "429 indicates that the user has sent too many requests in a given amount of time (rate limiting).",
        "label": "429 - Too Many Requests"
    },
    {
        "value": 431,
        "title": "431 indicates that the server is unwilling to process the request because its header fields are too large.",
        "label": "431 - Request Header Fields Too Large"
    },
    {
        "value": 451,
        "title": "451 indicates that the server is denying access to the resource in response to a legal demand.",
        "label": "451 - Unavailable For Legal Reasons"
    },
    {
        "value": 500,
        "title": "500 indicates that the server encountered an unexpected condition that prevented it from fulfilling the request.",
        "label": "500 - Internal Server Error"
    },
    {
        "value": 501,
        "title": "501 indicates that the server does not support the functionality required to fulfill the request.",
        "label": "501 - Not Implemented"
    },
    {
        "value": 502,
        "title": "502 indicates that the server, while acting as a gateway or proxy, received an invalid response from an inbound server it accessed while attempting to fulfill the request.",
        "label": "502 - Bad Gateway"
    },
    {
        "value": 503,
        "title": "503 indicates that the server is currently unable to handle the request due to a temporary overload or scheduled maintenance, which will likely be alleviated after some delay.",
        "label": "503 - Service Unavailable"
    },
    {
        "value": 504,
        "title": "504 indicates that the server, while acting as a gateway or proxy, did not receive a timely response from an upstream server it needed to access in order to complete the request.",
        "label": "504 - Gateway Time-out"
    },
    {
        "value": 505,
        "title": "505 indicates that the server does not support, or refuses to support, the protocol version that was used in the request message.",
        "label": "505 - HTTP Version Not Supported"
    },
    {
        "value": 506,
        "title": "506 indicates that the server has an internal configuration error: the chosen variant resource is configured to engage in transparent content negotiation itself, and is therefore not a proper end point in the negotiation process.",
        "label": "506 - Variant Also Negotiates"
    },
    {
        "value": 507,
        "title": "507 means the method could not be performed on the resource because the server is unable to store the representation needed to successfully complete the request.",
        "label": "507 - Insufficient Storage"
    },
    {
        "value": 511,
        "title": "511 indicates that the client needs to authenticate to gain network access.",
        "label": "511 - Network Authentication Required"
    }
]

export const supportedCountriesList: any = {
    "Aland Islands": "AX",
    "Albania": "AL",
    "Algeria": "DZ",
    "American Samoa": "AS",
    "Andorra": "AD",
    "Angola": "AO",
    "Anguilla": "AI",
    "Antigua and Barbuda": "AG",
    "Argentina": "AR",
    "Armenia": "AM",
    "Aruba": "AW",
    "Australia": "AU",
    "Austria": "AT",
    "Azerbaijan": "AZ",
    "Bahamas": "BS",
    "Bahrain": "BH",
    "Bangladesh": "BD",
    "Barbados": "BB",
    "Belgium": "BE",
    "Belize": "BZ",
    "Benin": "BJ",
    "Bermuda": "BM",
    "Bhutan": "BT",
    "Bolivia": "BO",
    "Bonaire, Sint Eustatius and Saba": "BQ",
    "Bosnia and Herzegovina": "BA",
    "Botswana": "BW",
    "Bouvet Island": "BV",
    "Brazil": "BR",
    "Brit. Indian Ocean": "IO",
    "British Virgin Islands": "VG",
    "Brunei": "BN",
    "Bulgaria": "BG",
    "Burkina Faso": "BF",
    "Burundi": "BI",
    "Cambodia": "KH",
    "Cameroon": "CM",
    "Canada": "CA",
    "Cape Verde": "CV",
    "Cayman Islands": "KY",
    "Chad": "TD",
    "Chile": "CL",
    "China": "CN",
    "Christmas Island": "CX",
    "Cocos Islands": "CC",
    "Colombia": "CO",
    "Comoros": "KM",
    "Cook Islands": "CK",
    "Costa Rica": "CR",
    "Cote D’Ivoire": "CI",
    "Croatia": "HR",
    "Curaçao": "CW",
    "Cyprus": "CY",
    "Czech Republic": "CZ",
    "Denmark": "DK",
    "Djibouti": "DJ",
    "Dominica": "DM",
    "Dominican Republic": "DO",
    "Ecuador": "EC",
    "Egypt": "EG",
    "El Salvador": "SV",
    "Equatorial Guinea": "GQ",
    "Eritrea": "ER",
    "Estonia": "EE",
    "Ethiopia": "ET",
    "Falkland Islands": "FK",
    "Faroe Islands": "FO",
    "Fiji": "FJ",
    "Finland": "FI",
    "France": "FR",
    "French Guiana": "GF",
    "French Polynesia": "PF",
    "French Southern Terr.": "TF",
    "Gabon": "GA",
    "Gambia": "GM",
    "Georgia": "GE",
    "Germany": "DE",
    "Ghana": "GH",
    "Gibraltar": "GI",
    "Greece": "GR",
    "Greenland": "GL",
    "Grenada": "GD",
    "Guadeloupe": "GP",
    "Guam": "GU",
    "Guatemala": "GT",
    "Guernsey": "GG",
    "Guinea": "GN",
    "Guinea-Bissau": "GW",
    "Guyana": "GY",
    "Heard/ Mcdonald Islands": "HM",
    "Holy See/ Vatican City": "VA",
    "Honduras": "HN",
    "Hong Kong": "HK",
    "Hungary": "HU",
    "Iceland": "IS",
    "India": "IN",
    "Indonesia": "ID",
    "Iraq": "IQ",
    "Ireland": "IE",
    "Isle of Man": "IM",
    "Israel": "IL",
    "Italy": "IT",
    "Jamaica": "JM",
    "Japan": "JP",
    "Jersey": "JE",
    "Jordan": "JO",
    "Kazakhstan": "KZ",
    "Kenya": "KE",
    "Kiribati": "KI",
    "Kosovo": "XK",
    "Kuwait": "KW",
    "Kyrgyzstan": "KG",
    "Lao People’s DR": "LA",
    "Latvia": "LV",
    "Lebanon": "LB",
    "Lesotho": "LS",
    "Liberia": "LR",
    "Liechtenstein": "LI",
    "Lithuania": "LT",
    "Luxembourg": "LU",
    "Macao": "MO",
    "Macedonia": "MK",
    "Madagascar": "MG",
    "Malawi": "MW",
    "Malaysia": "MY",
    "Maldives": "MV",
    "Malta": "MT",
    "Marshall Islands": "MH",
    "Martinique": "MQ",
    "Mauritania": "MR",
    "Mauritius": "MU",
    "Mayotte": "YT",
    "Mexico": "MX",
    "Micronesia": "FM",
    "Moldova": "MD",
    "Monaco": "MC",
    "Mongolia": "MN",
    "Montenegro": "ME",
    "Montserrat": "MS",
    "Morocco": "MA",
    "Mozambique": "MZ",
    "Namibia": "NA",
    "Nauru": "NR",
    "Nepal": "NP",
    "Netherlands": "NL",
    "Netherlands Antilles": "AN",
    "New Caledonia": "NC",
    "New Zealand": "NZ",
    "Niger": "NE",
    "Nigeria": "NG",
    "Niue": "NU",
    "Norfolk Island": "NF",
    "Northern Mariana Islands": "MP",
    "Norway": "NO",
    "Oman": "OM",
    "Pakistan": "PK",
    "Palau": "PW",
    "Palestinian Territory": "PS",
    "Panama": "PA",
    "Papua New Guinea": "PG",
    "Paraguay": "PY",
    "Peru": "PE",
    "Philippines": "PH",
    "Pitcairn": "PN",
    "Poland": "PL",
    "Portugal": "PT",
    "Puerto Rico": "PR",
    "Qatar": "QA",
    "Republic of Congo": "CG",
    "Republic of Serbia": "RS",
    "Reunion": "RE",
    "Romania": "RO",
    "Rwanda": "RW",
    "S. Georgia/ Sandwich Islands": "GS",
    "Saint Helena": "SH",
    "Saint Kitts and Nevis": "KN",
    "Saint Lucia": "LC",
    "Saint Martin": "MF",
    "Saint Pierre and Miquelon": "PM",
    "Saint Vincent/ Grenadines": "VC",
    "Samoa": "WS",
    "San Marino": "SM",
    "Sao Tome and Principe": "ST",
    "Saudi Arabia": "SA",
    "Senegal": "SN",
    "Seychelles": "SC",
    "Sierra Leone": "SL",
    "Singapore": "SG",
    "Slovakia": "SK",
    "Slovenia": "SI",
    "Solomon Islands": "SB",
    "South Africa": "ZA",
    "South Korea": "KR",
    "Spain": "ES",
    "Sri Lanka": "LK",
    "Sudan": "SD",
    "Suriname": "SR",
    "Svalbard and Jan Mayen": "SJ",
    "Swaziland": "SZ",
    "Sweden": "SE",
    "Switzerland": "CH",
    "Taiwan": "TW",
    "Tajikistan": "TJ",
    "Tanzania": "TZ",
    "Thailand": "TH",
    "Timor-Leste": "TL",
    "Togo": "TG",
    "Tokelau": "TK",
    "Tonga": "TO",
    "Trinidad and Tobago": "TT",
    "Tunisia": "TN",
    "Turkey": "TR",
    "Turkmenistan": "TM",
    "Turks and Caicos Islands": "TC",
    "Tuvalu": "TV",
    "U.S. Virgin Islands": "VI",
    "Uganda": "UG",
    "Ukraine": "UA",
    "United Arab Emirates": "AE",
    "United Kingdom": "GB",
    "United States": "US",
    "United States (M.O.I.)": "UM",
    "Uruguay": "UY",
    "Uzbekistan": "UZ",
    "Vanuatu": "VU",
    "Vietnam": "VN",
    "Wallis and Futuna": "WF",
    "Western Sahara": "EH",
    "Zambia": "ZM"
}

export const addNewFlowNodeData = {
    name: "User auth",
    httpReq: {
        itemData: {
            headers: [
                {
                    "id": 0,
                    "data_key": "",
                    "data_value": ""
                }
            ],
            method: "POST",
            URL: "https://acme.com/api/auth",
            body: {
                "dataType": 1
            },
            variables: [
                {
                    "id": 0,
                    "data_key": "",
                    "data_value": "",
                    "nodeId": ""
                }
            ],
        }
    },
    status: 0,
}

export const initialFlowNodeData = {
    name: "Request",
    httpReq: {
        itemData: {
            headers: [
                {
                    "id": 0,
                    "data_key": "",
                    "data_value": ""
                }
            ],
            method: "GET",
            URL: "https://acme.com/api",
            body: {
                "dataType": 1
            },
            variables: [
                {
                    "id": 0,
                    "data_key": "",
                    "data_value": "",
                    "nodeId": ""
                }
            ],
        }
    },
    status: 0,
}

export const colorPalette = ['#6d67e4', '#b71375', '#fc4f00', '#f79540', '#e8c07d', '#5ecb96', '#ff4d4f'];