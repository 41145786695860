import { createSlice } from '@reduxjs/toolkit';

export interface State {
    flowLogsSidebar: boolean,
    sidebarExpanded: boolean,
    disableAutoSidebarExpand: boolean,
}

const initialState: State = {
    flowLogsSidebar: false,
    sidebarExpanded: false,
    disableAutoSidebarExpand: false,
};

export const slice = createSlice({
    name: 'uiconfig',
    initialState,
    reducers: {
        toggleLogsSidebar: (state: any) => {
            state.flowLogsSidebar = !state.flowLogsSidebar;
        },
        setShowLogsSidebar: (state: any, action: any) => {
            state.flowLogsSidebar = action.payload
        },
        setSidebarExpand: (state: any, action: any) => {
            state.sidebarExpanded = action.payload;
        },
        setDisableAutoSidebarExpand: (state: any, action: any) => {
            state.disableAutoSidebarExpand = action.payload;
        },
        resetConfig: () => {
            return initialState;
        },
    }
});

export const { toggleLogsSidebar, setShowLogsSidebar, setSidebarExpand, setDisableAutoSidebarExpand, resetConfig } = slice.actions;
export default slice.reducer;