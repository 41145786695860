import CompanyLogoIcon from "./CompanyLogoIcon";

const AuthSideComponent = () => {
    return <div className="side-container">
        <CompanyLogoIcon
            height='30'
            width='30'
            color='white'
            style={{ margin: "10px 0 20px 0" }}
        />
        {/* <div className="side-content">
            <div className="welcome-container">
                <div className="blur-container" />
                <div className="text-container">
                    <Typography.Title level={3}>Welcome</Typography.Title>
                    <Typography.Text>----</Typography.Text>
                </div>
            </div>
        </div> */}
    </div>;
}

export default AuthSideComponent;