import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Divider, Form, Input, Typography } from "antd";
import Request from "../../utils/request";
import endpoints from "../../utils/endpoints";
import _ from "lodash";
import { domainValidation, emailValidation } from "../../utils/utils";
import FormItemTitle from "../../components/FormItemTitle";
import AuthSideComponent from "../../components/AuthSideComponent";
// import { supportedCountriesList } from "../../utils/constants";

// const { Option } = Select;

type IForm = { email: string, password: string, country: string, repassword: string, website?: string };

function Register() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | string | null>(null);

  const actionRegister = async ({ email, password, repassword, website }: IForm) => {
    // if (_.isNil(country)) return setError("Country is not selected.");
    if (password !== repassword) return setError("Password is not same.");
    if (emailValidation(email) === false) return setError("E-mail address is incorrect.");
    const hasWebsiteEntered = website && website?.length > 0;
    if (hasWebsiteEntered && domainValidation(website ?? "") === false) return setError("Website address is incorrect.");
    if (hasWebsiteEntered && !website?.startsWith("http://") && !website?.startsWith("https://")) {
      website = "https://" + website;
    }
    setLoading(true);
    await Request.post(endpoints.register, {
      email,
      password,
      website: hasWebsiteEntered ? website : null,
    })
      .then(() => {
        navigate("/login", {
          state: { email }
        })
        setLoading(false);
      })
      .catch(err => {
        setError(err?.response?.data?.message || true);
        setLoading(false);
      });
  }

  // const onCountryChange = (country: string) => {
  //   form.setFieldsValue({ country });
  // };

  return (
    <div className="auth-container">
      <AuthSideComponent />
      <div className="login-container">
        <div className="login-wrapper">
          <Typography.Title level={3} style={{ marginBottom: 20 }}>Sign up</Typography.Title>
          <Form
            name="normal_login"
            className="sign-up-form"
            onFinish={actionRegister}
            layout="vertical"
            form={form}
            requiredMark={false}
          >
            <Form.Item
              name="email"
              label="E-mail:"
              help={""}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please enter your e-mail.',
                },
                () => ({
                  validator(_, value) {
                    if (!value || emailValidation(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The e-mail address is incorrect.'));
                  },
                }),
              ]}
            >
              <Input placeholder="E-mail" type="email" className={"email-input"} />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password:"
              help={""}
              rules={[{ required: true, message: 'Please enter your password.' }]}
            >
              <Input.Password
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              name="repassword"
              label="Confirm Password:"
              help={""}
              rules={[
                { required: true, message: 'Please enter your same password.' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm password"
              />
            </Form.Item>
            {/* <Form.Item
            name="country"
            label="Country:"
            help={""}
            rules={[
              { required: true, message: 'Please enter your country.' }
            ]}
          >
            <Select onChange={onCountryChange} showSearch notFoundContent={
              <div style={{ textAlign: "center" }}>
                <span style={{ color: "#ccc" }}>No country found</span>
              </div>
            }
              filterOption={(input, option) => option?.key?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              autoClearSearchValue
            >
              {Object.keys(supportedCountriesList).map((m: any) => <Option key={m} value={supportedCountriesList[m]}>{m}</Option>)}
            </Select>
          </Form.Item> */}
            <Form.Item
              name="website"
              // label={<FormItemTitle title="Website (optional):" tooltip="Enter your company's website, and we'll start monitoring right away. This is optional. You can create a monitor later." />}
              label="Company website:"
              help={""}
              hasFeedback
              rules={[
                {
                  required: false,
                  // message: "Please enter your company's website.",
                },
                () => ({
                  validator(_, value) {
                    if (value?.length > 0 && !domainValidation(value)) {
                      return Promise.reject(new Error('The company website address is incorrect.'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input placeholder="https://acme.com" type="text" className={"email-input"} />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Register
              </Button>
            </Form.Item>
          </Form>
          {error && <Alert
            description={_.isString(error) ? error : "Whoops, make sure you've filled correctly!"}
            type="error"
            style={{ marginBottom: 20 }}
          />}
          <Divider />
          <div className="tooltip-wrapper">
            <Link className="auth-navigate-link" to={"/login"}>Already have an account? Sign In</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register;
