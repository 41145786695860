import { ArrowLeftOutlined } from "@ant-design/icons";
import { CSSProperties, ReactNode } from "react";
import PageTitle from "./PageTitle";
import { Button } from "antd";
import classNames from "classnames";
import PageHeaderRightSection from "./PageHeaderRightSection";

type IPageContent = {
    children: ReactNode,
    footer?: ReactNode,
    title?: string,
    containerStyle?: CSSProperties,
    contentStyle?: CSSProperties,
    backButton?: boolean
    className?: string
    contentClassName?: string
}

const PageContent = (props: IPageContent) => {
    const goBack = () => {
        window.history.back();
    }

    return (<div className={classNames("page-content", props?.className)} style={props.containerStyle}>
        <div className="page-content-wrapper">
            <div className="page-content-header">
                <div className="page-content-header-left">
                    {props?.backButton && (
                        <Button className="page-content-back-button" type="primary" onClick={goBack}>
                            <ArrowLeftOutlined />
                        </Button>
                    )}
                    {props.title && <PageTitle title={props.title} />}
                </div>
                <PageHeaderRightSection />
            </div>
            <div style={props.contentStyle} className={props?.contentClassName}>{props.children}</div>
        </div>
        {props.footer && <div className="sticky-footer">{props.footer}</div>}
    </div>)
}

export default PageContent;