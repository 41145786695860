import 'reactflow/dist/style.css';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Popconfirm, Tag, notification } from "antd";
import AutoScroll from '@brianmcallister/react-auto-scroll';
import { SaveFilled, FileAddOutlined, DeleteOutlined, UnlockFilled, LockFilled, ClearOutlined, BellFilled, BellOutlined } from "@ant-design/icons";
import PageContent from "../../../components/PageContent";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
  Connection,
  Edge,
} from 'reactflow';
import { nodes as initialNodes, edges as initialEdges } from '../../../components/Flow/initial-elements';
import { useDispatch } from "react-redux";
import { setContentLoading, setFlowLocked, setFlowNodeDrawer } from "../../../utils/redux/features/cache";
import useIsMounted from "../../../utils/hooks/useIsMounted";
import { useAppSelector } from "../../../utils/hooks/useRedux";
import RequestNode from "../../../components/Flow/Nodes/RequestNode";
import FlowNodeDrawer from '../../../components/Flow/FlowNodeDrawer';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { setSidebarExpand, setShowLogsSidebar } from '../../../utils/redux/features/ui-config';
import { initialFlowNodeData } from '../../../utils/constants';
import { getShortId, sortEdges } from '../../../utils/utils';
import Request from '../../../utils/request';
import endpoints from '../../../utils/endpoints';
import { useNavigate, useParams } from 'react-router-dom';
import isString from 'lodash/isString';
import isNaN from 'lodash/isNaN';
import isNull from 'lodash/isNull';
import CreateFlowModal from '../../../components/Flow/CreateFlowModal';
import NotificationSettingsDrawer from '../../../components/NotificationSettingsDrawer';
import { AlertChannels, ServiceTypes } from '../../../utils/types';

const nodeTypes = {
  request: RequestNode,
};

const minimapStyle = {
  height: 100,
  width: 100
};

// const onInit = (instance: ReactFlowInstance) => {
//   setTimeout(() => {
//     instance.fitView({
//       includeHiddenNodes: true
//     });
//   }, 500)
// };

type FooterContentType = {
  isEdit?: boolean,
  isRunning?: boolean,
  isGraphLocked: boolean,
  isDeleteLoading: boolean,
  isCreatingLoading?: boolean,
  isTestDisabled?: boolean,
  isNotifySettingsDrawerOpen: boolean,
  onNotificationSettings: () => void,
  onCreate: () => void,
  onEdit: () => void,
  onDelete: () => void,
  onRun?: () => void,
  toggleGraphLock: () => void,
}

const FooterContent = ({ isEdit, onCreate, onEdit, onDelete, toggleGraphLock, onNotificationSettings, isNotifySettingsDrawerOpen, isGraphLocked, isRunning, isDeleteLoading, isCreatingLoading, isTestDisabled }: FooterContentType) => {
  return (
    <div className="sticky-footer-content">
      {isEdit && (
        <Popconfirm
          title="Are you sure to delete this flow?"
          onConfirm={onDelete}
          okText="Delete"
          cancelText="No"
        >
          <Button type="primary" danger loading={isDeleteLoading} disabled={isRunning || isDeleteLoading || isCreatingLoading}><DeleteOutlined /></Button>
        </Popconfirm>
      )}
      {isEdit && <Button type="primary" disabled={isRunning || isCreatingLoading || isDeleteLoading} loading={isCreatingLoading} onClick={toggleGraphLock}>
        {isGraphLocked ? (<>
          Unlock <UnlockFilled />
        </>) : (<>
          Lock <LockFilled />
        </>)}
      </Button>}
      <Button type="primary" disabled={!isEdit} onClick={onNotificationSettings}>Notifications {isNotifySettingsDrawerOpen ? <BellFilled /> : <BellOutlined />}</Button>
      {/* {!isGraphLocked && <Button type="primary" disabled={isRunning || isTestDisabled || isCreatingLoading || isDeleteLoading} loading={isRunning} onClick={onRun}>Test <ThunderboltOutlined /></Button>} */}
      {(isEdit && !isGraphLocked) && <Button className="run-btn" type="primary" disabled={isRunning || isDeleteLoading} loading={isCreatingLoading} onClick={onEdit}>Save changes <SaveFilled /></Button>}
      {!isEdit && <Button type="primary" className="run-btn" disabled={isRunning || isCreatingLoading || isDeleteLoading || isTestDisabled} loading={isCreatingLoading} onClick={onCreate}>Create <SaveFilled /></Button>}
    </div>
  )
}

const FlowNodeEditor = () => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { screenToFlowPosition, getNodes, getEdges } = useReactFlow();
  const { id } = useParams();
  const hasId = useMemo(() => isString(id), [id]);
  const dataFetchedRef = useRef(false);
  const connectingNodeId = useRef(null);
  const logsRef = useRef(null);
  const uiconfigState = useAppSelector(state => state.uiconfig);
  const cacheState = useAppSelector(state => state.cache);
  const disableAutoSidebarExpand = uiconfigState.disableAutoSidebarExpand;
  const showLogs = false;
  // const showLogs = uiconfigState.flowLogsSidebar;
  const flowNodeDrawerId = cacheState.flowNodeDrawerId;
  const isFlowLocked = cacheState.isFlowLocked;
  const [isCreatingLoading, setCreatingLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isRunning, setRunning] = useState<boolean>(false);
  const [flowName, setFlowName] = useState<string>('');
  const [nodes, setNodes, onNodesChange] = useNodesState(!hasId ? initialNodes as any : []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(!hasId ? initialEdges : []);
  const [logs, setLogs] = useState<string[]>(["Logs will appear here..."]);
  const [nameModalVisible, setNameModal] = useState<boolean>(false);
  const [isNotifySettingsDrawerOpen, setNotifySettingsDrawerOpen] = useState(false);

  const clearFlowOnUnmount = useCallback(() => {
    setFlowName('');
    setNodes([]);
    setEdges([]);
    setLogs([]);
    dispatch(setFlowNodeDrawer({ status: false, id: null } as never));
    dispatch(setFlowLocked(false as never));
  }, [dispatch, setNodes, setEdges])

  useEffect(() => {
    const fetchMonitorData = async (id: string) => {
      dispatch(setContentLoading(true));
      Request.get(`${endpoints.flow}/${id}`)
        .then(m_Data => {
          dispatch(setContentLoading(false));
          const mData = m_Data?.data?.payload;

          if (m_Data.data.success === false || isNaN(mData) || isNull(mData)) {
            notification.error({ message: "Flow not found" });
            navigate("/flows");
            return;
          }

          if (isString(mData?.flowId)) {
            dispatch(setFlowLocked(true as never));
            setFlowName(mData?.name);
            setNodes(mData?.nodes);
            setEdges(mData?.edges);
          }
        })
        .catch(_err => {
          console.log(_err, 'er');
          dispatch(setContentLoading(false));
        });
    }

    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (id) {
      fetchMonitorData(id);
    }

    return () => {
      if (hasId) {
        clearFlowOnUnmount();
      }
    }
  }, [id, hasId, dispatch, navigate, setNodes, setEdges, clearFlowOnUnmount]);

  useEffect(() => {
    return () => {
      if (!hasId) {
        if (isFlowLocked) {
          dispatch(setFlowLocked(false as never))
        }
      }
    }
  }, [dispatch, hasId, isFlowLocked])

  const postLogs = (log: string) => {
    const dateHour = new Date().toLocaleTimeString();
    setLogs(logs => [...logs, `[${dateHour}]: ${log}`]);
  }

  const onConnect = useCallback((params: Edge | Connection) => {
    if (params.source === params.target) return;

    // if (params.source && params.target) {
    //   const sourceNode = nodes.find((node) => node.id === params.source);
    //   const targetNode = nodes.find((node) => node.id === params.target);
    //   if (!sourceNode || !targetNode) return;
    //   const connectedEdges = getConnectedEdges([sourceNode, targetNode], edges);
    //   if (connectedEdges.length > 0) return;
    // }

    setEdges((eds) => {
      connectingNodeId.current = null;
      const newEdge = { ...params, animated: true };
      return addEdge(newEdge, eds);
    });
  }, [setEdges]);

  const onConnectStart = useCallback((_: any, { nodeId }: any) => {
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event: any) => {
      if (!connectingNodeId.current || isFlowLocked) return;

      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        const id = getShortId(nodes);
        const newNode = {
          id,
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          type: 'request',
          data: initialFlowNodeData,
          origin: [0.5, 0.0],
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) => {
          return eds.concat({ id, source: connectingNodeId.current, target: id, animated: true } as never);
        });
      }
    },
    [screenToFlowPosition, setEdges, setNodes, nodes, isFlowLocked],
  );

  const onNodesDelete = useCallback(
    (deleted: any) => {
      if (deleted?.[0]?.id === flowNodeDrawerId) {
        dispatch(setFlowNodeDrawer({ status: false, id: null } as never));
      }
      // setEdges(
      //   deleted.reduce((acc: any, node: any) => {
      //     const incomers = getIncomers(node, nodes, edges);
      //     const outgoers = getOutgoers(node, nodes, edges);
      //     const connectedEdges = getConnectedEdges([node], edges);

      //     const remainingEdges = acc.filter((edge: any) => !connectedEdges.includes(edge));

      //     const createdEdges = incomers.flatMap(({ id: source }) =>
      //       outgoers.map(({ id: target }) => ({ id: `${source}->${target}`, source, target, animated: false }))
      //     );

      //     return [...remainingEdges, ...createdEdges];
      //   }, edges)
      // );
    },
    [flowNodeDrawerId, dispatch]
  );

  useEffect(() => {
    if (isMounted()) {
      if (!disableAutoSidebarExpand) dispatch(setSidebarExpand(true as never));
    }

    return () => {
      if (!disableAutoSidebarExpand) dispatch(setSidebarExpand(false as never));
    }
  }, [isMounted, dispatch, disableAutoSidebarExpand]);

  const edgesWithUpdatedTypes = edges.map((edge) => {
    if (edge.sourceHandle) {
      const customNode = nodes.find((node) => node.type === 'custom');
      const edgeType = customNode?.data?.selects?.[edge.sourceHandle];
      edge.type = edgeType;
    }

    return edge;
  });

  const onRunAction = useCallback(async (previewMode?: boolean) => {
    if (isRunning) return;
    if (edges.length === 0) return;
    if (!showLogs) dispatch(setShowLogsSidebar(true as never));
    if (previewMode !== true) setRunning(true);
    setEdges((edges) => edges.map((edge) => ({ ...edge, animated: true })));
    postLogs("Running flow.")

    async function handleNode(node: any) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const nodeData = node.data;
          const nodeReqData = nodeData.httpReq.itemData;
          postLogs(`[${nodeReqData.method}] "${nodeData.name}" requested...`)

          setNodes((nodes) => nodes.map((n) => {
            if (n.id === node.id) {
              n.data = {
                ...n.data,
                isInProgress: 1,
              };
              return n;
            } else {
              return n;
            }
          }));
        }, 1000)

        // Stop progress
        setTimeout(() => {
          const nodeData = node.data;
          const nodeReqData = nodeData.httpReq.itemData;
          postLogs(`[${nodeReqData.method}] "${nodeData.name}" passed. ✅`)

          setNodes((nodes) => nodes.map((n) => {
            if (n.id === node.id) {

              n.data = {
                ...n.data,
                isInProgress: 2,
              };
              return n;
            } else {
              return n;
            }
          }));

          resolve(true);
        }, 2500);
      });
    }

    setNodes((nodes) => nodes.map((n) => {
      n.data = {
        ...n.data,
        isInProgress: 0,
      };
      return n;
    }));

    const allNodes = getNodes();
    const allEdges = getEdges();

    const sortedEdges = sortEdges(allNodes, allEdges);

    const startNode = allNodes.find((n: any) => n.id === edges[0].source);
    if (startNode) {
      await handleNode(startNode);
    }

    for (const x in sortedEdges) {
      const edge = sortedEdges[x];
      const targetNode = allNodes.find((n: any) => n.id === edge.target);
      if (targetNode) {
        await handleNode(targetNode);
      }
    }

    if (previewMode !== true) setRunning(false);
    setEdges((edges) => edges.map((edge) => ({ ...edge, animated: false })));
    postLogs("Running finished.");
  }, [edges, getEdges, getNodes, dispatch, showLogs, setNodes, setEdges, isRunning]);

  const addNode = () => {
    if (isFlowLocked) return;
    const id = getShortId(nodes);
    const lastNode = nodes[nodes.length - 1] || { position: { x: 0, y: 0 } };
    setNodes((nodes: any) => [
      ...nodes,
      {
        id,
        type: 'request',
        data: initialFlowNodeData,
        position: { x: lastNode.position.x + 600, y: lastNode.position.y + 100 },
      },
    ]);
  }

  const closeNameModal = () => {
    setNameModal(false);
  }

  const openNameModal = () => {
    setNameModal(true);
  }

  const onCreateAction = (flowName: string) => {
    if (isRunning || isCreatingLoading) return false;
    const allNodes = getNodes();
    const allEdges = getEdges();
    const sortedEdges = sortEdges(allNodes, allEdges);

    // for (const node of allNodes) {
    //   const itemData = node?.data?.httpReq?.itemData;
    //   node.data.httpReq.itemData = {
    //     ...itemData,
    //     headers: itemData?.headers?.filter((d: any) => d.data_key !== '' && d.data_value !== '') || [],
    //   }
    //   if (itemData?.params?.length) {
    //     node.data.params = itemData?.params?.filter((d: any) => d.data_key !== '' && d.data_value !== '');
    //   }
    //   if (itemData?.body?.dataType === IHTTPRequestDataTypes.FORM && itemData?.body?.form?.length) {
    //     node.data.body.form = itemData?.body?.form?.filter((d: any) => d.data_key !== '' && d.data_value !== '');
    //   }
    //   if (itemData?.variables?.length) {
    //     node.data.variables = itemData?.variables?.filter((d: any) => d.data_key !== '' && d.data_value !== '');
    //   }
    // }

    const formattedData = {
      name: flowName,
      nodes: allNodes,
      edges: sortedEdges,
    };

    setCreatingLoading(true);
    Request({
      url: hasId ? `${endpoints.flow}/${id}` : endpoints.flow,
      method: hasId ? "PUT" : "POST",
      data: formattedData
    })
      .then((mData) => {
        console.log(mData, 'mdat')
        if (Boolean(mData?.data?.success)) {
          setNameModal(false);
          if (hasId) {
            notification.success({
              message: "Flow is updated."
            })
          } else if (isString(mData?.data?.payload?.flowId)) {
            notification.success({
              message: "Flow is created."
            })
            navigate(`/flows/view/${mData.data.payload.flowId}`, { replace: true })
          }
          // navigate("/flows")
        }
        setCreatingLoading(false);
      })
      .catch(err => {
        if (err?.response?.data?.upgradePlan === true) {
          closeNameModal();
        }
        setCreatingLoading(false);
      })
  }

  const onEditAction = () => onCreateAction(flowName);
  const onDeleteAction = () => {
    setDeleteLoading(true);
    setCreatingLoading(false);
    setRunning(false);
    Request.delete(`${endpoints.flow}/${id}`)
      .then(mData => {
        if (Boolean(mData?.data?.success)) {
          notification.success({
            message: "Flow is deleted."
          })
          navigate(`/flows`)
        }
        setDeleteLoading(false);
      })
      .catch(_err => {
        setDeleteLoading(false);
      });
  };

  // const toggleLogsPanel = () => {
  //   dispatch(toggleLogsSidebar());
  // }

  const toggleGraphLock = () => {
    dispatch(setFlowLocked(!isFlowLocked as never));
  }

  const clearLogs = () => {
    setLogs([]);
    if (!isRunning) {
      setNodes((nodes) => nodes.map((n) => {
        n.data = {
          ...n.data,
          isInProgress: 0,
        };
        return n;
      }));
    }
  }

  const openNotificationSettings = () => {
    setNotifySettingsDrawerOpen(true);
  }

  const closeNotificationSettings = () => {
    setNotifySettingsDrawerOpen(false);
  }

  const applyNotificationSettings = () => {
    closeNotificationSettings();
  }

  return (
    <>
      <PageContent title={hasId ? flowName : "Create flow"} footer={<FooterContent onNotificationSettings={openNotificationSettings} isNotifySettingsDrawerOpen={isNotifySettingsDrawerOpen} isGraphLocked={isFlowLocked} isEdit={hasId} onDelete={onDeleteAction} toggleGraphLock={toggleGraphLock} onEdit={onEditAction} onRun={onRunAction} onCreate={openNameModal} isRunning={isRunning} isDeleteLoading={isDeleteLoading} isCreatingLoading={isCreatingLoading} isTestDisabled={edges?.length === 0} />} backButton>
        <div className='top-actions-container'>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10
          }}>
            {!isFlowLocked && <Button type='primary' onClick={addNode}>Add request <FileAddOutlined /></Button>}
            {isFlowLocked && <Tag color="green">Unlock to edit</Tag>}
          </div>
          {/* <Button type="primary" onClick={toggleLogsPanel}>Logs {showLogs ? <EyeInvisibleOutlined /> : <EyeOutlined />}</Button> */}
        </div>
        <PanelGroup direction="horizontal" autoSaveId="flow-container">
          <Panel defaultSize={60} minSize={30}>
            <div className="flow-editor-container">
              <div style={{ height: "75vh" }}>
                <ReactFlow
                  nodes={nodes}
                  edges={edgesWithUpdatedTypes}
                  onConnectStart={onConnectStart}
                  onNodesChange={onNodesChange}
                  onNodesDelete={onNodesDelete}
                  onEdgesChange={onEdgesChange}
                  onConnectEnd={onConnectEnd}
                  onConnect={onConnect}
                  // onInit={onInit}
                  // multiSelectionKeyCode="Shift"
                  fitViewOptions={{
                    padding: 5,
                  }}
                  defaultViewport={{
                    x: 100,
                    y: 20,
                    zoom: 0.5
                  }}
                  nodeTypes={nodeTypes}
                  proOptions={{ hideAttribution: true }}
                  edgesUpdatable={!isFlowLocked}
                  edgesFocusable={!isFlowLocked}
                  nodesDraggable={!isFlowLocked}
                  nodesConnectable={!isFlowLocked}
                  nodesFocusable={!isFlowLocked}
                  elementsSelectable={!isFlowLocked}
                >
                  <MiniMap
                    style={minimapStyle}
                    zoomable
                    pannable
                    nodeColor="#0c0f19"
                    maskColor="rgba(14, 19, 31, 0.93)"
                    position='top-right'
                  />
                  <Controls
                    position='top-left'
                    showInteractive={false}
                  />
                  <Background color="#aaa" gap={30} />
                  <FlowNodeDrawer />
                </ReactFlow>
              </div>
              <div>
              </div>
            </div>
          </Panel>
          {showLogs && <>
            <PanelResizeHandle className="flow-panel-handler" />
            <Panel defaultSize={30} minSize={20} style={{ position: "relative" }}>
              <div className='log-container' ref={logsRef}>
                <AutoScroll scrollBehavior='smooth' showOption={false} height={(logsRef as any)?.current?.clientHeight}>
                  {logs.map(msg => {
                    return (
                      <div key={msg} className={`log__msg`}>
                        {msg}
                      </div>
                    );
                  })}
                </AutoScroll>
              </div>
              <Button type="primary" onClick={clearLogs} className='log-clear-btn'><ClearOutlined /></Button>
            </Panel>
          </>}
        </PanelGroup>
      </PageContent>
      <CreateFlowModal
        isOpen={nameModalVisible}
        isCreateLoading={isCreatingLoading}
        onClose={closeNameModal}
        onCreate={onCreateAction}
      />
      {id && (
        <NotificationSettingsDrawer
          serviceType={ServiceTypes.flow}
          dataId={id}
          isOpen={isNotifySettingsDrawerOpen}
          onClose={closeNotificationSettings}
          onApply={applyNotificationSettings}
          onlyShow={[AlertChannels.email, AlertChannels.telegram]}
        />
      )}
    </>
  );
};

const FlowNodeContainer = () => {
  return (
    <ReactFlowProvider>
      <FlowNodeEditor />
    </ReactFlowProvider>
  )
}

export default memo(FlowNodeContainer);