import { QuestionCircleFilled } from "@ant-design/icons";
import { Form, Tag, Tooltip } from "antd";
import { PresetColorType, PresetStatusColorType } from "antd/es/_util/colors";
import { LiteralUnion } from "antd/lib/_util/type";
import { ReactNode } from "react";

const FormSettingsItem = (props: { label?: string | ReactNode, name: string, children: string | ReactNode, tooltip?: string, info?: string, infoColor?: LiteralUnion<PresetColorType | PresetStatusColorType, string>; }) => {
    return <Form.Item label={props.label}>
        <div className="form-settings-item-container">
            <div className="name-container">
                <div>{props.name}</div>
                {props.tooltip && <Tooltip title={props.tooltip}>
                    <QuestionCircleFilled style={{ cursor: "help" }} />
                </Tooltip>}
            </div>
            <div>
                {props.children}
            </div>
        </div>
        {props.info && <Tag style={{ marginTop: 10, maxWidth: 600, whiteSpace: "normal" }} color={props.infoColor || "blue"}>{props.info}</Tag>}
    </Form.Item>
}

export default FormSettingsItem;