import { useMemo } from "react";
import Flags, { FlagCode } from "../../../assets/images/flags";
import { useAppSelector } from "../../../utils/hooks/useRedux";
import { uptimeLogsData } from "../../../utils/types";
import { formattedDate } from "../../../utils/utils";
import _ from "lodash";

const UptimeTooltip = ({ active, payload }: any) => {
    const locations = useAppSelector(state => state.common.locations);

    const location = useMemo(() => {
        if (active && payload && payload.length) {
            const data: uptimeLogsData = payload?.[0].payload;
            return locations.find(x => x.regionId === data.regionId);
        }

        return null;
    }, [active, payload, locations]);

    const date = useMemo(() => {
        if (active && payload && payload.length) {
            const data: uptimeLogsData = payload?.[0].payload;
            return formattedDate(data?.date)
        }

        return null;
    }, [active, payload]);

    if (active && payload && payload.length) {
        const data: uptimeLogsData = payload?.[0].payload;
        return (
            <div className="log-tooltip">
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 10,
                }}>
                    <span style={{ fontSize: 10 }}>{date}</span>
                    <span style={{ fontSize: 10, fontWeight: 'bold', color: data.status === 1 ? '#5ecb96' : '#ff4d4f' }}>{data.status === 1 ? 'UP' : 'DOWN'}</span>
                </div>
                <div>
                    <span className="label">Uptime: </span>
                    <span className="value">{_.isNaN(data.uptime) ? 0 : data.uptime.toFixed(2)}%</span>
                </div>
                <div>
                    <span className="label">Latency: </span>
                    <span className="label">{data.duration.toFixed(2)} ms</span>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                    }}
                >
                    <span className="label">Location: </span>
                    <span
                        className="value"
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Flags height={15} code={location?.code as FlagCode} /> {location?.city ?? "Unknown"}
                    </span>
                </div>
            </div>
        );
    }

    return null;
};

export default UptimeTooltip;