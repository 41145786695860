import { Button, Input, Popconfirm, Tag, notification } from "antd";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PageContent from "../../components/PageContent";
import DangerActionConfirmModal from "../../components/DangerActionConfirmModal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/hooks/useRedux";
import Request from "../../utils/request";
import endpoints from "../../utils/endpoints";
import { ApiOutlined, CreditCardOutlined, DeleteFilled, EditOutlined, FormOutlined, InfoOutlined, KeyOutlined, UserAddOutlined, UserDeleteOutlined, UsergroupAddOutlined, WarningOutlined } from "@ant-design/icons";
import { setCacheFlows, setCacheMonitors, setTeamMembers } from "../../utils/redux/features/cache";
import { DATA_STATUSES, IProjectUsers, PlansByID, ProjectUserRoles } from "../../utils/types";
import { emailValidation } from "../../utils/utils";
import { upperFirst } from "lodash";
import { updateUser } from "../../utils/redux/features/common";

function Settings() {
  const dispatch = useAppDispatch();
  const dataFetchedRef = useRef(false);
  const prevProjectIdRef = useRef<string | null>(null);
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);
  const [refreshAPIKeyModal, setRefreshAPIKeyModal] = useState(false);
  const cacheState = useAppSelector((state) => state.cache);
  const commonState = useAppSelector((state) => state.common);
  const projectId = commonState.projectId;
  const projects = commonState.projects;
  const userData = commonState.user;
  const [isAPIKeyLoading, setAPIKeyLoading] = useState<boolean>(false);
  const [userInviteeEmail, setUserInviteeEmail] = useState<string>('');
  const [isUserInviteLoading, setUserInviteLoading] = useState<boolean>(false);
  const [isUserRevokeLoading, setUserRevokeLoading] = useState<string | null>(null);
  const [apiKey, setAPIKey] = useState<string | null>(null);

  const [isDeletingProject, setIsDeletingProject] = useState(false);

  const currentProject = useMemo(() => {
    return projects.find((project) => project.projectId === projectId);
  }, [projectId, projects]);

  const [projectName, setProjectName] = useState<string>(currentProject?.name || '');
  const [projectRenameLoading, setProjectRenameLoading] = useState<boolean>(false);

  const projectHasResources = useMemo(() => {
    return cacheState.monitors.length !== 0 || cacheState.flows.length !== 0;
  }, [cacheState.flows, cacheState.monitors]);

  const openDeleteProjectModal = () => {
    setDeleteProjectModal(true);
  }

  const openRefreshAPIKeyModal = () => {
    setRefreshAPIKeyModal(true);
  }

  const onDeleteProjectAction = () => {
    setIsDeletingProject(true);
    Request.delete(endpoints.project + `/${projectId}`)
      .then(() => {
        setIsDeletingProject(false);
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        setIsDeletingProject(false);
      });
  }

  const getFlows = useCallback(async () => {
    const flowsRes = await Request.get(endpoints.flow);
    if (flowsRes?.data?.success === true) {
      dispatch(setCacheFlows(flowsRes?.data?.payload?.data))
    }
  }, [dispatch])

  const getMonitors = useCallback(async () => {
    const monitorsRes = await Request.get(endpoints.monitor).catch(() => { });
    if (monitorsRes?.data?.success === true) {
      dispatch(setCacheMonitors(monitorsRes?.data?.payload?.data))
    } else {
      dispatch(setCacheMonitors([] as never))
    }
  }, [dispatch])

  const getProjectUsers = useCallback(() => {
    Request.get(endpoints.projectUsers)
      .then((res) => {
        if (res?.data?.success === false) {
          return dispatch(setTeamMembers([] as never));
        }
        dispatch(setTeamMembers(res?.data?.payload || []))
      })
      .catch(() => { })
  }, [dispatch])

  const getApiKey = useCallback(async () => {
    setAPIKeyLoading(true);
    return Request.get(endpoints.ApiKey)
      .then((res) => {
        setAPIKeyLoading(false);
        const key = res?.data?.payload?.key;
        if (key) {
          setAPIKey(key);
        }
      })
      .catch(() => {
        setAPIKeyLoading(false);
      })
  }, [])

  const createAPIKey = async () => {
    setAPIKeyLoading(true);
    return Request.post(endpoints.ApiKey)
      .then((res) => {
        setAPIKeyLoading(false);
        const key = res?.data?.payload?.key;
        if (key) {
          setAPIKey(key);
          notification.success({
            message: "API Key is created."
          })
        } else {
          notification.error({
            message: "Failed to create API Key."
          })
        }
      })
      .catch(() => {
        setAPIKeyLoading(false);
      })
  }

  const inviteUserAction = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUserInviteLoading(true);

    if (emailValidation(userInviteeEmail) === false) {
      notification.error({
        message: "Invalid email address."
      })
      setUserInviteLoading(false);
      return;
    }

    return Request.post(endpoints.sendInvitation, {
      inviteeEmail: userInviteeEmail
    })
      .then((res) => {
        setUserInviteLoading(false);
        const successRes = res?.data?.success;
        if (successRes) {
          setUserInviteeEmail('');
          getProjectUsers();
          notification.success({
            message: "User is invited."
          })
        } else {
          notification.error({
            message: "Failed to invite user."
          })
        }
      })
      .catch(() => {
        setUserInviteLoading(false);
      })
  }

  const revokeUserAction = async (inviteeEmail: string) => {
    setUserRevokeLoading(inviteeEmail);
    return Request.post(endpoints.revokeUser, {
      inviteeEmail
    })
      .then((res) => {
        setUserRevokeLoading(null);
        const successRes = res?.data?.success;
        if (successRes) {
          getProjectUsers();
          notification.success({
            message: "User is revoked."
          })
        } else {
          notification.error({
            message: "Failed to revoke user."
          })
        }
      })
      .catch(() => {
        setUserRevokeLoading(null);
      })
  }

  const renameProjectAction = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!projectName) return notification.error({ message: "Project name is required." });
    if (projectRenameLoading) return notification.error({ message: "Project rename is in progress." });
    setProjectRenameLoading(true);
    return Request.post(endpoints.renameProject, {
      name: projectName
    })
      .then(() => {
        Request.get(endpoints.profile).then(res => {
          setProjectRenameLoading(false);
          notification.success({
            message: "Project is renamed."
          })
          if (res.data?.payload) dispatch(updateUser(res.data?.payload))
        }).catch(() => {
          setProjectRenameLoading(false);
        });

      })
      .catch(() => {
        setProjectRenameLoading(false);
      })
  }

  const onApiKeyCopied = () => {
    notification.success({
      message: "API Key is copied."
    })
  }

  // Fetch resources
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    prevProjectIdRef.current = projectId;

    if (!cacheState.flows.length) getFlows();
    if (!cacheState.monitors.length) getMonitors();
    if (!cacheState.teamMembers.length) getProjectUsers();

    getApiKey();
  }, [cacheState.flows.length, cacheState.monitors.length, cacheState.teamMembers.length, getFlows, getMonitors, getProjectUsers, getApiKey, projectId])

  return (
    <>
      <PageContent title="Project settings" contentClassName="settings-container">
        <div className="settings-item">
          <h2 className="settings-label"><InfoOutlined /> Information</h2>
          <div className="settings-content">
            <div className="settings-content-child">
              <div style={{ display: "flex", gap: 10 }}>
                <label style={{ display: "flex", gap: 7, alignItems: "center", fontWeight: "bold" }}><CreditCardOutlined />Project plan:</label>
                <label>{upperFirst(PlansByID[userData?.plan as never || 0])}</label>
              </div>
              <div>
                <Tag color="orange" style={{ marginRight: 0, wordWrap: 'break-word', whiteSpace: 'pre-wrap', fontSize: 13 }}>When the project owner upgrades the project, all team members gain access to the upgraded features within that project.</Tag>
              </div>
            </div>
          </div>
        </div>

        <div className="settings-item">
          <h2 className="settings-label"><FormOutlined /> General</h2>
          <div className="settings-content">
            <div className="settings-content-child">
              <label>Rename project</label>
              <form style={{ display: 'flex', alignItems: 'center', gap: 10 }} onSubmit={renameProjectAction}>
                <Input placeholder="Project name" style={{ maxWidth: 500, minHeight: 32 }} disabled={projectRenameLoading} onChange={e => setProjectName(e.target.value)} value={projectName} />
                <Button type="primary" style={{ minHeight: "35.14px" }} htmlType="submit" disabled={projectRenameLoading || projectName?.length === 0 || currentProject?.name === projectName} loading={projectRenameLoading}>
                  <EditOutlined /> Rename
                </Button>
              </form>
            </div>
          </div>
        </div>

        <div className="settings-item">
          <h2 className="settings-label"><UsergroupAddOutlined /> Team members</h2>
          <div className="settings-content">
            <div className="settings-content-child" style={{ gap: 20 }}>
              <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <label>Invite member</label>
                <form style={{ gap: 10, display: "flex", alignItems: "center" }} onSubmit={inviteUserAction}>
                  <Input placeholder="E-mail" type="email" style={{ maxWidth: 500, minHeight: 32 }} disabled={isUserInviteLoading} value={userInviteeEmail} onChange={e => setUserInviteeEmail(e.target.value)} />
                  <Button type="primary" style={{ minHeight: "35.14px" }} loading={isUserInviteLoading} htmlType="submit" disabled={userInviteeEmail?.length === 0 || isUserInviteLoading}>
                    <UserAddOutlined /> Send
                  </Button>
                </form>
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <label>Team members</label>
                <div className="project-users-list">
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 10, maxWidth: 480 }}>
                    {
                      cacheState.teamMembers.map((member: IProjectUsers) => (
                        <div key={member.email} className="user-item">
                          <label className="project-user-email">{member.email}</label>
                          <Tag color={
                            member.status === DATA_STATUSES.pending ? 'orange' :
                              member.role === ProjectUserRoles.owner ? 'red' :
                                member.role === ProjectUserRoles.admin ? 'blue' : 'green'
                          }
                            style={{ marginRight: 0, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                            {member.status === DATA_STATUSES.pending ? 'Pending' : member.role === ProjectUserRoles.owner ? 'Owner' : member.role === ProjectUserRoles.admin ? 'Admin' : 'Member'}
                          </Tag>

                          <Popconfirm
                            title="Are you sure to revoke this user?"
                            onConfirm={() => revokeUserAction(member.email)}
                            okText="Yes"
                            cancelText="No"
                            okButtonProps={{ type: "primary", danger: true }}
                            cancelButtonProps={{ type: "default" }}
                            disabled={member.role === ProjectUserRoles.owner || isUserRevokeLoading === member.email}
                          >
                            <Button
                              type="primary"
                              disabled={member.role === ProjectUserRoles.owner || isUserRevokeLoading === member.email}
                              loading={isUserRevokeLoading === member.email}
                            >
                              <UserDeleteOutlined />
                            </Button>
                          </Popconfirm>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="settings-item">
          <h2 className="settings-label"><ApiOutlined /> API integration</h2>
          <div className="settings-content">
            <div className="settings-content-child">
              <div>
                <span>Please refer to our <a href="https://docs.onradar.io" target="_blank" rel="noreferrer">API Documentation</a> for more information.</span><br /><br />
                <label>Secret key</label><br />
                <CopyToClipboard text={apiKey as string} onCopy={onApiKeyCopied}>
                  <Input.Password
                    placeholder="No key"
                    style={{ maxWidth: 500, minHeight: 32, margin: "10px 0 20px 0" }}
                    disabled={isAPIKeyLoading || !apiKey} value={apiKey || 'No key'}
                  />
                </CopyToClipboard><br />
                <Button type="primary" disabled={isAPIKeyLoading} loading={isAPIKeyLoading} onClick={apiKey ? openRefreshAPIKeyModal : createAPIKey}>
                  {apiKey ? 'Refresh' : 'Create'} key <KeyOutlined />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="settings-item settings-item-danger">
          <h2 className="settings-label"><WarningOutlined /> Danger zone</h2>
          <div className="settings-content">
            <div className="settings-content-child">
              <div>
                <Button type="primary" danger onClick={openDeleteProjectModal} disabled={isDeletingProject || projects.length === 1 || projectHasResources || userData?.isProjectOwner === false} loading={isDeletingProject}>
                  Delete project <DeleteFilled />
                </Button>
                {
                  (userData?.isProjectOwner === false ||
                    projects.length === 1 ||
                    projectHasResources) && (
                    <>
                      <br /><br />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {
                          userData?.isProjectOwner === false ? <Tag color="warning" style={{ marginRight: 0, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>You're not the project owner.</Tag> :
                            projects.length === 1 ? <Tag color="warning" style={{ marginRight: 0, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>You cannot delete the last project.</Tag> :
                              projectHasResources && <Tag color="warning" style={{ marginRight: 0, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>You cannot delete the project with resources.</Tag>
                        }
                      </div>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </PageContent>
      <DangerActionConfirmModal
        isOpen={deleteProjectModal}
        onClose={() => setDeleteProjectModal(false)}
        onSubmit={onDeleteProjectAction}
        secretKey={currentProject?.name}
        messageText={<div style={{ marginBottom: 10 }}>
          <h3>Do you really want to delete this project?</h3><br />If so, please type the project name <b>({currentProject?.name})</b> to confirm.<br />This action cannot be undone.
        </div>}
      />
      <DangerActionConfirmModal
        isOpen={refreshAPIKeyModal}
        onClose={() => setRefreshAPIKeyModal(false)}
        onSubmit={createAPIKey}
        submitButtonText="Refresh key"
        messageText={<div style={{ marginBottom: 10 }}>
          <h3>Do you really want to change the API key?</h3>
          <br />This action will invalidate the current key and generate a new one.
        </div>}
      />
    </>
  );
}

export default Settings;
