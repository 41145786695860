import { Form, Tabs } from "antd";
import { memo, ReactNode, useEffect, useState } from "react";
import { contientnsData } from "../utils/constants";
import { LocationInterface } from "../utils/types";
import RegionItem from "./RegionItem";
import _ from "lodash";
import { useAppSelector } from "../utils/hooks/useRedux";

type IRegionSelectionTabTypes = {
    label?: string | ReactNode;
    selectedArr: number[];
    onToggle: (id: number) => void;
}

const RegionContainer = (props: { data: LocationInterface[], onClick: (id: number) => void, selectedArr: number[] }) => {
    return <div className="regions-container">
        {props.data.map(c => (
            <RegionItem
                key={c.regionId}
                isActive={props.selectedArr.indexOf(c.regionId) !== -1}
                id={c.regionId}
                countryCode={c.code}
                country={c.name}
                city={c.city}
                onClick={props.onClick}
                unavailable={c.unavailable}
            />
        ))}
    </div>
}

const RegionSelectionTab = (props: IRegionSelectionTabTypes) => {
    const serverRegionsData = useAppSelector(state => state.common.locations);
    const [tabs, setTabs] = useState<any[]>([]);

    useEffect(() => {
        const serverDataAll = _.sortBy(serverRegionsData.filter(rD => rD.enabled), ['unavailable']);
        const tabsArr = [
            {
                label: "All",
                key: 'all',
                children: <RegionContainer selectedArr={props.selectedArr} onClick={props.onToggle} data={serverDataAll} />,
            }
        ];

        for (const x in contientnsData) {
            const serverDataRegion = _.sortBy(serverRegionsData.filter(rD => rD.location === contientnsData[x].id && rD.enabled), ['unavailable']);
            tabsArr.push({
                label: contientnsData[x].name,
                key: contientnsData[x].name,
                children: <RegionContainer selectedArr={props.selectedArr} onClick={props.onToggle} data={serverDataRegion} />,
            })
        }

        setTabs(tabsArr);
    }, [props.selectedArr, props.onToggle, serverRegionsData])

    return (
        <Form.Item label={props.label}>
            <Tabs
                defaultActiveKey="all"
                type="card"
                size="large"
                items={tabs}
            />
        </Form.Item>
    )
}

export default memo(RegionSelectionTab);