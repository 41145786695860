import { PlusOutlined } from "@ant-design/icons"; // SearchOutlined
// import { Input } from "antd";
import { useCallback, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import MonitorItem from "../../../components/MonitorItem";
import PageContent from "../../../components/PageContent";
import endpoints from "../../../utils/endpoints";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/useRedux";
import { setCacheMonitors } from "../../../utils/redux/features/cache";
import Request from "../../../utils/request";
import { setContentLoading } from "../../../utils/redux/features/cache";

function Monitors() {
  const dispatch = useAppDispatch();
  const dataFetchedRef = useRef(false);
  const monitors = useAppSelector(state => state.cache.monitors);
  const projectId = useAppSelector(state => state.common.projectId);
  const location = useLocation();

  const getMonitors = useCallback(async () => {
    if (monitors.length === 0) dispatch(setContentLoading(true));
    const monitorsRes = await Request.get(endpoints.monitor).catch(() => {
      if (monitors.length === 0) dispatch(setContentLoading(false));
    });
    if (monitorsRes?.data?.success === true) {
      dispatch(setCacheMonitors(monitorsRes?.data?.payload?.data))
    } else {
      dispatch(setCacheMonitors([] as never))
    }
    if (monitors.length === 0) dispatch(setContentLoading(false));
  }, [dispatch, monitors])

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    getMonitors();

    const monitorInterval = setInterval(getMonitors, 1000 * 60 * 2); // Refresh monitors every 2 minutes

    return () => {
      clearInterval(monitorInterval);
    }
  }, [getMonitors, projectId])

  return (
    <PageContent title="Monitors">
      <div className="filter-area">
        {/* <Input
          // asyncControl={true}
          // onChange={this.handleFilterChange}
          placeholder="Search..."
          style={{ width: 300 }}
          suffix={<SearchOutlined />}
        // value={filterValue}
        /> */}
      </div>
      <div className="monitor-list">
        <Link to={location.pathname === "/" ? "/monitors/create" : "create"} className="monitor-add">
          <div><PlusOutlined style={{ marginRight: 5 }} /> Create monitor</div>
        </Link>
        {monitors.length === 0 ? <div className="no-data">No monitors found.</div> :
          monitors.map((site: any) => (
            <MonitorItem
              key={site.monitorId}
              id={site.monitorId}
              companyName={site.name}
              website={site.endpoint}
              status={site.status}
              endpointStatus={site?.endpointStatus}
            />
          ))
        }
      </div>
    </PageContent>
  );
}

export default Monitors;
