import { Button, Drawer, Input, Space, notification } from "antd"
import { CloseCircleFilled, InfoCircleOutlined, LoadingOutlined, SaveFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Request from "../../utils/request";
import endpoints from "../../utils/endpoints";
import { AlertChannels, IntegrationCredentialsInterface } from "../../utils/types";

type NotificationAlertSettingsDrawerProps = {
    channelId: number | string | null,
    data: IntegrationCredentialsInterface | null | undefined
    onApply: () => void;
    onClose: () => void;
    refreshIntegrations: () => void;
}

const WebhookDrawer = ({ channelId, data, onApply, onClose, refreshIntegrations }: NotificationAlertSettingsDrawerProps) => {
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [name, setName] = useState<string>("");
    const [token, setToken] = useState<string>("");

    const resetForm = () => {
        setTimeout(() => {
            setName("");
            setToken("");
        }, 500)
    }

    const closeDrawer = () => {
        onClose();
        resetForm();
    }

    useEffect(() => {
        if (channelId === null) resetForm();
    }, [channelId]);

    useEffect(() => {
        if (data) {
            if (!name.length) setName(data.name);
            if (!token.length) setToken(data.token as string);
        }
    }, [data, name.length, token.length])

    const applyChanges = async () => {
        if (!name.length) return;
        setSaveLoading(true);
        if (data?.integrationId) {
            // Update integration
            await Request.put(endpoints.integrationUpdate(data.integrationId), {
                name,
                token
            })
                .then(res => {
                    if (res.data?.success) {
                        notification.success({
                            message: "Successfully updated."
                        })
                    }
                    setSaveLoading(false);
                })
                .catch(() => {
                    setSaveLoading(false);
                });
        } else {
            // Create new integration
            await Request.post(endpoints.integration, {
                name,
                channel: channelId,
                token
            })
                .then(res => {
                    if (res.data?.success === true) {
                        onApply();
                        resetForm();
                        notification.success({
                            message: "Successfully created."
                        })
                    }
                    setSaveLoading(false);
                })
                .catch(() => {
                    setSaveLoading(false);
                });
        }
        refreshIntegrations();
    }

    return (
        <Drawer
            title={<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <span>{data?.integrationId ? "Edit" : "Connect"} Webhook integration</span>
                {isDataLoading && <LoadingOutlined spin />}
            </div>}
            open={channelId === AlertChannels.webhook}
            size="large"
            onClose={closeDrawer}
            closable={false}
            extra={
                <Space>
                    <Button type="primary" disabled={isSaveLoading || isDataLoading} onClick={closeDrawer}>Close <CloseCircleFilled /></Button>
                    <Button className="run-btn" loading={isSaveLoading} disabled={isSaveLoading || !name.length || !token.length} type="primary" onClick={applyChanges}>Save <SaveFilled /></Button>
                </Space>
            }
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10
                }}
            >
                <span>Name</span>
                <Input
                    type="text"
                    placeholder="Service status webhook"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                    <span>URL</span>
                    <Input
                        type="text"
                        placeholder="https://acme.com/webhook/alerts"
                        value={token}
                        onChange={e => setToken(e.target.value)}
                    />
                    <p className="integration-instructions">
                        <InfoCircleOutlined style={{ marginRight: 5 }} /> We'll send a <b>POST</b> request to your webhook URL with a JSON payload. You can use this payload to trigger alerts in your system.
                    </p>
                    <b>Webhook response example:</b>
                    <code className="code-snippet">
                        {`{`}<br />
                        &nbsp;&nbsp;<b>{`projectId: `}</b><span>{`"p_xxxxxxxxxxxxxx"`}</span>,<br />
                        &nbsp;&nbsp;<b>{`monitorId: `}</b><span>{`"m_xxxxxxxxxxxxxx"`}</span>,<br />
                        &nbsp;&nbsp;<b>{`endpoint: `}</b><span>{`"https://acme.com/api/services"`}</span>,<br />
                        &nbsp;&nbsp;<b>{`duration: `}</b><span className="code-snippet-number">{`90`}</span>,<br />
                        &nbsp;&nbsp;<b>{`statusCode: `}</b><span className="code-snippet-number">{`200`}</span>,<br />
                        &nbsp;&nbsp;<b>{`status: `}</b><span>{`"up" | "down"`}</span><br />
                        {`}`}
                    </code>
                </div>
            </div>
        </Drawer>
    )
}

export default WebhookDrawer;