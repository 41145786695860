import * as React from "react"
import { SVGProps, memo } from "react"

const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    height={35}
    style={{
      marginRight: ".75em",
    }}
    {...props}
  >
    <path
      fill="#EEE"
      d="M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v18z"
    />
    <circle fill="#ED1B2F" cx={18} cy={18} r={7} />
  </svg>
)

const FlagJP = memo(Icon)
export default FlagJP
