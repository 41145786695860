import { DeleteOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Input, Table } from "antd";
import { useEffect, useMemo, useState, memo, useCallback } from "react";
import useIsMounted from "../utils/hooks/useIsMounted";
import { IKeyValueData } from "../utils/types";

type IKeyValueTableProps = {
    onDataChange?: (data: IKeyValueData[]) => void,
    autoCompleteOptions?: { value: string }[],
    defaultData: IKeyValueData[] | null | undefined,
    keyColumnTitle?: string,
    valueColumnTitle?: string,
}

const KeyValueTable = ({ onDataChange, autoCompleteOptions, defaultData, keyColumnTitle, valueColumnTitle }: IKeyValueTableProps) => {
    const isMounted = useIsMounted();
    const [data, setData] = useState<IKeyValueData[]>(defaultData || [...new Array(1)].map((_d, id) => ({
        id,
        data_key: "",
        data_value: "",
    })));

    const fillIfNeed = useCallback((index: number, objectID: number) => {
        if (data.length > 0 && index === data.length - 1 && (data?.[index]?.data_key !== "" || data?.[index]?.data_value !== "")) {
            setData(prev => [...prev, {
                id: objectID + 1,
                data_key: "",
                data_value: "",
            }]);
        }
    }, [data]);

    const deleteItem = (index: number) => {
        setData(prev => prev.filter((_nI, i) => i !== index));
    }

    const columns = useMemo(() => [
        {
            title: keyColumnTitle ?? 'Key',
            dataIndex: 'key',
            key: 'key',
            width: "50%",
            render: (defaultValue: any, object: IKeyValueData, index: number) => {
                const valueData = data.find(d => d.id === object.id);
                if (autoCompleteOptions) {
                    return <AutoComplete
                        style={{ width: "100%" }}
                        options={autoCompleteOptions}
                        filterOption={(inputValue, option) =>
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        value={valueData?.data_key || defaultValue}
                        defaultValue={defaultValue}
                        onChange={(value) => {
                            fillIfNeed(index, object.id);
                            setData(prev => prev.map(d => {
                                if (d.id === object.id) d.data_key = value;
                                return d;
                            }));
                        }}
                        onSelect={(value: any) => {
                            fillIfNeed(index, object.id);
                            setData(prev => prev.map(d => {
                                if (d.id === object.id) d.data_key = value;
                                return d;
                            }));
                        }}
                    />
                }
                return <Input
                    value={valueData?.data_key || defaultValue}
                    defaultValue={defaultValue}
                    onChange={(e) => {
                        fillIfNeed(index, object.id);
                        setData(prev => prev.map(d => {
                            if (d.id === object.id) d.data_key = e.target.value;
                            return d;
                        }));
                    }}
                />
            }
        },
        {
            title: valueColumnTitle ?? 'Value',
            dataIndex: 'value',
            key: 'value',
            width: "50%",
            render: (defaultValue: any, object: IKeyValueData, index: number) => {
                const valueData = data.find(d => d.id === object.id);
                return <Input
                    value={valueData?.data_value || defaultValue}
                    defaultValue={defaultValue}
                    onChange={(e) => {
                        fillIfNeed(index, object.id);
                        setData(prev => prev.map(d => {
                            if (d.id === object.id) d.data_value = e.target.value;
                            return d;
                        }));
                    }}
                />
            }
        },
        {
            title: '',
            key: 'action',
            render: (_v: any, _o: IKeyValueData, index: number) => {
                return <div>
                    {(data.length > 1 && index !== data.length - 1) && <Button type="primary" onClick={() => deleteItem(index)}><DeleteOutlined /></Button>}
                </div>;
            }
        }
    ], [keyColumnTitle, valueColumnTitle, data, autoCompleteOptions, fillIfNeed]);

    useEffect(() => {
        if (isMounted()) {
            if (onDataChange) onDataChange(data);
        }
        // eslint-disable-next-line
    }, [isMounted, data])

    return (
        <div>
            <Table
                dataSource={data}
                columns={columns}
                size="small"
                pagination={false}
                rowKey="id"
            />
        </div>
    )
}

export default memo(KeyValueTable);