import { upperFirst } from "lodash";
import PageContent from "../../components/PageContent";
import { supportedCountriesList } from "../../utils/constants";
import { useAppSelector } from "../../utils/hooks/useRedux";
import { DATA_STATUSES_REVERSE, PlansByID } from "../../utils/types";
import { Button, Input, notification } from "antd";
import { InfoOutlined, LockOutlined } from "@ant-design/icons";
import DangerActionConfirmModal from "../../components/DangerActionConfirmModal";
import { useState } from "react";
import endpoints from "../../utils/endpoints";
import Request from "../../utils/request";

function Account() {
  const commonState = useAppSelector((state) => state.common);
  const [dangerModalOpen, setDangerModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const countryName = Object.keys(supportedCountriesList).find((key) => supportedCountriesList[key] === commonState?.user?.country);

  const openPasswordChangeModal = () => {
    setDangerModalOpen(true);
  }

  const closeDangerModal = () => {
    setDangerModalOpen(false);
  }

  const changePasswordAction = () => {
    setLoading(true);
    Request.post(endpoints.passwordChange, {
      password: newPassword
    })
      .then(() => {
        setNewPassword('');
        setLoading(false);
        notification.success({
          message: 'Password changed',
          description: 'Password has been changed successfully'
        });
      })
      .catch(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <PageContent title="Account" contentClassName="settings-container">
        <div className="settings-item">
          <h2 className="settings-label"><InfoOutlined /> Information</h2>
          <div className="settings-content" style={{ gap: 0 }}>
            <div className="settings-content-child" style={{ flexDirection: "row" }}>
              <label style={{ fontWeight: 'bold' }}>Email:</label>
              <div>{commonState?.user?.email} ({commonState?.user?.emailVerified ? 'verified' : 'not verified'})</div>
            </div>

            {countryName && (
              <div className="settings-content-child" style={{ flexDirection: "row" }}>
                <label style={{ fontWeight: 'bold' }}>Country:</label>
                <div>{countryName}</div>
              </div>
            )}

            <div className="settings-content-child" style={{ flexDirection: "row" }}>
              <label style={{ fontWeight: 'bold' }}>Plan:</label>
              <div>{upperFirst(PlansByID[commonState?.user?.plan as never || 0])}</div>
            </div>

            <div className="settings-content-child" style={{ flexDirection: "row" }}>
              <label style={{ fontWeight: 'bold' }}>Projects:</label>
              <div>{commonState?.user?.projects?.length || 1}</div>
            </div>

            <div className="settings-content-child" style={{ flexDirection: "row" }}>
              <label style={{ fontWeight: 'bold' }}>Status:</label>
              <div>{upperFirst(DATA_STATUSES_REVERSE[commonState?.user?.status || 0])}</div>
            </div>
          </div>
        </div>

        <div className="settings-item">
          <h2 className="settings-label"><LockOutlined /> Password change</h2>
          <div className="settings-content" style={{ gap: 10 }}>
            <label style={{ fontWeight: 'bold' }}>New password</label>
            <Input.Password placeholder="*******" value={newPassword} onChange={e => setNewPassword(e.target.value)} style={{ minHeight: 32, width: 300 }} />
            <Button type="primary" onClick={openPasswordChangeModal} disabled={isLoading || newPassword.length === 0 || newPassword.length < 5} style={{ width: 300 }}>Save password</Button>
          </div>
        </div>
      </PageContent>
      <DangerActionConfirmModal
        isOpen={dangerModalOpen}
        onClose={closeDangerModal}
        onSubmit={changePasswordAction}
        submitButtonText="Yes, change password"
        title="Change password"
        messageText="Are you sure you want to change the password?"
      />
    </>
  );
}

export default Account;
