import { Button, Drawer, Input, Space, notification } from "antd"
import { CloseCircleFilled, InfoCircleOutlined, LoadingOutlined, SaveFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import Request from "../../utils/request";
import endpoints from "../../utils/endpoints";
import { AlertChannels, IntegrationCredentialsInterface } from "../../utils/types";

type NotificationAlertSettingsDrawerProps = {
    channelId: number | string | null,
    data: IntegrationCredentialsInterface | null | undefined;
    onApply: () => void;
    onClose: () => void;
    refreshIntegrations: () => void;
}

const TelegramDrawer = ({ channelId, data, onApply, onClose, refreshIntegrations }: NotificationAlertSettingsDrawerProps) => {
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [name, setName] = useState<string>("");
    const [token, setToken] = useState<string>("");

    const resetForm = () => {
        setTimeout(() => {
            setName("");
            setToken("");
        }, 500)
    }

    const closeDrawer = () => {
        onClose();
        resetForm();
    }

    useEffect(() => {
        if (channelId === null) resetForm();
    }, [channelId]);

    useEffect(() => {
        if (data) {
            if (!name.length) setName(data.name);
            if (!token.length) setToken(data.token as string);
        }
    }, [data, name.length, token.length])

    const applyChanges = async () => {
        if (!name.length || !token.length) return;
        setSaveLoading(true);
        if (data?.integrationId) {
            // Update integration
            await Request.put(endpoints.integrationUpdate(data?.integrationId), {
                name,
                token
            })
                .then(res => {
                    if (res.data?.success) {
                        notification.success({
                            message: "Successfully updated."
                        })
                    }
                    setSaveLoading(false);
                })
                .catch(() => {
                    setSaveLoading(false);
                });
        } else {
            // Create new integration
            await Request.post(endpoints.integration, {
                name,
                channel: channelId,
                token
            })
                .then(res => {
                    if (res.data?.success === true) {
                        onApply();
                        resetForm();
                        notification.success({
                            message: "Successfully created."
                        })
                    }
                    setSaveLoading(false);
                })
                .catch(() => {
                    setSaveLoading(false);
                });
        }
        refreshIntegrations();
    }

    return (
        <Drawer
            title={<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <span>{data?.integrationId ? "Edit" : "Connect"} Telegram integration</span>
                {isDataLoading && <LoadingOutlined spin />}
            </div>}
            open={channelId === AlertChannels.telegram}
            size="large"
            onClose={closeDrawer}
            closable={false}
            extra={
                <Space>
                    <Button type="primary" disabled={isSaveLoading || isDataLoading} onClick={closeDrawer}>Close <CloseCircleFilled /></Button>
                    <Button className="run-btn" loading={isSaveLoading} disabled={isSaveLoading || !name.length || !token.length} type="primary" onClick={applyChanges}>Save <SaveFilled /></Button>
                </Space>
            }
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10
                }}
            >
                <span>Name</span>
                <Input
                    type="text"
                    placeholder="DevOps Group"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <span>Chat ID</span>
                <Input
                    type="text"
                    placeholder="-208407830"
                    value={token}
                    onChange={e => setToken(e.target.value)}
                />
                <p className="integration-instructions">
                    <InfoCircleOutlined style={{ marginRight: 5 }} /> Please invite our Telegram bot (<a href="https://t.me/onradarBot" rel="noreferrer" target="_blank"><strong>@onradarBot</strong></a>) to your group or start a private chat with it.
                    <br />
                    You will receive <i>-208407830</i> as the chat ID. Please make sure to copy the whole number including dash (-) if exists and paste it here.
                </p>
            </div>
        </Drawer>
    )
}

export default TelegramDrawer;