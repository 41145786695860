import { addNewFlowNodeData } from "../../utils/constants";

export const nodes = [
  {
    id: 'Initial',
    type: 'request',
    data: addNewFlowNodeData,
    position: { x: 0, y: 0 },
  }
];

export const edges = [];
