import { SVGProps, memo } from "react"

const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    height={35}
    style={{
      marginRight: ".75em",
    }}
    {...props}
  >
    <path fill="#EEE" d="M15 6 8 5H4a4 4 0 0 0-4 4v18a4 4 0 0 0 4 4h4l7-1V6z" />
    <path
      fill="#CE1126"
      d="M32 5H8l6.081 2.6L8 10.2l6.081 2.6L8 15.4l6.081 2.6L8 20.6l6.081 2.6L8 25.8l6.081 2.6L8 31h24a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4z"
    />
  </svg>
)

const FlagBH = memo(Icon)
export default FlagBH
