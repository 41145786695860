import classNames from "classnames";
import isNil from "lodash/isNil";
import isArray from "lodash/isArray";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import isObject from "lodash/isObject";
import isNaN from "lodash/isNaN";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageContent from "../../../components/PageContent";
import { setContentLoading } from "../../../utils/redux/features/cache";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/useRedux";
import Request from "../../../utils/request";
import endpoints from "../../../utils/endpoints";
import { Button, Tag, notification } from "antd";
import { DATA_STATUSES, HTTPBreakdownData, MonitorResponseData, SSLCertificateData, ServiceTypes, UPTIME_LOG_STATUSES } from "../../../utils/types";
import { BellFilled, BellOutlined, EditOutlined, LinkOutlined, LoadingOutlined, PauseOutlined, PlayCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import HTTPBreakdownSection from "../../../components/HTTPBreakdownSection";
import { differenceInDays } from "date-fns";
import Flags, { FlagCode } from "../../../assets/images/flags";
import NotificationSettingsDrawer from "../../../components/NotificationSettingsDrawer";
import { ResponseTimeChart } from "../../../components/Charts/ResponseTimeChart";
import { UptimeChart } from "../../../components/Charts/UptimeChart";

type IFooterContentTypes = {
  onEdit: () => void;
  onNotificationSettings: () => void;
  isEditDisabled: boolean;
  isNotifySettingsDrawerOpen: boolean;
}

const FooterContent = ({ onEdit, onNotificationSettings, isEditDisabled, isNotifySettingsDrawerOpen }: IFooterContentTypes) => {
  return (
    <div className="sticky-footer-content">
      <Button type="primary" disabled={isEditDisabled} onClick={onEdit}>Edit <EditOutlined /></Button>
      <Button type="primary" disabled={isEditDisabled} onClick={onNotificationSettings}>Notifications {isNotifySettingsDrawerOpen ? <BellFilled /> : <BellOutlined />}</Button>
    </div>
  )
}

function MonitorView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const dataFetchedRef = useRef(false);
  const locations = useAppSelector(state => state.common.locations);
  const [isEditDisabled, setEditDisabled] = useState(false);
  const [isFetchingSSL, setFetchingSSL] = useState(false);
  const [isStatusChanging, setIsStatusChanging] = useState(false);
  const [isNotifySettingsDrawerOpen, setNotifySettingsDrawerOpen] = useState(false);
  const [data, setData] = useState<MonitorResponseData | null>(null);
  const [httpBreakdown, setHTTPBreakdown] = useState<HTTPBreakdownData[] | null>(null);

  const monitorStatus = useMemo(() => data?.monitor?.status, [data]);

  const SSLDaysLeft = useMemo(() => {
    const day = differenceInDays(new Date(data?.ssl?.info?.valid_to as never), new Date());
    if (day < 0) return 0;
    if (isNaN(day) || day.toString() === "NaN") return 0;
    return day;
  }, [data]);

  const SSLIndicatorColor = useMemo(() => {
    if (SSLDaysLeft <= 10) return "#ff7575";
    if (SSLDaysLeft <= 20) return "#ffc975";
    return "#75ff83";
  }, [SSLDaysLeft])

  const hasSSL = useMemo(() => !isNil(data?.ssl?.info?.valid_from), [data?.ssl]);

  const incidents = useMemo(() => data?.incidentLogs, [data]);
  const hasActiveIncident = useMemo(() => {
    if (!incidents) return false;
    return incidents.some(x => x.endpointStatus === UPTIME_LOG_STATUSES.down);
  }, [incidents]);

  const uptimeLogsData = useMemo(() => {
    const logs = data?.logs?.uptimeLogs || [];
    const dataRefined = logs.map((x, i) => {
      const total = logs.slice(0, i + 1).reduce((acc, curr) => acc + curr.duration, 0);
      const uptime = logs.slice(0, i + 1).reduce((acc, curr) => acc + (curr.status === 1 ? curr.duration : 0), 0);
      return {
        ...x,
        uptime: (uptime / total) * 100
      }
    });
    return dataRefined;
  }, [data]);

  const getRegion = useCallback((regionId: number) => {
    if (!regionId || !locations?.length) return null
    return locations.find(x => x.regionId === regionId);
  }, [locations]);

  const fetchSSL = useCallback(async () => {
    if (isNil(id)) return;
    setFetchingSSL(true);
    await Request.get(endpoints.monitorFetchSSL(id))
      .then(m_Data => {
        const mData: SSLCertificateData = m_Data?.data?.payload;
        if (isObject(mData)) {
          setData(prev => ({ ...prev, ssl: mData }) as never);
        }
      })
      .finally(() => setFetchingSSL(false))
  }, [id])

  const fetchMonitorData = useCallback(async () => {
    if (isNil(id)) return;
    Request.get(`${endpoints.monitor}/${id}/details`)
      .then(m_Data => {
        const mData = m_Data?.data?.payload;
        if (m_Data.data.success === false || isNaN(mData) || isNull(mData)) {
          notification.error({ message: "Monitor not found" });
          return navigate("/monitors");
        }

        if (isString(mData?.monitor?.monitorId)) {
          setData(mData);
        }
      })
      .catch(err => {
        if (
          err?.response?.data?.code === "Internal_SingleMonitor#2" ||
          err?.response?.data?.code === "Internal_SingleMonitor#0"
        ) {
          return navigate("/404");
        }
      });
  }, [id, navigate]);

  useEffect(() => {
    const fetchHTTPBreakdown = async () => {
      if (isNil(id)) return;
      return Request.get(endpoints.monitorBreakdownStats(id))
        .then(m_Data => {
          const mData = m_Data?.data?.payload;
          if (isArray(mData)) {
            setHTTPBreakdown(mData);
          }
        })
    }

    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (isNil(id)) {
      notification.error({ message: "Monitor not found" });
      return navigate("/monitors");
    }

    (async () => {
      dispatch(setContentLoading(true));
      await Promise.all([fetchMonitorData(), fetchHTTPBreakdown(), fetchSSL()]).catch(err => {
        dispatch(setContentLoading(false));
      });
      dispatch(setContentLoading(false));
    })()

    const loadingCooldown = setTimeout(() => {
      dispatch(setContentLoading(false));
    }, 2000);

    // const refreshData = setInterval(() => {
    //   Promise.all([fetchMonitorData(), fetchHTTPBreakdown()]);
    // }, 1000 * 60 * 2); // Refresh data every 2 minutes

    return () => {
      dataFetchedRef.current = false;
      setEditDisabled(false);
      setData(null);
      setHTTPBreakdown(null);
      // clearInterval(refreshData);
      clearTimeout(loadingCooldown);
      dispatch(setContentLoading(false));
    }
  }, [id, dispatch, navigate, fetchMonitorData, fetchSSL])

  const onEditAction = () => {
    navigate("/monitors/view/edit/" + id);
  }

  const updateMonitorStatus = useCallback(async (status: DATA_STATUSES) => {
    if (isNil(id)) return;
    setIsStatusChanging(true);
    await Request.put(endpoints.monitorStatusUpdate(id), { status })
      .then(async () => {
        await fetchMonitorData();
        setIsStatusChanging(false);
      })
      .catch(() => {
        setIsStatusChanging(false);
      });
  }, [id, fetchMonitorData]);

  const toggleMonitorStatus = useCallback(async () => {
    if (isNil(id)) return;
    const status = monitorStatus === DATA_STATUSES.active ? DATA_STATUSES.inactive : DATA_STATUSES.active;
    await updateMonitorStatus(status);
  }, [id, monitorStatus, updateMonitorStatus]);

  const openNotificationSettings = () => {
    setNotifySettingsDrawerOpen(true);
  }

  const closeNotificationSettings = () => {
    setNotifySettingsDrawerOpen(false);
  }

  const applyNotificationSettings = () => {
    closeNotificationSettings();
  }

  return (
    <>
      <PageContent title={data ? data?.monitor?.name : ""} backButton contentStyle={{
        paddingTop: 10,
        gap: 10,
        display: "flex",
        flexDirection: "column",
      }} footer={<FooterContent onEdit={onEditAction} onNotificationSettings={openNotificationSettings} isEditDisabled={isEditDisabled} isNotifySettingsDrawerOpen={isNotifySettingsDrawerOpen} />}>
        {data?.monitor && (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 10,
          }}>
            <div style={{
              display: "flex",
              gap: 8,
              alignItems: 'center'
            }}>
              <span>Endpoint: {`${data?.monitor?.endpoint ?? ""}${data?.monitor?.port ? ` ${data?.monitor?.endpoint}` : ""}`}</span>
              {data?.monitor?.endpoint && (
                <Button
                  onClick={() => window.open(data?.monitor?.endpoint)}
                  type="primary" style={{
                    width: 25,
                    height: 25,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}><LinkOutlined /></Button>
              )}
            </div>
            <div style={{
              display: "flex",
              gap: 3,
              alignItems: 'center'
            }}>
              <Tag color={monitorStatus === DATA_STATUSES.active ? "green" : "red"}>{monitorStatus === DATA_STATUSES.active ? 'Active' : 'Inactive'}</Tag>
              <Button
                onClick={toggleMonitorStatus}
                disabled={isStatusChanging}
                type="primary" style={{
                  width: 25,
                  height: 25,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>{isStatusChanging ? <LoadingOutlined spin /> : (monitorStatus === DATA_STATUSES.active ? <PauseOutlined /> : <PlayCircleOutlined />)}</Button>
            </div>
          </div>
        )}
        <div className="statistics-container">
          <div className="charts-container">
            {
              hasActiveIncident && (
                <div className="box-container content-error-light monitor-error-incident incident-log">
                  <div className="content">
                    <div className="error-title">
                      <h3>{incidents?.length === 1 ? 'Incident' : 'Incidents'} detected</h3>
                      {incidents?.[0]?.updatedAt && <span>{new Date(incidents?.[0]?.updatedAt)?.toLocaleDateString()}</span>}
                    </div>
                    <div className="incident-logs-container">
                      {
                        incidents?.map((x) => {
                          const region = getRegion(x.regionId);
                          return (
                            <div key={x.id} className="incident-logs-item">
                              <div className="incident-logs-title">
                                <div className="incident-logs-title">
                                  <Flags height={30} code={region?.code as FlagCode} />
                                  <span>{region?.city ?? "Unknown"}</span>
                                </div>
                                <b>{x?.lastStatusCode}</b>
                              </div>
                              {x?.lastError && <p><b>Error message:</b> {x?.lastError}</p>}
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              )
            }
            {
              hasSSL && SSLDaysLeft === 0 && (
                <div className="box-container content-error monitor-error-incident">
                  <div className="content">
                    <div className="error-title">
                      <h3>SSL Expired!</h3>
                      {data?.ssl?.updatedAt && <span>{new Date(data?.ssl?.updatedAt)?.toLocaleDateString()}</span>}
                    </div>
                    <span>Your SSL certificate has expired. Please renew it to avoid any issues.</span>
                  </div>
                </div>
              )
            }
            <div
              className="statistics-widget"
              style={{
                width: "100%"
              }}>
              <h3>Uptime</h3>
              <div style={{ marginTop: 20 }}>
                <UptimeChart data={uptimeLogsData} />
              </div>
            </div>
            <div
              className="statistics-widget"
              style={{
                width: "100%"
              }}>
              <h3>Response times</h3>
              <div style={{ marginTop: 20 }}>
                <ResponseTimeChart data={uptimeLogsData} />
              </div>
            </div>
            {/* <div
            className="statistics-widget"
            style={{
              width: "100%",
            }}>
            <h3>Uptime metrics per region</h3>
            <div style={{ marginTop: 20 }}>
              <ResponsiveContainer width="100%" height={150}>
                <LineChart data={fakeData}>
                  <XAxis dataKey="name" axisLine={false} tickLine={false} />
                  <YAxis axisLine={false} tickLine={false} />
                  <Tooltip content={<CustomTooltip />} />
                  <Line dot={false} dataKey="pv" stroke="#6d67e4" strokeWidth={2} />
                  <Line dot={false} dataKey="uv" stroke="#b71375" strokeWidth={2} />
                  <Line dot={false} dataKey="amt" stroke="#f79540" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div> */}
          </div>
          <div className="info-container">
            <div className="box-container">
              <h3>Uptime metrics</h3>
              <div className="content">
                <span>Uptime rate: <b>{data?.logs ? `${data?.logs?.uptime}%` : "N/A"}</b></span>
                <span>Last response time: <b>{data?.logs ? `${data?.logs?.lastResponseTime} ms` : "N/A"}</b></span>
                <span>Average response time: <b>{data?.logs ? `${data?.logs?.averageResponseTime} ms` : "N/A"}</b></span>
              </div>
            </div>
            <div className={classNames('box-container', 'ssl-info-box', data?.monitor && !hasSSL && 'content-error-light')}>
              <div className="ssl-header">
                <h3>SSL status</h3>
                <Button
                  type="primary"
                  disabled={isFetchingSSL}
                  onClick={fetchSSL}
                  style={{
                    width: 25,
                    height: 25,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >{isFetchingSSL ? <LoadingOutlined spin /> : <ReloadOutlined />}</Button>
              </div>
              <div className="content">
                {!hasSSL ? <span>SSL validation <u>failed</u>. Please verify your DNS records for accurate setup.</span> : (
                  <div className={classNames(data?.ssl && !hasSSL && "ssl-info-details-disabled")}>
                    <h2 style={{ color: SSLIndicatorColor }}><b>{SSLDaysLeft}</b> {SSLDaysLeft > 1 ? 'days' : "day"} left</h2>
                    <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                      <span>Subject: <b>{data?.ssl?.info?.subject ?? "Unknown"}</b></span>
                      <span>Issuer: <b>{data?.ssl?.info?.issuer ?? "Unknown"}</b></span>
                      <span>Valid from: <b>{data?.ssl?.info?.valid_from?.toString() ?? "Unknown"}</b></span>
                      <span>Valid to: <b>{data?.ssl?.info?.valid_to?.toString() ?? "Unknown"}</b></span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <HTTPBreakdownSection data={httpBreakdown} />
          </div>
        </div>
      </PageContent>
      {id && (
        <NotificationSettingsDrawer
          serviceType={ServiceTypes.monitor}
          dataId={id}
          isOpen={isNotifySettingsDrawerOpen}
          onClose={closeNotificationSettings}
          onApply={applyNotificationSettings}
        />
      )}
    </>
  );
}

export default MonitorView;
