import { Button, Input, Modal, Tag } from "antd";
import { useRef, useState } from "react";
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

type ModalTypes = {
    isOpen: boolean;
    isCreateLoading: boolean;
    onCreate: (data: any) => void;
    onClose: () => void;
}

const CreateFlowModal = ({ isOpen, onClose, onCreate, isCreateLoading }: ModalTypes) => {
    const [name, setName] = useState('');
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null);
    const [disabled, setDisabled] = useState(true);
    const [showError, setError] = useState(false);

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    const actionCloseModal = () => {
        setName('');
        onClose();
    }

    const actionCreate = () => {
        try {
            if (name?.length === 0) return setError(true);
            setError(false);
            onCreate(name);
        } catch (err) {
            console.error(err);
            setError(true);
        }
    }

    return (
        <Modal
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                >
                    Flow name
                </div>
            }
            open={isOpen}
            footer={[
                <Button key="back" type="primary" disabled={isCreateLoading} onClick={actionCloseModal}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" className="run-btn" disabled={isCreateLoading} loading={isCreateLoading} onClick={actionCreate}>
                    Create
                </Button>,
            ]}
            onCancel={actionCloseModal}
            modalRender={modal => (
                <Draggable
                    bounds={bounds}
                    disabled={disabled}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
        >
            <Input value={name} style={{ maxWidth: '100%' }} onChange={e => setName(e.target.value)} placeholder="Fintech User Flow #1" />
            {showError && <Tag color="yellow" style={{ marginTop: 10 }}>Please enter a name.</Tag>}
        </Modal>
    )
}

export default CreateFlowModal;