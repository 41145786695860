import { Link, Navigate, Route, Routes } from "react-router-dom";
import AllMonitors from "./AllMonitors";
import AllCustomers from "./AllCustomers";

export default function RootPanel() {
    return (
        <div style={{ padding: 10, }}>
            <h1>Root Panel</h1>
            <ul style={{ display: "flex", gap: 10, listStyleType: "none" }}>
                <li><Link to="/all-customers">Customers</Link></li>
                <li><Link to="/all-monitors">Monitors</Link></li>
                {/* <li><Link to="/all-flows">Flows</Link></li> */}
            </ul>
            <Routes>
                <Route path="/" element={<Navigate to="/all-customers" />} />
                <Route path="/all-customers" element={<AllCustomers />} />
                <Route path="/all-monitors" element={<AllMonitors />} />
            </Routes>
        </div>
    )
}