import { Button, Collapse, Drawer, Input, Space } from "antd"
import { useAppDispatch, useAppSelector } from "../../utils/hooks/useRedux";
import { setFlowNodeDrawer } from "../../utils/redux/features/cache";
import RequestContainer from "../HTTPRequestEditor/RequestContainer";
import { useReactFlow, useStoreApi } from "reactflow";
import { useMemo, useState } from "react";
import { CloseCircleFilled, CopyFilled, DeleteFilled, ImportOutlined } from "@ant-design/icons";
import ImportcURLModal from "./ImportcURLModal";
import { getShortId } from "../../utils/utils";

const { Panel } = Collapse;

const FlowNodeDrawer = () => {
    const { setNodes, getNode, deleteElements } = useReactFlow();
    const dispatch = useAppDispatch();
    const store = useStoreApi();
    const flowNodeDrawer = useAppSelector(state => state.cache.flowNodeDrawer);
    const flowNodeDrawerId = useAppSelector(state => state.cache.flowNodeDrawerId);
    const [isImportModalShown, setDisplayImportModal] = useState(false);
    const [flickReqContainer, setFlickReqContainer] = useState(false);

    const httpNodeData = useMemo(() => {
        if (!flowNodeDrawerId) return {};
        const node = getNode(flowNodeDrawerId);
        if (node) {
            return {
                data: node.data?.httpReq?.itemData,
                name: node.data?.name,
                status: node.data?.status,
            } || {};
        }
        return {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowNodeDrawerId, getNode, flickReqContainer]);

    const closeDrawer = () => {
        dispatch(setFlowNodeDrawer({ status: false, id: null } as never));
    }

    const deleteNode = () => {
        if (!flowNodeDrawerId) return;
        if (!window.confirm("Are you sure you want to delete this node?")) return;
        deleteElements({ nodes: [{ id: flowNodeDrawerId }] });
    }

    const onRequestDataChange = (data: any) => {
        if (!flowNodeDrawerId) return;
        const { nodeInternals } = store.getState();
        setNodes(
            Array.from(nodeInternals.values()).map((node) => {
                if (node.id === flowNodeDrawerId) {
                    node.data = {
                        ...node.data,
                        httpReq: data,
                    };
                }
                return node;
            })
        );
    }

    const updateNodeName = (name: string) => {
        if (!flowNodeDrawerId) return;
        const { nodeInternals } = store.getState();
        setNodes(
            Array.from(nodeInternals.values()).map((node) => {
                if (node.id === flowNodeDrawerId) {
                    node.data = {
                        ...node.data,
                        name,
                    };
                }
                return node;
            })
        );
    }

    const openImportModal = () => {
        setDisplayImportModal(true);
    }

    const closeImportModal = () => {
        setDisplayImportModal(false);
    }

    const duplicateNode = () => {
        if (!flowNodeDrawerId) return;
        const { nodeInternals } = store.getState();
        const node = nodeInternals.get(flowNodeDrawerId);

        if (node) {
            const nodes = Array.from(nodeInternals.values());
            const newNode = {
                ...node,
                id: getShortId(nodes),
                position: {
                    x: node.position.x + 500,
                    y: node.position.y,
                }
            };
            setNodes([...Array.from(nodeInternals.values()), newNode]);
        }
    }

    const importcURL = (request: any) => {
        if (!flowNodeDrawerId || !request) return;
        const { nodeInternals } = store.getState();
        setNodes(
            Array.from(nodeInternals.values()).map((node) => {
                if (node.id === flowNodeDrawerId) {
                    node.data = {
                        ...node.data,
                        httpReq: {
                            data: request,
                            itemData: request,
                        }
                    };
                }
                return node;
            })
        );

        setFlickReqContainer(true);
        setTimeout(() => {
            setFlickReqContainer(false);
        }, 500);
    }

    return (
        <>
            <Drawer
                title={"Configure"}
                open={flowNodeDrawer}
                onClose={closeDrawer}
                closable={false}
                mask={false}
                size="large"
                extra={
                    <Space>
                        <Button type="primary" onClick={deleteNode} danger><DeleteFilled /></Button>
                        <Button type="primary" onClick={duplicateNode}>Duplicate <CopyFilled /></Button>
                        <Button type="primary" onClick={openImportModal}>Import cURL <ImportOutlined /></Button>
                        <Button type="primary" onClick={closeDrawer}>Close <CloseCircleFilled /></Button>
                    </Space>
                }
            >
                {!flickReqContainer && flowNodeDrawerId && (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <Input
                            placeholder="Name"
                            defaultValue={httpNodeData.name}
                            onChange={e => updateNodeName(e.target.value)}
                            style={{ marginBottom: 10 }}
                        />
                        <RequestContainer variablesEnabled nodeId={flowNodeDrawerId} data={httpNodeData.data} onDataChange={onRequestDataChange} />
                    </div>
                )}
            </Drawer>
            <ImportcURLModal isOpen={isImportModalShown} onClose={closeImportModal} onImport={importcURL} />
        </>
    )
}

export default FlowNodeDrawer;