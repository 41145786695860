import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, Link, useSearchParams } from "react-router-dom";
import { setUser } from "../../utils/redux/features/common";
import { useAppDispatch } from "../../utils/hooks/useRedux";
import { Alert, Button, Divider, Form, Input, Typography, notification } from "antd";
import Request from "../../utils/request";
import endpoints from "../../utils/endpoints";
import { MailOutlined } from "@ant-design/icons";
import AuthSideComponent from "../../components/AuthSideComponent";

const isDEV = true;

function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [query] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [isMagicLinkMode, setMagicLinkMode] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [isNotVerified, setIsNotVerified] = useState(false);
  const [fromInvitation, setFromInvitation] = useState(false);
  const isMounted = useRef(false);
  const [email, setEmail] = useState<string>(location.state?.email || "");

  const toggleMagicLinkMode = () => setMagicLinkMode(prev => !prev);

  const checkTokenForAutoLogin = async (token: string) => {
    Request.get(endpoints.profile, {
      headers: {
        "x-auth-key": token
      }
    })
      .then(res => {
        dispatch(setUser({
          ...res.data.payload,
          token
        }));
        navigate("/");
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "Token is expired."
        });
      });
  }

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    const invitation = query.get("invitation");
    if (invitation) {
      setFromInvitation(true);
    }
    const tokenQuery = query.get("token");
    if (tokenQuery) {
      checkTokenForAutoLogin(tokenQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const actionLogin = async ({ email, password }: { email: string, password: string }) => {
    if (isMagicLinkMode) return requestMagicLink(email);

    setLoading(true);
    await Request.post(endpoints.login, {
      email,
      password
    })
      .then(res => {
        navigate("/");
        dispatch(setUser(res.data.payload));
      })
      .catch(err => {
        const errCode = err?.response?.data?.code;

        if (errCode !== 'Internal_Login#2') {
          setError(true);
          setErrorMsg(err?.response?.data?.message || "Whoops, make sure you've entered a valid email address!");

          if (errCode === 'Internal_Login#4') {
            setIsNotVerified(true);
          } else {
            if (isNotVerified) setIsNotVerified(false);
          }
        } else {
          setError(false);
          setErrorMsg(null);
        }
      });
    setLoading(false);
  }

  const requestMagicLink = async (emailAddress: string) => {
    setLoading(true);
    Request.post(endpoints.magicLink, {
      email: emailAddress
    })
      .then(() => {
        notification.success({
          message: "Success",
          description: "Magic link has been sent."
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const resendVerificationEmail = async () => {
    setLoadingResend(true);
    Request.post(endpoints.resendVerification(email))
      .then(() => {
        notification.success({
          message: "Success",
          description: "Verification link has been sent."
        });
        setLoadingResend(false);
      })
      .catch((err) => {
        const errCode = err?.response?.data?.code;

        if (errCode === 'Internal_ResendEmailVerification#4' || errCode === 'Internal_ResendEmailVerification#1') {
          notification.info({
            message: "Information",
            description: err?.response?.data?.message || "Something went wrong."
          });
        } else {
          notification.error({
            message: "Error",
            description: `${err?.response?.data?.message || "Something went wrong."} (${errCode})`
          });
        }

        setLoadingResend(false);
      });
  }

  return (
    <div className="auth-container">
      <AuthSideComponent />
      <div className="login-container">
        <div className="login-wrapper">
          {fromInvitation && <Alert
            description={'You have been invited to join the team. Please sign in to continue.'}
            type="info"
            style={{ marginTop: 20, marginBottom: 10 }}
          />}
          {location.state?.email && <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20, gap: 10, }}>
            <Alert
              description={<div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                  <MailOutlined style={{ fontSize: 30 }} />
                  <div>
                    Please check your email for the verification link.<br />If you didn't receive the email, please check your spam folder or click the button below to resend.
                  </div>
                </div>
              </div>}
              type="success"
            />
            <div style={{
              alignItems: 'flex-end',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <Button type="primary" loading={loadingResend} onClick={resendVerificationEmail}>Resend verification link</Button>
            </div>
          </div>}
          <Typography.Title level={3} style={{ marginBottom: 20 }}>{isMagicLinkMode ? 'Sign in with magic link ⚡️' : 'Sign in'}</Typography.Title>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ email: location.state?.email }}
            onFinish={actionLogin}
            layout="vertical"
            requiredMark={false}
          >
            <Form.Item
              name="email"
              label="E-mail:"
              help={""}
              rules={[{ required: true, message: 'Please input your e-mail.' }]}
            >
              <Input placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} type="email" className={"email-input"} />
            </Form.Item>
            {
              !isMagicLinkMode && (
                <Form.Item
                  name="password"
                  label="Password:"
                  help={""}
                  rules={[{ required: true, message: 'Please input your password.' }]}
                >
                  <Input.Password
                    placeholder="Password"
                  />
                </Form.Item>
              )
            }
            <Form.Item>
              <div style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div>
                  <Button loading={loading} type="primary" htmlType="submit">
                    {isMagicLinkMode ? 'Send magic link' : 'Sign in'}
                  </Button>
                  <p className="auth-support">If you're having trouble logging in, please contact <a href={"mailto:support@onradar.io"}>support@onradar.io</a>.</p>
                </div>
                <Link
                  className="auth-navigate-link"
                  style={{
                    alignSelf: 'center',
                    marginTop: 20
                  }}
                  to={isMagicLinkMode ? "#password" : "#magic"}
                  onClick={toggleMagicLinkMode}
                >
                  {isMagicLinkMode ? 'Sign in with password' : 'Sign in with magic link'}
                </Link>
              </div>
            </Form.Item>
          </Form>
          {(error && !isNotVerified) && <Alert
            description={errorMsg}
            type="error"
            style={{ marginTop: 20 }}
          />}
          {(error && isNotVerified) && <Alert
            description={<div style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}>
              <div>
                <p style={{ marginBottom: 0 }}>E-mail address is not verified.<br />Check your email for the verification link.</p>
              </div>
              <div>
                <Button type="primary" loading={loadingResend} onClick={resendVerificationEmail}>Resend verification link</Button>
              </div>
            </div>}
            type="warning"
            style={{ marginTop: 20 }}
          />}
          <Divider />
          <div className="tooltip-wrapper">
            <Link className="auth-navigate-link" to={"/register"}>Create a new account</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;
