import { useEffect, useMemo, useState } from "react";
import endpoints from "../utils/endpoints";
import { CheckOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Request from "../utils/request";

const FeedbackWidget = ({ feedbackVisible }: { feedbackVisible: boolean }) => {
    const [feedback, setFeedback] = useState("");
    const [isSending, setSending] = useState(false);
    const [isSent, setSent] = useState(false);
    const disableSend = useMemo(() => feedback.length === 0, [feedback])

    const clearFeedback = () => {
        setFeedback("");
        setSending(false);
        setSent(false);
    }

    const openCrispPopup = () => {
        if (typeof window?.$crisp?.push === "function") window.$crisp.push(["do", "chat:open"]);
    }

    const sendFeedback = () => {
        setSending(true);
        Request.post(endpoints.sendFeedback, { feedback })
            .then(() => {
                setSent(true);
                setFeedback("");
            })
            .catch(() => {
                setSent(false);
                setSending(false);
            })
    }

    useEffect(() => {
        if (!feedbackVisible) {
            setTimeout(() => {
                clearFeedback();
            }, 1000)
        }
    }, [feedbackVisible])

    return (
        <div className="feedback-container">
            {isSent && <div className="feedback-success"><CheckOutlined /> Thank you!</div>}
            {!isSent && <>
                <textarea disabled={isSending} onChange={e => setFeedback(e.target.value)} className="c-textarea feedback-textarea" placeholder="How can we improve?" />
                <div className="feedback-footer">
                    <span className="footer-help-text">Need help? <a href="mailto:support@onradar.io">E-mail</a> or <a href="#chat" onClick={openCrispPopup}>chat with us</a>.</span>
                    <Button type="primary" disabled={isSending || disableSend} loading={isSending} onClick={sendFeedback}>Send</Button>
                </div>
            </>}
        </div>
    )

}

export default FeedbackWidget;