const p = "api/v1/";

const endpoints = {
    login: "login",
    register: "register",
    monitor: p + "monitor",
    monitorFetchSSL: (id: string) => `${p}monitor/${id}/fetch-ssl`,
    monitorStatusUpdate: (id: string) => `${p}monitor/${id}/status`,
    monitorBreakdownStats: (id: string) => `${p}monitor/${id}/breakdown-stats`,
    flow: p + "flow",
    project: p + "project",
    ApiKey: p + "project/api-key",
    projectUsers: p + "project/users",
    sendInvitation: p + "project/invitation",
    renameProject: p + "project/rename",
    revokeUser: p + "project/revoke-user",
    location: p + "location",
    integration: p + "integration",
    integrationUpdate: (id: string) => `${p}integration/${id}`,
    integrationToggleService: p + "integration/toggle-service",
    connectSlackWithCode: p + "integration/slack",
    testAlert: p + "integration/test-alert",
    profile: p + "user/profile",
    sendFeedback: p + "user/send-feedback",
    magicLink: p + "user/request-magic-link",
    passwordChange: p + "user/password-change",
    paymentConfig: p + "payment/config",
    paymentIntent: p + "payment/intent",
    paymentCheckout: p + "payment/checkout",
    paymentBillingPortal: p + "payment/customer-portal",
    rootPanel: p + "root/",
    resendVerification: (email: string) => `${p}user/resend-email-verification/${email}`,
}

export default endpoints