import { Button, Drawer, Input, Space, notification } from "antd"
import { CloseCircleFilled, ConsoleSqlOutlined, InfoCircleOutlined, LoadingOutlined, SaveFilled, SlackOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import Request from "../../utils/request";
import endpoints from "../../utils/endpoints";
import { AlertChannels, IntegrationCredentialsInterface } from "../../utils/types";
import { isDEV } from "../../utils/config";
import { openPopupCenter } from "../../utils/utils";

type NotificationAlertSettingsDrawerProps = {
    channelId: number | string | null,
    data: IntegrationCredentialsInterface | null | undefined;
    isCreate: boolean,
    onApply: () => void;
    onClose: () => void;
    refreshIntegrations: () => void;
}

const SlackDrawer = ({ channelId, data, isCreate, onClose, refreshIntegrations }: NotificationAlertSettingsDrawerProps) => {
    const isOpen = useMemo(() => channelId === AlertChannels.slack, [channelId]);
    const [preData, setPreData] = useState<IntegrationCredentialsInterface | null>(null);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [name, setName] = useState<string>("");
    const [token, setToken] = useState<string>("");
    const integrationData = useMemo(() => preData || data, [data, preData]);

    const resetForm = () => {
        setTimeout(() => {
            setName("");
            setToken("");
            setPreData(null);
        }, 500)
    }

    const closeDrawer = () => {
        onClose();
        resetForm();
    }

    useEffect(() => {
        if (channelId === null) resetForm();
    }, [channelId]);

    useEffect(() => {
        if (integrationData) {
            if (!name?.length) setName(integrationData.name);
            if (!token?.length) setToken(integrationData.token as string);
        }
    }, [integrationData, name?.length, token?.length, isCreate, isOpen])

    const applyChanges = async () => {
        if (!name?.length) return;
        setSaveLoading(true);
        if (integrationData?.integrationId) {
            // Update integration
            await Request.put(endpoints.integrationUpdate(integrationData.integrationId), {
                name,
                token
            })
                .then(res => {
                    if (res.data?.success) {
                        notification.success({
                            message: "Successfully updated."
                        })
                    }
                    setSaveLoading(false);
                })
                .catch(() => {
                    setSaveLoading(false);
                });
        } else {
            // Create new integration
            await Request.post(endpoints.integration, {
                name,
                channel: channelId,
                token
            })
                .then(res => {
                    if (res.data?.success) {
                        setPreData(res.data.payload);
                        notification.success({
                            message: "Successfully created."
                        })
                    }
                    setSaveLoading(false);
                })
                .catch(() => {
                    setSaveLoading(false);
                });
        }
        refreshIntegrations();
    }

    const openSlackPopup = () => {
        setIsDataLoading(true);
        const clientId = '6956480916741.6972510455985';
        const redirectUri = encodeURIComponent(isDEV ? 'https://2579-188-227-218-98.ngrok-free.app/retriever' : 'https://app.onradar.io/retriever');
        const scope = encodeURIComponent('incoming-webhook');
        const slackUrl = `https://slack.com/oauth/v2/authorize?client_id=${clientId}&scope=${scope}&user_scope=&redirect_uri=${redirectUri}&state=&granular_bot_scope=1&single_channel=0&install_redirect=&tracked=1&team=`;
        const popup = openPopupCenter(slackUrl, 'popup', 600, 600);

        const interval = setInterval(() => {
            if (popup?.closed) {
                closeSlackConnectionSession();
            }
        }, 1000);

        const slackCodeEventListener = async (event: MessageEvent<any>) => {
            if (event.origin === window.location.origin) {
                if (event?.data && event?.data?.includes('onradar-slack-oauth-callback')) {
                    const oAuthToken = event.data.split("@----@")[1];
                    if (!oAuthToken || oAuthToken === "null") {
                        closeSlackConnectionSession();
                        return notification.warning({
                            message: "Slack integration connection interrupted."
                        });
                    }

                    setIsDataLoading(true);
                    await Request.post(endpoints.connectSlackWithCode, {
                        integrationId: integrationData?.integrationId,
                        code: oAuthToken
                    })
                        .then(res => {
                            if (res.data?.success === true) {
                                setPreData(res.data?.payload);
                                closeSlackConnectionSession();
                                notification.success({
                                    message: "Slack integration connected."
                                })
                            }
                            setIsDataLoading(false);
                        })
                        .catch(() => {
                            closeSlackConnectionSession();
                            setIsDataLoading(false);
                        });
                    refreshIntegrations();
                }
            }
        };

        window.addEventListener('message', slackCodeEventListener);

        const closeSlackConnectionSession = () => {
            setIsDataLoading(false);
            clearInterval(interval);
            window.removeEventListener('message', slackCodeEventListener);
        }
    }

    return (
        <Drawer
            title={<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <span>{integrationData?.integrationId ? "Edit" : "Connect"} Slack integration</span>
                {isDataLoading && <LoadingOutlined spin />}
            </div>}
            open={isOpen}
            size="large"
            onClose={closeDrawer}
            closable={false}
            extra={
                <Space>
                    <Button type="primary" disabled={isSaveLoading || isDataLoading} onClick={closeDrawer}>Close <CloseCircleFilled /></Button>
                    <Button className="run-btn" loading={isSaveLoading} disabled={isSaveLoading || !name?.length} type="primary" onClick={applyChanges}>Save <SaveFilled /></Button>
                </Space>
            }
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10
                }}
            >
                <span>Name</span>
                <Input
                    type="text"
                    placeholder="Slack #channel"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                {(integrationData?.integrationId && !integrationData?.meta?.slackTeamName) ? (
                    <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                        <span>Connect</span>
                        <Button type="primary" loading={isDataLoading} disabled={isDataLoading} onClick={openSlackPopup}><SlackOutlined /> Add to Slack</Button>
                        <p className="integration-instructions">
                            <InfoCircleOutlined style={{ marginRight: 5 }} /> To connect your Slack channel, click on the <b><SlackOutlined /> Add to Slack</b> button above. A new window will open where you can select the channel you want to connect. After that, it will close automatically and you will see a success message here.
                        </p>
                    </div>
                ) : (integrationData?.integrationId && integrationData?.meta?.slackTeamName) ? (
                    <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                        <span>Connected <SlackOutlined /> workspace: <b>{integrationData?.meta?.slackTeamName}</b></span>
                        {integrationData?.meta?.slackChannelName && <span>Channel: <b>{integrationData?.meta?.slackChannelName}</b></span>}
                    </div>
                ) : <></>}
            </div>
        </Drawer>
    )
}

export default SlackDrawer;