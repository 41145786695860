import { Radio, RadioChangeEvent } from "antd";
import { useEffect, useState } from "react";
import KeyValueTable from "../KeyValueTable";
import Editor from "@monaco-editor/react";
import { IHTTPRequestBodyData, IHTTPRequestDataTypes } from "../../utils/types";

const BodyTab = ({ onDataChange, defaultData }: { onDataChange?: (data: any) => void, defaultData: IHTTPRequestBodyData | undefined }) => {
    const [data, setData] = useState<IHTTPRequestBodyData | undefined>(defaultData || {
        dataType: IHTTPRequestDataTypes.NONE,
        form: undefined,
        JSON: undefined,
        XML: undefined,
        raw: undefined
    });

    const onChange = (e: RadioChangeEvent) => {
        setData((prev: any) => ({
            ...prev,
            dataType: e.target.value
        }))
    }

    useEffect(() => {
        if (onDataChange) onDataChange(data);
        // eslint-disable-next-line
    }, [data]);

    return (<div>
        <Radio.Group onChange={onChange} value={data?.dataType}>
            <Radio value={IHTTPRequestDataTypes.NONE}>None</Radio>
            <Radio value={IHTTPRequestDataTypes.FORM}>Form</Radio>
            <Radio value={IHTTPRequestDataTypes.JSON}>JSON</Radio>
            <Radio value={IHTTPRequestDataTypes.XML}>XML</Radio>
            {/* <Radio value={IHTTPRequestDataTypes.RAW}>Raw</Radio> */}
        </Radio.Group>
        <div className="mt-10">
            {
                data?.dataType === IHTTPRequestDataTypes.FORM && <KeyValueTable
                    defaultData={defaultData?.form}
                    onDataChange={value => setData((prev: any) => ({ ...prev, form: value }))}
                />
            }
            {
                data?.dataType === IHTTPRequestDataTypes.JSON && <Editor
                    height="150px"
                    defaultLanguage="json"
                    theme="vs-dark"
                    defaultValue={"{}"}
                    value={data?.JSON}
                    onChange={value => setData((prev: any) => ({ ...prev, JSON: value }))}
                />
            }
            {
                data?.dataType === IHTTPRequestDataTypes.XML && <Editor
                    height="150px"
                    defaultLanguage="xml"
                    theme="vs-dark"
                    defaultValue={`<?xml version="1.0" ?>`}
                    value={data?.XML}
                    onChange={value => setData((prev: any) => ({ ...prev, XML: value }))}
                />
            }
            {/* {
                data?.dataType === IHTTPRequestDataTypes.RAW && <Editor
                    height="150px"
                    defaultLanguage="text"
                    theme="vs-dark"
                    defaultValue={""}
                    value={data?.raw}
                    onChange={value => setData((prev: any) => ({ ...prev, raw: value }))}
                />
            } */}
        </div>
    </div>)
}

export default BodyTab;