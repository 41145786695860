import { Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Crisp } from "crisp-sdk-web";
import Sidebar from "../components/Sidebar";
import { useAppDispatch, useAppSelector } from "../utils/hooks/useRedux";
import Monitors from "./private/Monitors/Monitors";
import { NoPage, AlreadyAuth } from "./NoPage";
import Login from "./public/Login";
import Flows from "./private/Flows/Flows";
// import FlowsCreate from "./private/Flows/FlowsCreate";
import MonitorsManage from "./private/Monitors/MonitorsManage";
import Register from "./public/Register";
import Settings from "./private/Settings";
import PulseDot from "react-pulse-dot";
import MonitorView from "./private/Monitors/MonitorView";
import classNames from "classnames";
import useWindowSize from "../utils/hooks/useWindowSize";
import { useEffect } from "react";
import useIsMounted from "../utils/hooks/useIsMounted";
// import Home from "./private/Home";
import endpoints from "../utils/endpoints";
import { setLocations, updateUser } from "../utils/redux/features/common";
import Request from "../utils/request";
import Billing from "./private/Billing";
import FlowNodeEditor from "./private/Flows/FlowNodeEditor";
import { CRISP_KEY, DISABLED_EXPAND_SIDEBAR_ROUTES } from "../utils/config";
import { setSidebarExpand } from "../utils/redux/features/ui-config";
import Account from "./private/Account";
import Integrations from "./private/Integrations";
import { setCacheLinkedIntegrations } from "../utils/redux/features/cache";
import { getCompanyNameFromEmail, getMainDomainName } from "../utils/utils";
import UpgradePlanModal from "../components/UpgradePlanModal";
import RootPanel from "./private/RootPanel";
// import Onboarding from "../components/Onboarding/Onboarding";

function Router() {
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();
  const commonState = useAppSelector(state => state.common);
  const cacheState = useAppSelector(state => state.cache);
  const uiconfigState = useAppSelector(state => state.uiconfig);
  const contentLoading = cacheState.contentLoading;
  const hasSidebarExpanded = uiconfigState.sidebarExpanded;
  const disableAutoSidebarExpand = uiconfigState.disableAutoSidebarExpand;
  const hasAuth = commonState?.user?.token;
  const isRootPanelAllowed = commonState?.user?.isRootPanelAllowed;
  const size = useWindowSize();

  useEffect(() => {
    if (isMounted()) {
      if (!disableAutoSidebarExpand && DISABLED_EXPAND_SIDEBAR_ROUTES.indexOf(window.location.pathname) === -1) {
        if ((size?.width ?? 0) < 768) {
          dispatch(setSidebarExpand(true as never));
        } else {
          dispatch(setSidebarExpand(false as never));
        }
      }
    }
  }, [isMounted, dispatch, size, disableAutoSidebarExpand])

  useEffect(() => {
    console.log('commonState?.user', commonState?.user, isRootPanelAllowed)
    try {
      if (hasAuth && commonState?.user) {
        try {
          Crisp.configure(CRISP_KEY);

          setTimeout(() => {
            if (Crisp.isCrispInjected() && commonState?.user) {
              Crisp.user.setEmail(commonState?.user?.email);
              const companyName = getMainDomainName(commonState?.user?.website) || getCompanyNameFromEmail(commonState?.user?.email);
              if (companyName) {
                Crisp.user.setCompany(companyName, {
                  url: commonState?.user?.website,
                });
              }
            }
          }, 2000);
        } catch (error) { }

        if (typeof window?.$crisp?.is === "function" && !Crisp.chat.isVisible()) {
          Crisp.chat.show();
        }

        if (Sentry.isInitialized()) {
          Sentry.setUser({
            email: commonState?.user?.email,
          });
        }
      } else {
        try {
          if (typeof window?.$crisp?.is === "function" && Crisp.isCrispInjected()) {
            if (Crisp.chat.isVisible()) Crisp.chat.hide();
          }
        } catch (error) { }
      }
    } catch (error) { }
  }, [hasAuth, commonState?.user]);

  useEffect(() => {
    if (isMounted()) {
      if (hasAuth) {
        Request.get(endpoints.location).then(locRes => {
          dispatch(setLocations(locRes?.data?.payload) as never);
        });

        Request.get(endpoints.profile).then(res => {
          if (res.data?.payload) dispatch(updateUser(res.data?.payload))
        });

        Request.get(endpoints.integration).then(res => {
          if (res.data?.payload) dispatch(setCacheLinkedIntegrations(res.data?.payload))
        });
      }
    }
  }, [isMounted, hasAuth, dispatch])

  if (hasAuth && typeof isRootPanelAllowed === "boolean" && isRootPanelAllowed === true) {
    return <RootPanel />
  }

  return (
    <>
      {/* {hasAuth && <div className="announcement-banner">
        Public beta
      </div>} */}
      <div className="container">
        {hasAuth && <>
          <Sidebar />
          {/* <Onboarding /> */}
        </>}
        <div className={classNames("main-content", !hasAuth && "main-content-wide", hasSidebarExpanded && "main-content-collapsed")}>
          {contentLoading === true && (
            <div className="loader-container">
              <PulseDot style={{ fontSize: '2em' }} />
              Loading...
            </div>
          )}
          <Routes>
            {hasAuth ? <>
              <Route path="/" element={<Monitors />} />
              <Route path="/monitors" element={<Monitors />} />
              <Route path="/monitors/create" element={<MonitorsManage />} />
              <Route path="/monitors/view/edit/:id" element={<MonitorsManage />} />
              <Route path="/monitors/view/:id" element={<MonitorView />} />
              <Route path="/flows" element={<Flows />} />
              <Route path="/flows/create" element={<FlowNodeEditor />} />
              <Route path="/flows/view/:id" element={<FlowNodeEditor />} />
              {/* <Route path="/flows/create" element={<FlowsCreate />} /> */}
              {/* <Route path="/flows/node-editor" element={<FlowNodeEditor />} /> */}
              <Route path="/account" element={<Account />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/integrations/:channel" element={<Integrations />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/login" element={<AlreadyAuth />} />
              <Route path="/register" element={<AlreadyAuth />} />
              <Route path="/404" element={<NoPage />} />
              <Route path="*" element={<NoPage />} />
            </> : <>
              <Route path="/login" element={<Login />} />
              {/* <Route path="/register" element={isDEV ? <Register /> : <Redirect to="/login" />} /> */}
              <Route path="/register" element={<Register />} />
              <Route path="*" element={<Login />} />
            </>}
          </Routes>
        </div>
      </div>
      <UpgradePlanModal />
    </>
  );
}

export default Router;