import { memo, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CompanyLogoIcon from "./CompanyLogoIcon";
import FreeTrialBar from "./FreeTrialBar";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../utils/hooks/useRedux";
import { resetCommon } from "../utils/redux/features/common";
import { Divider, Tag } from "antd";
import {
  AppstoreAddOutlined,
  BranchesOutlined,
  CreditCardOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  LogoutOutlined,
  MonitorOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { isDEV } from "../utils/config";
import ProjectSelectbox from "./ProjectSelectbox";
import { resetConfig, setDisableAutoSidebarExpand, setSidebarExpand } from "../utils/redux/features/ui-config";
import { resetCache } from "../utils/redux/features/cache";
import { useOnClickOutside } from "../utils/hooks/useOnClickOutside";
import useWindowSize from "../utils/hooks/useWindowSize";

const pageDisabled = isDEV;

function Sidebar() {
  const ref = useRef(null)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(state => state.common.user);
  const uiconfigState = useAppSelector(state => state.uiconfig);
  const hasSidebarExpanded = uiconfigState.sidebarExpanded;
  const disableAutoSidebarExpand = uiconfigState.disableAutoSidebarExpand;
  const location = useLocation();
  const size = useWindowSize();
  const isMobile = (size?.width ?? 0) < 768;

  const isActive = (path: string) => (path === "/" ? (location.pathname === path) : (location.pathname.indexOf(path) !== -1)) ? "nav-item-active" : "";

  const actionLogout = () => {
    dispatch(resetCommon());
    dispatch(resetCache());
    dispatch(resetConfig());
    setTimeout(() => navigate("/login"), 500)
  }

  const toggleSidebar = () => {
    if (!disableAutoSidebarExpand) dispatch(setDisableAutoSidebarExpand(true as never));
    dispatch(setSidebarExpand(!hasSidebarExpanded as never));
  }

  const closeSidebarOnMobileOutsideClick = () => {
    if (disableAutoSidebarExpand && !hasSidebarExpanded && isMobile) {
      dispatch(setSidebarExpand(true as never));
    }
  }

  useOnClickOutside(ref, closeSidebarOnMobileOutsideClick)

  return (
    <div className={classNames("sidebar-nav", hasSidebarExpanded && "sidebar-nav-collapsed")} ref={ref}>
      <CompanyLogoIcon
        height='30'
        width='30'
        color='white'
        style={{ margin: "10px 0 20px 0" }}
        svgStyle={{ cursor: "pointer" }}
        onClick={() => navigate("/")}
      />
      <Link
        to={"#"}
        className="menu-toggle-button"
        style={hasSidebarExpanded ? {
          right: "unset",
          position: "relative",
          top: "unset",
        } : undefined}
        onClick={toggleSidebar}>
        {hasSidebarExpanded ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
      </Link>
      <ProjectSelectbox hasSidebarExpanded={hasSidebarExpanded} />
      <Divider className="nav-divider" />
      <Link aria-disabled to={"/monitors"} className={classNames(isActive("/monitors") || isActive("/"))}><div className="title"><MonitorOutlined /> {!hasSidebarExpanded && <span>Monitors</span>}</div></Link>
      <Link aria-disabled to={"/flows"} className={classNames(isActive("/flows"))}><div className="title"><BranchesOutlined /> {!hasSidebarExpanded && <span>Flows</span>}</div></Link>
      <Link aria-disabled to={"/integrations"} className={classNames(isActive("/integrations"))}><div className="title"><AppstoreAddOutlined /> {!hasSidebarExpanded && <span>Integrations</span>}</div></Link>
      <Link to={"/settings"} className={isActive("/settings")}><div className="title"><SettingOutlined /> {!hasSidebarExpanded && <span>Project settings</span>}</div></Link>
      <Divider className="nav-divider" />
      <Link to={"/account"} className={isActive("/account")}><div className="title"><UserOutlined /> {!hasSidebarExpanded && <span>Account</span>}</div></Link>
      <Link to={"/billing"} className={classNames(isActive("/billing"))}><div className="title"><CreditCardOutlined /> {!hasSidebarExpanded && <span>Billing</span>}</div></Link>
      {userData?.trial && <FreeTrialBar hideLabel={hasSidebarExpanded} />}
      <Divider className="nav-divider" />
      <Link to={"#"} onClick={actionLogout}><div className="title"><LogoutOutlined />  {!hasSidebarExpanded && <span>Logout</span>}</div></Link>
    </div >
  );
}

export default memo(Sidebar);