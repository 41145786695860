import { Button, Modal, Tag } from "antd";
import { useRef, useState } from "react";
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import { useAppDispatch, useAppSelector } from "../utils/hooks/useRedux";
import { setUpgradeModalShow } from "../utils/redux/features/cache";

const UpgradePlanModal = () => {
    const dispatch = useAppDispatch();
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null);
    const [disabled, setDisabled] = useState(true);
    const cacheState = useAppSelector(state => state.cache);
    const isUpgradeModalShown = cacheState.isUpgradeModalShown;
    const isUpgradeModalError = cacheState.isUpgradeModalError;

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    const actionCloseModal = () => {
        dispatch(setUpgradeModalShow({ show: false, error: null } as never));
        setDisabled(true);
        setBounds({ left: 0, top: 0, bottom: 0, right: 0 });
    }

    const actionSubmit = () => {
        window.open('/billing', '_blank');
    }

    const planComparison = () => {
        window.open('https://onradar.io/pricing', '_blank');
    }

    return (
        <Modal
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                >
                    Upgrade
                </div>
            }
            open={isUpgradeModalShown}
            footer={[
                <Button key="back" type="primary" onClick={() => actionCloseModal()}>
                    Dismiss
                </Button>,
                <Button key="submit" type="primary" onClick={planComparison}>
                    Plans
                </Button>,
                <Button key="submit" type="primary" className="run-btn" onClick={actionSubmit}>
                    Upgrade
                </Button>,
            ]}
            onCancel={() => actionCloseModal()}
            modalRender={modal => (
                <Draggable
                    bounds={bounds}
                    disabled={disabled}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
        >
            <p>Unfortunately you hit the limit of your current plan. Please upgrade your plan to unlock more features.</p>
            <p>To continue from where you left, you need to upgrade. If you leave the page (not this pop-up), you will lose your unsaved changes.</p>
            <Tag style={{ marginTop: 10, maxWidth: 600, whiteSpace: "normal" }} color="orange">{isUpgradeModalError}</Tag>
        </Modal>
    )
}

export default UpgradePlanModal;