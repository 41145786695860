import { HTTPBreakdownData, LocationInterface } from "../utils/types";
import Flags, { FlagCode } from "../assets/images/flags"
import { useAppSelector } from "../utils/hooks/useRedux";
import { Tooltip } from "antd";
import { useMemo } from "react";
import _ from "lodash";
import { calculatePercentages } from "../utils/utils";
import { TooltipPlacement } from "antd/es/tooltip";

type IHTTPBreakdownSectionTypes = {
    data: HTTPBreakdownData[] | null;
}

type IItemTypes = {
    item: HTTPBreakdownData | undefined | null;
    locationInfo: LocationInterface | undefined;
}

type IProgressBarTypes = {
    data: HTTPBreakdownData;
    hasData: boolean;
}

const TooltipWrapper = (props: { children: any, title: any, displayTooltip?: boolean, showArrow?: boolean, placement?: TooltipPlacement }) => props?.displayTooltip === false ? props.children : <Tooltip showArrow={props?.showArrow} title={props.title as never} placement={props?.placement}>{props.children}</Tooltip>

const ProgressBar = ({ data, hasData }: IProgressBarTypes) => {
    const percentages = useMemo(() => calculatePercentages(data), [data]);

    return <TooltipWrapper displayTooltip={hasData} title={<div className="progress-bar-tooltip">
        <div className="progress-bar-tooltip-item"><div className="pbti-contr"><div className="progress-bar-segment-color progress-bar-segment-dns" /> DNS <span>{data.averageDNS?.toFixed(2)} ms</span></div> {percentages.averageDNS?.toFixed(0)}%</div>
        <div className="progress-bar-tooltip-item"><div className="pbti-contr"><div className="progress-bar-segment-color progress-bar-segment-download" /> Download <span>{data.averageDownload?.toFixed(2)} ms</span></div> {percentages.averageDownload?.toFixed(0)}%</div>
        <div className="progress-bar-tooltip-item"><div className="pbti-contr"><div className="progress-bar-segment-color progress-bar-segment-first-byte" /> First Byte <span>{data.averageFirstByte?.toFixed(2)} ms</span></div> {percentages.averageFirstByte?.toFixed(0)}%</div>
        <div className="progress-bar-tooltip-item"><div className="pbti-contr"><div className="progress-bar-segment-color progress-bar-segment-tcp" /> TCP <span>{data.averageTCP?.toFixed(2)} ms</span></div> {percentages.averageTCP?.toFixed(0)}%</div>
        <div className="progress-bar-tooltip-item"><div className="pbti-contr"><div className="progress-bar-segment-color progress-bar-segment-wait" /> Wait <span>{data.averageWait?.toFixed(2)} ms</span></div> {percentages.averageWait?.toFixed(0)}%</div>
    </div>} showArrow={false} placement="left">
        {hasData ? (
            <div className="progress-bar-container">
                <div className="progress-bar-item progress-bar-segment-dns" style={{ width: `${percentages.averageDNS}%` }} />
                <div className="progress-bar-item progress-bar-segment-download" style={{ width: `${percentages.averageDownload}%` }} />
                <div className="progress-bar-item progress-bar-segment-first-byte" style={{ width: `${percentages.averageFirstByte}%` }} />
                <div className="progress-bar-item progress-bar-segment-tcp" style={{ width: `${percentages.averageTCP}%` }} />
                <div className="progress-bar-item progress-bar-segment-wait" style={{ width: `${percentages.averageWait}%` }} />
            </div>
        ) : (
            <div className="progress-bar-container">
                <div className="progress-bar-item progress-bar-segment-empty" style={{ width: "100%" }}>No data</div>
            </div>
        )}
    </TooltipWrapper>
}

const Item = ({ item, locationInfo }: IItemTypes) => {
    const pureProgressBarData = _.omit(item, ['regionId', 'averageTotal']);
    const hasData = Object.values(pureProgressBarData).length !== 0 && Object.values(pureProgressBarData).some(x => x !== null && x !== undefined);

    return <div className="item">
        <div className="item-info">
            <TooltipWrapper title={`${locationInfo?.name}, ${locationInfo?.city}`} showArrow={false}>
                <div className="location-info">
                    <div className="flag"><Flags code={locationInfo?.code as FlagCode} /></div>
                    <div className="country-code">{locationInfo?.shortCity}</div>
                </div>
            </TooltipWrapper>
            <TooltipWrapper displayTooltip={hasData} title={"Total latency"} showArrow={false}>
                <div className="total-ms">{!_.isNil(item?.averageTotal) ? `${item?.averageTotal?.toFixed(0)} ms` : ""}</div>
            </TooltipWrapper>
        </div>
        <div className="progress-bar">
            <ProgressBar data={pureProgressBarData as never} hasData={hasData} />
        </div>
    </div>
}

const HTTPBreakdownSection = ({ data }: IHTTPBreakdownSectionTypes) => {
    const locations = useAppSelector(state => state.common.locations);

    const refinedData = useMemo(() => {
        if (!locations) return [];
        let refinedD = [];
        for (const x in locations) {
            if (locations[x].unavailable === true || locations[x].enabled === false) {
                continue;
            }
            const findRealData = data?.find(r => r.regionId === locations[x].regionId);
            if (findRealData) {
                refinedD.push({
                    location: locations[x],
                    insights: findRealData ?? null
                })
            } else {
                refinedD.push({
                    location: locations[x],
                    insights: null
                })
            }
        }
        return _.sortBy(refinedD, (a, b) => a?.insights);
    }, [data, locations]);

    return <div className="box-container http-breakdown-container">
        <h3>Request breakdown</h3>
        <div className="content">
            <div className="segments">
                <div className="segment-item"><div className="progress-bar-segment-color progress-bar-segment-dns" /> DNS</div>
                <div className="segment-item"><div className="progress-bar-segment-color progress-bar-segment-download" /> Download</div>
                <div className="segment-item"><div className="progress-bar-segment-color progress-bar-segment-first-byte" /> First Byte</div>
                <div className="segment-item"><div className="progress-bar-segment-color progress-bar-segment-tcp" /> TCP</div>
                <div className="segment-item"><div className="progress-bar-segment-color progress-bar-segment-wait" /> Wait</div>
            </div>
            <div className="list">
                {refinedData.map((item, index) => {
                    return <Item key={index} locationInfo={item.location} item={item.insights} />
                })}
            </div>
        </div>
    </div>
}

export default HTTPBreakdownSection;