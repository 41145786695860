import parse from "@bany/curl-to-json";
import qs from "qs";
import { Button, Modal, Tag } from "antd";
import { useRef, useState } from "react";
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import { IHTTPRequestData, IKeyValueData } from "../../utils/types";
import { extractUrlFromCurl, isValidJSON } from "../../utils/utils";
import { isObject, isString } from "lodash";

type ImportcURLModalTypes = {
    isOpen: boolean;
    onImport: (data: any) => void;
    onClose: () => void;
}

type cURLImportResType = {
    data?: any
    form?: any
    header?: any
    method: string
    location: string
    url?: string
}

const ImportcURLModal = ({ isOpen, onClose, onImport }: ImportcURLModalTypes) => {
    const [cURLText, setcURLText] = useState('');
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null);
    const [disabled, setDisabled] = useState(true);
    const [showError, setError] = useState(false);

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    const actionCloseModal = () => {
        setcURLText('');
        setDisabled(true);
        setBounds({ left: 0, top: 0, bottom: 0, right: 0 });
        setError(false);
        onClose();
    }

    const actionImportURL = () => {
        try {
            const request = parse(cURLText) as unknown as cURLImportResType;
            const extractedURL = extractUrlFromCurl(cURLText);

            if (!request) return setError(true);
            if (extractedURL === null) return setError(true);

            const refinedData: IHTTPRequestData = {
                body: {
                    dataType: 1,
                },
                URL: (isString(request.url) ? request.url : (request as any).location) || '',
                method: request.method as string,
            }

            if (Object.keys(request?.header as never)?.length) {
                const headers = [];
                let lastId = 0;
                for (let key in request.header) {
                    headers.push({ data_key: key, data_value: request.header[key], id: lastId++ });
                }
                headers.push({ data_key: '', data_value: '', id: lastId++ });
                refinedData.headers = headers;
            }

            if (isValidJSON(request?.data) && refinedData.body) {
                refinedData.body.JSON = JSON.stringify(request.data);
                refinedData.body.dataType = 3;
            }

            if (extractedURL) {
                const parsedQS = qs.parse(extractedURL?.split('?')?.[1]);
                if (isObject(parsedQS)) {
                    const params = [];
                    let lastId = 0;
                    for (let key in parsedQS) {
                        params.push({ data_key: key, data_value: parsedQS[key], id: lastId++ } as IKeyValueData);
                    }
                    params.push({ data_key: '', data_value: '', id: lastId++ });
                    refinedData.params = params;
                }
            }

            setError(false);
            onImport(refinedData);
            actionCloseModal();
        } catch (err) {
            console.error(err);
            setError(true);
        }
    }

    return (
        <Modal
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                >
                    Import cURL
                </div>
            }
            open={isOpen}
            footer={[
                <Button key="back" type="primary" onClick={actionCloseModal}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={actionImportURL}>
                    Import
                </Button>,
            ]}
            onCancel={actionCloseModal}
            modalRender={modal => (
                <Draggable
                    bounds={bounds}
                    disabled={disabled}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
        >
            <textarea className="c-textarea curl-textarea" placeholder="Paste your cURL here" value={cURLText} onChange={e => setcURLText(e.target.value)} />
            {showError && <Tag color="red" style={{ marginTop: 15 }}>Please ensure that the URL includes either <u>http://</u> or <u>https://</u>.</Tag>}
        </Modal>
    )
}

export default ImportcURLModal;