import PageContent from "../../components/PageContent";
import { useAppSelector } from "../../utils/hooks/useRedux";
import { useMemo, useState } from "react";
import endpoints from "../../utils/endpoints";
import isNil from "lodash/isNil";
import Request from "../../utils/request";
import Toggle from 'react-toggle';
import PricingCard from "../../components/PricingCard";
import { PlanType, plans } from "../../utils/pricingData";
import { Button, Tag } from "antd";
import { calculateAnnualDiscountedPrice } from "../../utils/utils";
import { PLANS_BY_ID } from "../../utils/types";
import { CreditCardOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";

function Billing() {
  const [query] = useSearchParams();
  const userData = useAppSelector(state => state.common.user);
  const currentPlan = useMemo(() => plans.find(p => p.id === userData?.plan), [userData]);
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [isPortalLoading, setIsPortalLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanType | undefined>();
  const [monthly, setMonthly] = useState(true);

  const createPortalSession = async () => {
    setIsPortalLoading(true);
    return Request.post(endpoints.paymentBillingPortal)
      .then((res: any) => {
        setIsPortalLoading(false);
        const portalSessionLink = res?.data?.payload;
        if (!isNil(portalSessionLink)) {
          window.open(portalSessionLink, '_blank');
        }
      })
      .catch(() => {
        setIsPortalLoading(false);
      });
  }

  const createPaymentCheckout = async () => {
    setIsPaymentLoading(true);
    return Request.post(endpoints.paymentCheckout, {
      planId: selectedPlan?.id,
      isAnnual: !monthly,
    })
      .then((res: any) => {
        setIsPaymentLoading(false);
        const checkoutSession = res?.data?.payload;
        if (!isNil(checkoutSession)) {
          window.location.href = checkoutSession.url;
        }
      })
      .catch(() => {
        setIsPaymentLoading(false);
      });
  }

  const onClickPlan = (plan: PlanType) => {
    setSelectedPlan(plan)
  }

  return (
    <PageContent title="Billing">
      <div>
        {
          query.get('payment') === 'success' && (
            <Tag color="success" style={{ marginRight: 0, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              Payment successful. Your plan has been updated.
            </Tag>
          )
        }
        {
          query.get('payment') === 'fail' && (
            <Tag color="error" style={{ marginRight: 0, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              Payment has been cancelled.
            </Tag>
          )
        }
      </div>
      {
        userData?.isProjectOwner === true && (
          <div className="settings-container" style={{ marginBottom: 20 }}>
            <div className="settings-item">
              <h2 className="settings-label">Billing portal</h2>
              <div className="settings-content">
                <div className="settings-content-child">
                  <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
                    <label>
                      Manage your subscription, view invoices, and update your payment method.
                    </label>
                    <div style={{ fontSize: 12 }}>
                      {
                        (userData?.plan !== PLANS_BY_ID.free && userData?.subscription?.meta && (
                          <div>
                            Your subscription will automatically renew on {new Date(userData?.subscription?.meta?.endDate).toLocaleDateString()}.
                          </div>
                        ))
                      }
                    </div>
                    <div>
                      <Button type="primary" loading={isPortalLoading} disabled={isPortalLoading} onClick={createPortalSession}><CreditCardOutlined /> Access billing portal</Button>
                    </div>
                    {(userData?.isProjectOwner === true && userData?.plan !== PLANS_BY_ID.free) && (
                      <div>
                        <Tag color="orange" style={{ marginRight: 0, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                          Please use the billing portal to manage your subscription.
                        </Tag>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className="billing-container">
        {/* <span style={{ fontSize: 20, alignSelf: 'center' }}>Choose your plan</span> */}
        <div className="plan-cards-container">
          {plans.filter(p => !p.notShowInCard).map((plan, i) => {
            return <PricingCard
              key={i}
              isMonthly={monthly}
              isCurrent={currentPlan?.id === plan.id}
              isSelected={selectedPlan?.id === plan.id}
              actionMode={currentPlan?.id === PLANS_BY_ID.free && userData?.isProjectOwner === true}
              onClickPlan={onClickPlan}
              plan={plan}
            />
          })}
        </div>
        {(currentPlan?.id === PLANS_BY_ID.free && userData?.isProjectOwner === true) && (
          <>
            <span style={{ fontSize: 20, alignSelf: 'center' }}>Select a billing period</span>
            <div className="billing-period-container">
              <div className='pricing-toggle'>
                <div className={`period-name ${monthly ? "period-name-selected" : ""}`} onClick={() => setMonthly(true)}>Monthly</div>
                <Toggle
                  icons={false}
                  checked={!monthly}
                  onChange={() => setMonthly(prev => !prev)}
                />
                <div className={`period-name ${!monthly ? "period-name-selected" : ""}`} onClick={() => setMonthly(false)}>Annual <span>(save 20%)</span></div>
              </div>
              {(selectedPlan && currentPlan?.id !== selectedPlan?.id) && <div className="upgrade-container">
                <div>
                  <h3>${monthly ? selectedPlan?.price : calculateAnnualDiscountedPrice(selectedPlan?.price)} /{monthly ? 'monthly' : 'annual'}</h3>
                  <Button type="primary" loading={isPaymentLoading} onClick={createPaymentCheckout}>Upgrade</Button>
                </div>
              </div>}
            </div>
          </>)}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyItems: 'center',
        }}>
          {userData?.isProjectOwner === false && (
            <div>
              <Tag color="orange" style={{ marginRight: 0, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                You are not the project owner. Only the project owner can change the billing preferences.
              </Tag>
            </div>
          )}
        </div>
      </div>
    </PageContent>
  );
}

export default Billing;
