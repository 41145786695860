import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import common from './features/common';
import cache from './features/cache';
import uiconfig from './features/ui-config';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { isDEV } from '../config';

const persistConfig = {
    key: 'dashboard',
    whitelist: ["common", "uiconfig"],
    blacklist: ["cache"],
    storage
};

const reducers = combineReducers({
    common,
    cache,
    uiconfig,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: isDEV,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;