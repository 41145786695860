import { Button, Drawer, Space, notification } from "antd"
import { AlertFilled, AlertOutlined, CloseCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import endpoints from "../utils/endpoints";
import Request from "../utils/request";
import { useAppDispatch, useAppSelector } from "../utils/hooks/useRedux";
import { setCacheLinkedIntegrations } from "../utils/redux/features/cache";
import { AlertChannels, ServiceTypes } from "../utils/types";
import { Link } from "react-router-dom";
import IntegrationIcons from "../assets/images/integrations";
import { upperFirst } from "lodash";
import classNames from "classnames";

type NotificationSettingsDrawerProps = {
    dataId: string;
    isOpen: boolean;
    serviceType: ServiceTypes;
    onlyShow?: AlertChannels[];
    onApply: () => void;
    onClose: () => void;
}

const NotificationSettingsDrawer = ({ dataId, serviceType, isOpen, onlyShow, onClose }: NotificationSettingsDrawerProps) => {
    const dispatch = useAppDispatch();
    const linkedIntegrations = useAppSelector(state => state.cache.linkedIntegrations);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [integrationLoadings, setIntegrationLoadings] = useState<string[]>([]);
    const [testAlertLoading, setTestAlertLoading] = useState<string[]>([]);

    const closeDrawer = () => {
        onClose();
    }

    // const applyChanges = () => {
    //     onApply();
    // }

    const fetchLinkedIntegrations = useCallback((integrationId?: string) => {
        setIsDataLoading(true);
        Request.get(endpoints.integration).then(res => {
            setIsDataLoading(false);
            if (res.data?.payload) dispatch(setCacheLinkedIntegrations(res.data?.payload));
            if (integrationId) setIntegrationLoadings(integrationLoadings.filter(id => id !== integrationId));
        })
            .catch(() => {
                setIsDataLoading(false);
                if (integrationId) setIntegrationLoadings(integrationLoadings.filter(id => id !== integrationId));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        if (isOpen) {
            fetchLinkedIntegrations();
        }
    }, [isOpen, fetchLinkedIntegrations]);

    const toggleService = (integrationId: string | undefined) => {
        if (!integrationId) return;
        const isIntegrationLoading = integrationLoadings.indexOf(integrationId as never) !== -1;
        if (isIntegrationLoading) return;

        setIntegrationLoadings([...integrationLoadings, integrationId]);
        Request.post(endpoints.integrationToggleService, { integrationId, serviceId: dataId, serviceType })
            .then(res => {
                if (res.data?.payload) {
                    fetchLinkedIntegrations(integrationId);
                } else {
                    setIntegrationLoadings(integrationLoadings.filter(id => id !== integrationId));
                }
            })
            .catch(() => {
                setIntegrationLoadings(integrationLoadings.filter(id => id !== integrationId));
            });
    }

    const testAlertAction = (integrationId: string | undefined) => {
        if (!integrationId) return;
        setTestAlertLoading([...testAlertLoading, integrationId]);
        Request.post(endpoints.testAlert, { integrationId, serviceId: dataId, serviceType })
            .then(res => {
                setTestAlertLoading(testAlertLoading.filter(id => id !== integrationId));
                if (res.data?.success === false) {
                    notification.error({ message: res.data?.message || 'Failed to send test alert' });
                }
                notification.success({ message: 'Test alert sent successfully' });
            })
            .catch(() => {
                setTestAlertLoading(testAlertLoading.filter(id => id !== integrationId));
            });
    }

    return (
        <Drawer
            title={<div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <span>Notification channels</span>
                {isDataLoading && <LoadingOutlined spin />}
            </div>}
            open={isOpen}
            size="large"
            onClose={closeDrawer}
            closable={false}
            extra={
                <Space>
                    <Button type="primary" onClick={closeDrawer}>Close <CloseCircleFilled /></Button>
                </Space>
            }
        >
            <div className="channels-container">
                {linkedIntegrations?.length !== 0 ? linkedIntegrations.map((integration) => {
                    if (onlyShow) {
                        if (onlyShow.indexOf(integration.channel as AlertChannels) === -1) return null;
                    }

                    const isIntegrationEnabled = integration?.services?.indexOf(dataId) !== -1 || integration.isDefault;
                    const isIntegrationLoading = integrationLoadings.indexOf(integration?.integrationId as never) !== -1;
                    const isIntegrationTestAlertLoading = testAlertLoading.indexOf(integration?.integrationId as never) !== -1;

                    const channelKey = Object.keys(AlertChannels).find(key => AlertChannels[key as keyof typeof AlertChannels] === integration.channel);
                    const channelValue = AlertChannels[channelKey as keyof typeof AlertChannels];
                    const channelName = upperFirst(channelKey);
                    return (
                        <div key={integration.integrationId} className={"channel-item"} style={{ gap: 10 }}>
                            <div className={classNames("channel-info", "channel-status-container")}>
                                <div className="channel-top" style={{ gap: 10, alignItems: "center" }}>
                                    <div className={"channel-icon"}><IntegrationIcons code={channelValue} /></div>
                                    <h3>{integration.name}</h3>
                                    {integration.channel !== AlertChannels.email && <p style={{ margin: 0 }}>{channelName}</p>}
                                </div>
                                <p className={classNames('channel-status', `channel-status-${isIntegrationEnabled ? 'active' : 'inactive'}`)}>{isIntegrationEnabled ? 'Active' : ''}</p>
                            </div>
                            <div style={{ display: "flex", gap: 10 }}>
                                {isIntegrationEnabled && <Button style={{ flex: 1 }} type="primary" disabled={isIntegrationLoading || isIntegrationTestAlertLoading} loading={isIntegrationTestAlertLoading} onClick={() => testAlertAction(integration?.integrationId)}>{isIntegrationTestAlertLoading ? <AlertFilled /> : <AlertOutlined />} Test alert</Button>}
                                <Button style={{ flex: isIntegrationEnabled ? 0.3 : 1 }} danger={isIntegrationEnabled} disabled={isIntegrationLoading || isIntegrationTestAlertLoading} loading={isIntegrationLoading} type="primary" onClick={() => toggleService(integration?.integrationId)}>{isIntegrationEnabled ? 'Disable' : 'Enable'}</Button>
                            </div>
                        </div>
                    )
                }) : <div style={{
                    textAlign: "center",
                    width: "100%",
                }}>You don't have any alert channel.<br />Please create a new one in <Link to="/integrations" style={{
                    color: "white",
                    textDecoration: "underline"
                }}>integrations</Link>.</div>}
            </div>
        </Drawer>
    )
}

export default NotificationSettingsDrawer;