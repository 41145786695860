import { QuestionCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";

const FormItemTitle = (props: { title: string; tooltip?: string }) => {
    return <div className="form-item-tooltip-container">
        <div>{props.title}</div>
        {props.tooltip && <Tooltip title={props.tooltip}>
            <QuestionCircleFilled style={{cursor: "help"}}/>
        </Tooltip>}
    </div>
}

export default FormItemTitle;