import { Button, Input, Modal, Popconfirm, Tag } from "antd";
import { useRef, useState } from "react";
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

type ModalTypes = {
    title?: string;
    isOpen: boolean;
    secretKey?: string;
    submitButtonText?: string;
    messageText: string | JSX.Element;
    errorMessageText?: string;
    onSubmit: () => void;
    onClose: () => void;
}

const DangerActionConfirmModal = ({ title, isOpen, submitButtonText, messageText, secretKey, errorMessageText, onSubmit, onClose }: ModalTypes) => {
    const [value, setValue] = useState('');
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null);
    const [disabled, setDisabled] = useState(true);
    const [showError, setError] = useState(false);

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    const actionCloseModal = () => {
        setValue('');
        setError(false);
        setDisabled(true);
        setBounds({ left: 0, top: 0, bottom: 0, right: 0 });
        onClose();
    }

    const actionSubmit = () => {
        if (secretKey && value !== secretKey) {
            setError(true);
            return;
        }
        onSubmit();
        actionCloseModal();
    }

    return (
        <Modal
            title={
                <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        if (disabled) {
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setDisabled(true);
                    }}
                >
                    {title || 'Confirmation'}
                </div>
            }
            open={isOpen}
            footer={[
                <Button key="back" type="primary" onClick={() => actionCloseModal()}>
                    Cancel
                </Button>,
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={actionSubmit}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ type: "primary", danger: true }}
                    cancelButtonProps={{ type: "default" }}
                >
                    <Button key="submit" type="primary" danger disabled={secretKey ? secretKey !== value : false}>
                        {submitButtonText || "Delete"}
                    </Button>
                </Popconfirm>

            ]}
            onCancel={() => actionCloseModal()}
            modalRender={modal => (
                <Draggable
                    bounds={bounds}
                    disabled={disabled}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
        >
            {typeof messageText === 'string' ? <h3>{messageText}</h3> : messageText}
            {secretKey && <Input placeholder="Project Name" value={value} onChange={e => setValue(e.target.value)} />}
            {(showError && secretKey) && <Tag color="red" style={{ marginBottom: 15 }}>{errorMessageText || 'Please enter correctly.'}</Tag>}
        </Modal>
    )
}

export default DangerActionConfirmModal;