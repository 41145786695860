import { useCallback, useMemo } from "react";
import Flags, { FlagCode } from "../../../assets/images/flags";
import { useAppSelector } from "../../../utils/hooks/useRedux";
import { uptimeLogsData } from "../../../utils/types";
import { formattedDate } from "../../../utils/utils";
import { colorPalette } from "../../../utils/constants";
import _ from "lodash";

const ChartTooltip = ({ active, payload }: any) => {
    const refinedPayload = payload;
    const locations = useAppSelector(state => state.common.locations);
    const getLocation = useCallback((regionId: number) => {
        return locations.find(x => x.regionId === regionId);
    }, [locations]);

    const date = useMemo(() => {
        if (active && refinedPayload && refinedPayload.length) {
            const data: uptimeLogsData = refinedPayload?.[0].payload;
            return formattedDate(data?.date)
        }
        return null;
    }, [active, refinedPayload]);

    if (active && refinedPayload && refinedPayload.length) {
        const data: uptimeLogsData = refinedPayload?.[0].payload;
        return (
            <div className="log-tooltip">
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 10,
                }}>
                    <span style={{ fontSize: 10 }}>{date}</span>
                    <span style={{ fontSize: 10, fontWeight: 'bold', color: data.status === 1 ? '#5ecb96' : '#ff4d4f' }}>{data.status === 1 ? 'UP' : 'DOWN'}</span>
                </div>
                <div>
                    <span className="label">Uptime: </span>
                    <span className="value">{_.isNaN(data.uptime) ? 0 : data.uptime.toFixed(2)}%</span>
                </div>
                {
                    refinedPayload.map((p: any, index: number) => {
                        const data = p?.payload;
                        const regionData = data.regions[p.dataKey];
                        const location = getLocation(regionData.regionId);
                        return (
                            <div
                                key={index + "_t"}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                }}
                            >
                                <span style={{
                                    width: 5,
                                    height: 15,
                                    borderRadius: 5,
                                    backgroundColor: colorPalette[index % colorPalette.length],
                                }} />
                                <span
                                    className="value"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Flags height={15} code={location?.code as FlagCode} /> {location?.city ?? "Unknown"}
                                </span>
                                <span className="label">{regionData.duration.toFixed(2)} ms</span>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    return null;
};

export default ChartTooltip;