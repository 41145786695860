import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Request from '../../../utils/request';
import endpoints from '../../../utils/endpoints';
import { ACCOUNT_SESSION, DATA_STATUSES_REVERSE, PlansByID } from '../../../utils/types';
import { formattedDate } from '../../../utils/utils';
import { useAppDispatch } from '../../../utils/hooks/useRedux';
import { resetCommon } from '../../../utils/redux/features/common';
import { resetCache } from '../../../utils/redux/features/cache';
import { resetConfig } from '../../../utils/redux/features/ui-config';

export default function AllCustomers() {
    const dispatch = useAppDispatch();
    const dataFetchedRef = useRef(false);
    const [data, setData] = useState([]);

    const fetchAllMonitors = useCallback(async () => {
        const res = await Request.get(endpoints.rootPanel + "customers");
        if (
            res.data.success === true &&
            res.data.data.length > 0
        ) {
            setData(res.data.data);
        } else {
            setData([]);
        }
    }, [])

    const openAccessPage = useCallback(async (userId: string) => {
        const res = await Request.post(endpoints.rootPanel + "access", { userId });
        if (
            res.data.success === true
        ) {
            dispatch(resetCommon());
            dispatch(resetCache());
            dispatch(resetConfig());
            window.location.href = res.data.data;
        }
    }, [dispatch])

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        fetchAllMonitors();
    }, [fetchAllMonitors])

    const columns: ColumnsType<ACCOUNT_SESSION> = useMemo(() =>
        [
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Plan',
                key: 'plan',
                dataIndex: 'plan',
                render: (_, record) => (
                    <>
                        <Tag color="blue" key={record.plan}>
                            {PlansByID[record.plan as keyof typeof PlansByID]}
                        </Tag>
                    </>
                ),
            },
            {
                title: 'Website',
                dataIndex: 'website',
                key: 'website',
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                render: (_, record) => (
                    <>
                        <Tag color="blue" key={record.plan}>
                            {DATA_STATUSES_REVERSE[record.status as keyof typeof DATA_STATUSES_REVERSE]}
                        </Tag>
                    </>
                ),
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (_, record: any) => (
                    <>
                        {formattedDate(record.createdAt)}
                    </>
                )
            },
            {
                title: 'Action',
                key: 'action',
                render: (_, record: any) => (
                    <Space size="middle">
                        <a onClick={() => openAccessPage(record._id)}>Auth</a>
                    </Space>
                )
            }
        ], [])

    return (
        <div>
            <h1>Root Panel / Customers</h1>
            <Table columns={columns} dataSource={data} pagination={false} />
        </div>
    )
}