import { PlusOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PageContent from "../../../components/PageContent";
import endpoints from "../../../utils/endpoints";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/useRedux";
import { setCacheFlows, setContentLoading } from "../../../utils/redux/features/cache";
import Request from "../../../utils/request";
import FlowItem from "../../../components/FlowItem";

function Flows() {
  const dispatch = useAppDispatch();
  const dataFetchedRef = useRef(false);
  const prevProjectIdRef = useRef<string | null>(null);
  const flows = useAppSelector(state => state.cache.flows);
  const projectId = useAppSelector(state => state.common.projectId);

  const getFlows = useCallback(async () => {
    if (flows.length === 0) dispatch(setContentLoading(true));
    const flowsRes = await Request.get(endpoints.flow)
      .catch(() => {
        if (flows.length === 0) dispatch(setContentLoading(false));
      });
    if (flowsRes?.data?.success === true) {
      dispatch(setCacheFlows(flowsRes?.data?.payload?.data))
    }
    if (flows.length === 0) dispatch(setContentLoading(false));
  }, [dispatch, flows])

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    prevProjectIdRef.current = projectId;
    getFlows();
  }, [getFlows, projectId])

  return (
    <PageContent title="Flows">
      {/* <div className="filter-area">
        <Input
          placeholder="Search..."
          style={{ width: 300 }}
          suffix={<SearchOutlined />}
        />
      </div> */}
      <div className="monitor-list">
        <Link to={"create"} className="monitor-add">
          <div><PlusOutlined style={{ marginRight: 5 }} /> Create flow</div>
        </Link>
        {flows.length === 0 ? <div className="no-data">No flows found.</div> :
          flows.map((site: any) => (
            <FlowItem
              key={site.flowId}
              id={site.flowId}
              name={site.name}
              status={site.status}
            />
          ))
        }
      </div>
    </PageContent>
  );
}

export default Flows;
