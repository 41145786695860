import { AlertChannels } from "../../../utils/types"
import IntEmail from "./IntEmail"
import IntSlack from "./IntSlack"
import IntTelegram from "./IntTelegram"
import IntWebhook from "./IntWebhook"

const IntegrationIcons = (props: { code: AlertChannels | undefined }) => {
    if (props.code === AlertChannels.webhook) return <IntWebhook />
    if (props.code === AlertChannels.telegram) return <IntTelegram />
    if (props.code === AlertChannels.email) return <IntEmail />
    if (props.code === AlertChannels.slack) return <IntSlack />
    return <>?</>
}

export default IntegrationIcons;