export type PlanType = {
    id: number,
    title: string,
    desc: string,
    price: number,
    featured?: boolean,
    contact?: boolean,
    free?: boolean
    features?: { title: string, limit?: string | number | undefined | null, icon?: any, bold?: boolean }[],
    notShowInCard?: boolean
}

export const plans: PlanType[] = [
    {
        id: 0,
        title: 'Free',
        desc: 'Ideal for beginners',
        price: 0,
        free: true,
        features: [
            {
                title: "E-mail and Telegram notifications",
            },
            {
                title: "5-minute check interval",
            },
            {
                title: "2 monitors",
            },
            {
                title: "1 flow monitoring with 2 nodes",
            }
        ]
    },
    {
        id: 1,
        title: 'Hobby',
        desc: 'Perfect for personal projects',
        price: 12,
        features: [
            {
                title: "Same as Free, plus:",
                bold: true
            },
            {
                title: "Telegram and webhook notifications",
            },
            {
                title: "1-minute check interval",
            },
            {
                title: "10 monitors",
            },
            {
                title: "5 flow monitoring with 3 nodes",
            },
        ]
    },
    {
        id: 2,
        title: 'Startup',
        desc: 'Tailored for emerging businesses',
        price: 25,
        features: [
            {
                title: "Same as Hobby, plus:",
                bold: true
            },
            {
                title: "Slack notifications",
            },
            {
                title: "100 monitors",
            },
            {
                title: "7 flow monitoring with 6 nodes",
            },
        ]
    },
    {
        id: 3,
        title: 'Business',
        desc: 'Designed for growing enterprises',
        price: 180,
        featured: true,
        features: [
            {
                title: "Same as Startup, plus:",
                bold: true
            },
            {
                title: "10-seconds check interval",
            },
            {
                title: "500 monitors",
            },
            {
                title: "30 flow monitoring with 30 nodes",
            },
        ]
    },
];

export const features = [
    {
        title: "Monitors",
        definition: [
            "10", "10", "50", "100", "Custom"
        ]
    },
    {
        title: "Flows",
        definition: [
            "No", "5", "10", "100", "Custom"
        ]
    },
    {
        title: "Monitoring locations",
        definition: [
            "16", "16", "16", "16", "16"
        ]
    },
    {
        title: "Check interval",
        definition: [
            "5 minutes", "3 minutes", "30 seconds", "10 seconds", "~5 seconds"
        ]
    },
    {
        title: "SSL monitoring",
        definition: [
            "Yes", "Yes", "Yes", "Yes", "Yes"
        ]
    },
    {
        title: "Subscribers",
        definition: [
            "10", "100", "500", "2000", "Custom"
        ]
    },
    {
        title: "Password protection",
        definition: [
            "No", "Yes", "Yes", "Yes", "Yes"
        ]
    },
    {
        title: "Custom HTTPS domain",
        definition: [
            "No", "Yes", "Yes", "Yes", "Yes"
        ]
    },
    {
        title: "Page customization",
        definition: [
            "Limited", "Limited", "Yes", "Yes", "Yes"
        ]
    },
    {
        title: "In-app notifications",
        definition: [
            "Yes", "Yes", "Yes", "Yes", "Yes"
        ]
    },
    {
        title: "E-mail notifications",
        definition: [
            "Yes", "Yes", "Yes", "Yes", "Yes"
        ]
    },
    {
        title: "SMS notifications",
        definition: [
            "No", "No", "Yes", "Yes", "Yes"
        ]
    },
    {
        title: "Slack notifications",
        definition: [
            "No", "Yes", "Yes", "Yes", "Yes"
        ]
    },
    {
        title: "Phone call",
        definition: [
            "No", "No", "Yes", "Yes", "Yes"
        ]
    },
    {
        title: "WhatsApp notifications",
        definition: [
            "No", "No", "Yes", "Yes", "Yes"
        ]
    },
    {
        title: "Telegram notifications",
        definition: [
            "No", "Yes", "Yes", "Yes", "Yes"
        ]
    },
]