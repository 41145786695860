import { SVGProps, memo } from "react"

const Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 36 36"
    height={35}
    style={{
      marginRight: ".75em",
    }}
    {...props}
  >
    <path fill="#138808" d="M0 27a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4v-4H0v4z" />
    <path fill="#EEE" d="M0 13h36v10H0z" />
    <path fill="#F93" d="M36 13V9a4 4 0 0 0-4-4H4a4 4 0 0 0-4 4v4h36z" />
    <circle fill="navy" cx={18} cy={18} r={4} />
    <circle fill="#EEE" cx={18} cy={18} r={3} />
    <path
      fill="#6666B3"
      d="m18 15 .146 2.264 1.001-2.035-.73 2.147 1.704-1.498-1.497 1.705 2.147-.731-2.035 1.002L21 18l-2.264.146 2.035 1.001-2.147-.73 1.497 1.704-1.704-1.497.73 2.147-1.001-2.035L18 21l-.146-2.264-1.002 2.035.731-2.147-1.705 1.497 1.498-1.704-2.147.73 2.035-1.001L15 18l2.264-.146-2.035-1.002 2.147.731-1.498-1.705 1.705 1.498-.731-2.147 1.002 2.035z"
    />
    <circle fill="navy" cx={18} cy={18} r={1} />
  </svg>
)

const FlagIN = memo(Icon)
export default FlagIN
