import { Button } from "antd";
import { PlanType } from "../utils/pricingData";
import { calculateAnnualDiscountedPrice, calculateAnnualPrice } from "../utils/utils";

type PricingCardProps = {
    plan: PlanType,
    onClickPlan: (plan: PlanType) => void,
    isMonthly: boolean,
    isCurrent?: boolean,
    isSelected?: boolean,
    actionMode: boolean,
}

const PricingCard = (props: PricingCardProps) => {
    const { plan, onClickPlan } = props;

    return <div className={`price-card ${props.isSelected ? "selected-plan" : ""}`}>
        {props.isCurrent && <div className="current-plan-badge">Current plan</div>}
        <div className="price-card-info-container">
            <div className="price-card-title">{plan.title}</div>
            <div className="price-card-desc">{plan.desc}</div>
        </div>
        <div className="price-card-price">
            <div className="price-amount-container">
                {(!props.isMonthly && !props.plan.free && plan.price !== -1) && <span className="price-card-price-amount-prev" >${calculateAnnualPrice(plan.price)}</span>}
                <span className={`price-card-price-amount${!props.isMonthly && !props.plan.free ? ' price-yellow' : ''}`} >{plan.price === -1 ? 'Custom' : `$${props.isMonthly && !props.plan.free ? plan.price : calculateAnnualDiscountedPrice(plan.price)}`}</span>
            </div>
            {!plan.contact && <span className="price-card-price-month">/{props.isMonthly ? 'monthly' : 'annual'}</span>}
        </div>
        {props.actionMode && (
            <Button
                type="primary"
                disabled={props?.isCurrent}
                onClick={() => onClickPlan(plan)}
                className={"price-card-action-btn"}
            >
                {props.isSelected ? "Selected" : "Select"}
            </Button>
        )}
        <div className='divider' />
        <div className="price-card-features">
            {plan.features && plan.features.map((feature, i) => {
                return <div key={i} className="price-card-feature">
                    <span className={`price-card-feature-text ${feature.bold === true ? "price-card-feature-text-bold" : ""}`}>{feature.title}</span>
                </div>
            })}
        </div>
    </div>
}

export default PricingCard;