import { createSlice } from '@reduxjs/toolkit';
import { ACCOUNT_SESSION, LocationInterface, ProjectInterface } from '../../types';

export interface State {
    user: ACCOUNT_SESSION | null,
    projectId: string | null,
    projects: ProjectInterface[],
    locations: LocationInterface[],
}

const initialState: State = {
    user: null,
    projectId: null,
    projects: [],
    locations: [],
};

export const slice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setUser: (state: any, action: any) => {
            state.user = action.payload;
            state.projectId = action.payload?.projects?.[0]?.projectId;
            state.projects = action.payload?.projects;
        },
        updateUser: (state: any, action: any) => {
            state.user = {
                ...state.user,
                ...action.payload,
            }
            state.projects = action.payload?.projects;
            const isProjectExist = action.payload?.projects.find((project: ProjectInterface) => project.projectId === state.projectId);
            if(!isProjectExist || !state.projectId) {
                state.projectId = action.payload?.projects?.[0]?.projectId;
                window.location.href = "/";
            }
        },
        setProject: (state: any, action: any) => {
            state.projectId = action.payload;
        },
        setProjects: (state: any, action: { payload: Partial<ProjectInterface[]> }) => {
            state.projects = action.payload;
        },
        setLocations: (state: any, action: { payload: Partial<LocationInterface[]> }) => {
            state.locations = action.payload;
        },
        resetCommon: () => {
            return initialState;
        },
    }
});

export const { setUser, updateUser, setProject, setProjects, setLocations, resetCommon } = slice.actions;
export default slice.reducer;